import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Login(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  
  const navigate = useNavigate();

  const submit = (e) => {
    e.preventDefault();
    async function login() {
      try {
        const response = await fetch("https://royalgreenhouse.co.in/crud.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: username, password: password }),
        });

        const result = await response.json();
        
        if (result === true) {
          console.log("successful login");
          props.setLogin(true);
          navigate("/dashboard", { replace: true });
        } else if (result === false) {
          console.log("login failed");
          props.setLogin(false);
        } else {
          console.log("technical error");
        }
      } catch (error) {
        console.log("Error:", error);
      }
    }
    login();
  };

  return (
    <Container fluid>
      <Row>
        <Col className="m-4 d-flex justify-content-center align-items-center">
          <Form
            onSubmit={(e) => submit(e)}
            className="border m-3 p-3 bg-info rounded"
          >
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                value={username}
                placeholder="Enter Username"
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="success" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
