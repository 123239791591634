import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import blog1Img from "../../Assets/gallery/blog1Img.png";
import "./blog.css";
import "animate.css";
import { Link } from "react-router-dom";

function HowToReduceFutureMaintenanceInPolyhouseFarming() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={blog1Img}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ width: "100%", maxWidth: "700px" }}
          alt="polyhouse farms"
        />
      </div>

      <Row className="m-3 m-md-5">
        <Col
          className="p-3 text-justify"
          sm={12}
          md={9}
          xs={12}
          id="content"
          style={{ fontSize: "1rem", lineHeight: "1.6" }}
        >
          <div id="blog1" className="border p-3">
            <h1 className="text-center p-3">
              How to Reduce Future Maintenance in Polyhouse Farming
            </h1>
            <p>
              <span className="fw-bold">Quality Materials:</span> Use durable,
              high-quality construction materials to ensure longevity and reduce
              frequent repairs.
            </p>
            <p>
              <span className="fw-bold">Regular Inspections:</span> Conduct
              regular inspections for early detection of issues like tears in
              the covering, structural weaknesses, or pest infestations.
            </p>
            <p>
              <span className="fw-bold">Proper Ventilation:</span> Ensure
              adequate ventilation to prevent mold and mildew, which can damage
              the structure and crops.
            </p>
            <p>
              <span className="fw-bold">Integrated Pest Management:</span>{" "}
              Implement IPM practices to control pests and diseases, reducing
              the need for chemical interventions and structural repairs.
            </p>
            <p>
              <span className="fw-bold">Climate Control Systems:</span> Invest
              in automated climate control systems to maintain optimal growing
              conditions and reduce wear and tear on the polyhouse.
            </p>
            <p>
              <span className="fw-bold">Cleanliness:</span> Maintain a clean
              environment to prevent the buildup of debris and contaminants that
              can cause damage over time.
            </p>
            <p>
              <span className="fw-bold">Drainage Systems:</span> Ensure
              effective drainage systems to prevent waterlogging and structural
              damage.
            </p>
            <p>
              <span className="fw-bold">Training:</span> Train staff on proper
              maintenance procedures and best practices to ensure consistent
              upkeep of the polyhouse.
            </p>
            <p>
              <span className="fw-bold">Maintenance Schedule:</span> Establish
              and adhere to a regular maintenance schedule to address minor
              issues before they become major problems.
            </p>
            <p>
              <span className="fw-bold">Professional Services:</span> Consider
              hiring professional services for periodic maintenance and repairs
              to ensure high-quality upkeep.
            </p>
            <h4>Our Expertise</h4>
            <ul style={{ paddingLeft: "1.2rem" }}>
              <li>
                <Link to="/Nvph" style={{ textDecoration: "none" }}>
                  NVPH Polyhouse
                </Link>
              </li>
              <li>
                <Link
                  to="/ClimateControlled"
                  style={{ textDecoration: "none" }}
                >
                  Fan Pad Polyhouse
                </Link>
              </li>
              <li>
                <Link to="/TunnelShadeHouse" style={{ textDecoration: "none" }}>
                  Tunnel Net House
                </Link>
              </li>
              <li>
                <Link
                  to="/MultispanShadenet"
                  style={{ textDecoration: "none" }}
                >
                  Flat Net House
                </Link>
              </li>
              <li>
                <Link
                  to="/RainProtectedWireRopeNetHouse"
                  style={{ textDecoration: "none" }}
                >
                  Rain Protected Wire Rope Net House
                </Link>
              </li>
              <li>
                <Link to="/DWC" style={{ textDecoration: "none" }}>
                  Hydroponic Farming
                </Link>
              </li>
              <li>
                <Link to="/NftFlatbed" style={{ textDecoration: "none" }}>
                  Flat Bed NFT
                </Link>
              </li>
              <li>
                <Link to="/NftAframe" style={{ textDecoration: "none" }}>
                  A Frame NFT
                </Link>
              </li>
              <li>
                <Link to="/DutchBucket" style={{ textDecoration: "none" }}>
                  Dutch Bucket Vine Crops
                </Link>
              </li>
              <li>
                <Link to="/VineCrops" style={{ textDecoration: "none" }}>
                  Grow Bags/Grow Slabs Vine Crop
                </Link>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default HowToReduceFutureMaintenanceInPolyhouseFarming;
