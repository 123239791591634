import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory7 from "../../Assets/accessory7.jpeg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const U_Shaped_Clamp_by_RGCPL = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 53,
      name: "U-Shaped Clamp (Saddle Clamp) by RGCPL",
      price: 250,
      image: accessory7,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory7}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              U-Shaped Clamp (Saddle Clamp) by RGCPL
            </h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The U-shaped clamp, also known as a saddle clamp, is essential
                  for securing pipes or cylindrical structures in poly house and
                  greenhouse setups. Manufactured by RGCPL, these clamps offer
                  superior support and durability. They ensure structural
                  stability, especially in pipe frameworks, protecting the
                  overall integrity of agricultural setups by preventing
                  movement or displacement of pipes.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span> Galvanized Iron
                      (GI)
                    </li>
                    <li>
                      <span className="fw-bold">Color:</span> Silver
                    </li>
                    <li>
                      <span className="fw-bold">Length:</span> Varies
                      (customizable)
                    </li>
                    <li>
                      <span className="fw-bold">Width:</span> 2 Inches
                    </li>
                    <li>
                      <span className="fw-bold">Thickness:</span> 4 mm
                    </li>
                    <li>
                      <span className="fw-bold">Usage/Application:</span>{" "}
                      Agriculture/Greenhouse structure
                    </li>
                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 5 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>Eco-friendly</li>
                  <li>Easy to install</li>
                  <li>Durable and weather-resistant</li>
                  <li>Structure: GI (Galvanized Iron)</li>
                  <li>Built Type: GI Structure</li>
                  <li>Type: Pipe securing clamp</li>
                  <li>Pattern: RGCPL</li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Boxed,
                      bundled in sets
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <h5 className="mt-3">Why Choose RGCPL for U-shaped Clamps?</h5>
        <p className="text-justify">
          RGCPL offers top-quality U-shaped clamps made from premium galvanized
          iron, ensuring longetivity and resistance to rust. These clamps are
          eco-friendly and made in-house, allowing for competitive pricing and
          quick delivery. RGCPL's attention to detail and quality control makes
          their clamps the ideal choice for agricultural projects requiring
          durable support components.
        </p>

        <h5 className="mt-3">Importance of U-Shaped Clamps:</h5>
        <p className="text-justify">
          These clamps are integral for securely holding pipes or tubes in
          place, particularly in greenhouse structures where pipes form the
          mainframe. The clamps provide secure anchorage and protect against the
          movement of pipes during adverse weather conditions.
        </p>
        <h5 className="mt-3">Key Benefits:</h5>

        <p className="text-justify">
          <ul>
            <li>
              <span className="fw-bold">Durability:</span> Manufactured from
              galvanized iron, these clamps resist rust and wear, providing
              long-lasting support.
            </li>
            <li>
              <span className="fw-bold">Stability:</span> Securely fastens pipes
              in place, reducing the risk of structural collapse due to
              dislodged components.
            </li>
            <li>
              <span className="fw-bold">Eco-friendly:</span> Made with
              environmetally conscious materials that support sustainable
              construction practices.
            </li>
            <li>
              <span className="fw-bold">Easy Installation:</span> Simple to
              attach to pipes, making assembly quick and efficient.
            </li>
          </ul>
        </p>
        <h5 className="mt-3">Applications of U-shaped Clamps:</h5>
        <p className="text-justify">
          <ul>
            <li>Securing pipes in polyhouse and greenhouse structures.</li>
            <li>Supporting cylindrical elements in agricultural buildings.</li>
            <li>
              General structural applications where pipe stabilization is
              needed.
            </li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default U_Shaped_Clamp_by_RGCPL;
