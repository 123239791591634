import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory1 from "../../Assets/accessory1.jpeg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Tension_Hook_for_Polyhouse = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 47,
      name: "Polyhouse Tension Hook for Curtain Control",
      price: 2,
      image: accessory1,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory1}
            alt="Plastic Shade Net Stitching Pins for Polyhouse"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Polyhouse Tension Hook for Curtain Control
            </h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The Tension Hook for Greenhouse/Polyhouse Structures is an
                  essential component for supporting and securing polyhouse or
                  greenhouse installations. It’s designed to withstand high
                  tension and is used for securing netting or various structural
                  elements. Crafted from galvanized iron, this hook is both
                  durable and weather-resistant.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Pieces.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Structure" className="custom-tab">
                  Structure
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>Material: Galvanized Iron (GI)</li>
                    <li>Shape: Curved hook</li>
                    <li>Height: Customizable</li>
                    <li>Thickness: 4-6 mm</li>
                    <li>
                      Usage/Application: Securing polyhouse netting, holding
                      tension cables
                    </li>
                    <li>Brand: RGCPL</li>
                    <li>Durability: 5-6 Years</li>
                    <li>Installation Time: Instant fit</li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>Strong and durable tension hook</li>
                  <li>
                    Essential for securing netting or structural components
                  </li>
                  <li>Corrosion-resistant material</li>
                  <li>Simple to install and use</li>
                </ul>
              </Tab.Pane>
              <Tab.Pane eventKey="Structure">
                <h4>Structure</h4>
                <ul>
                  <li>Material: Galvanized Iron (GI)</li>
                  <li>Built Type: Hook for Polyhouse/Greenhouse</li>
                  <li>Type: Tension Hook</li>
                  <li>Pattern: RGCPL</li>

                  <li>Manufactured By: RGCPL</li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>Manufactured by: Royal Green House</li>
                    <li>Item Code: RGCPL-0001</li>
                    <li>Delivery Time: 7-8 Days </li>
                    <li>Port of Dispatch: Maharashtra, India </li>
                    <li>Production Capacity: 10,000 Pieces Per Day </li>
                    <li>Packaging Details: Packed in durable plastic bags </li>

                    <li>Payment Terms: Advance Payment </li>
                    <li>Payment Mode: RTGS / NEFT / IMPS</li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <h5 className="mt-3">
          Why Choose Royal Green House for Polyhouse Tension Hook?
        </h5>
        <p className="mt-1 text-justify">
          Royal Green House’s{" "}
          <span className="fw-bold">Polyhouse Tension Hooks</span> are crucial
          for securing polyhouse curtains effectively. Their sturdy design
          ensures durability, making them ideal for long-lasting use in
          agricultural environments. Made from galvanized steel, these hooks
          resist rust and wear, providing reliability for polyhouse operations.
        </p>
        <h5 className="mt-3">
          Key Benefits of Royal Green House’s Polyhouse Tension hooks:
        </h5>
        <p className="text-justify">
          <ul>
            <li>
              Durable and Weather-Resistant: Constructed from galvanized steel,
              these tension hooks withstand tough environmental conditions.{" "}
            </li>
            <li>
              Precise Design: Specifically designed to maintain the tension and
              position of polyhouse curtains, ensuring optimal ventilation
              control.{" "}
            </li>
            <li>
              Easy Installation: The simple, efficient design allows for quick
              installation, saving time in polyhouse management.{" "}
            </li>
            <li>
              Affordable: High-quality product offered at a competitive price,
              perfect for enhancing polyhouse functionality.
            </li>
          </ul>
        </p>
        <h5 className="mt-3">Application of Polyhouse Tension Hooks:</h5>
        <p className="text-justify">
          These tension hooks are ideal for securing and maintaining the
          curtains in naturally ventilated polyhouses. By ensuring proper
          tension, they help in controlling the airflow and temperature, which
          is vital for effective greenhouse farming. Their robust and
          weather-resistant construction makes them a reliable choice for
          agricultural workers.
          <br />
          For inquiries and orders, contact Royal Green House today for reliable
          net house repair solutions!
          <h5 className="mt-3">Contact Us:</h5>
          <div className="text-sm-start text-center text-muted">
            <i className="fas fa-phone-alt"></i>
            <a href="tel:+919960991166" className="ms-2">
              {" "}
              +91-9960-991-166
            </a>
          </div>
          <br />
          <div className="text-sm-start text-center text-muted">
            <i className="fas fa-envelope"></i>
            <a
              href="mailto:info@royalgreenhouse.co.in"
              target="_blank"
              className="ms-2"
            >
              info@royalgreenhouse.co.in
            </a>
          </div>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Tension_Hook_for_Polyhouse;
