import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Top10_hydroponic_crops_img from "../../Assets/Top10_hydroponic_crops_img.png";
import "./blog.css";
import "animate.css";
import { Button } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";

function Top10_hydroponic_crops_for_india() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={Top10_hydroponic_crops_img}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ width: "90%" }}
          alt="top 10 hydroponics crops"
        />
      </div>

      <Row className="m-3">
        <Col className="p-4 textJustify" sm={12} md={10} lg={8} id="content">
          <div id="blog1" className="border p-2">
            <h1 className="text-center p-3">
              Top 10 Hydroponic Crops for India
            </h1>{" "}
            <p className="pb-4">
              Hydroponic farming offers numerous advantages in India’s diverse
              climate, enabling year-round cultivation of high-quality produce.
              Top 10 hydroponic companies in India have embraced these methods
              to maximize productivity and sustainability. Here are the top 10
              hydroponic crops that are well-suited for Indian conditions:
            </p>
            <ol>
              <li className="pb-4">
                <h2>Lettuce</h2>
                <h6>How It’s Ideal:</h6>
                <ul>
                  <li>
                    Lettuce grows well in the controlled environment of
                    hydroponics.
                  </li>
                  <li>
                    It thrives in cooler temperatures, which can be maintained
                    in a polyhouse.
                  </li>
                </ul>
                <h6>Benefits:</h6>
                <ul>
                  <li>Rapid growth cycle.</li>
                  <li>High market demand for fresh, crisp lettuce.</li>
                </ul>
              </li>

              <li className="pb-4">
                <h2>Spinach</h2>
                <h6>How It’s Ideal:</h6>
                <ul>
                  <li>
                    Spinach is well-suited for hydroponic systems, with a short
                    growth period.
                  </li>
                  <li>It can be grown year-round in controlled conditions.</li>
                </ul>
                <h6>Benefits:</h6>
                <ul>
                  <li>
                    Nutrient-rich and popular among health-conscious consumers.
                  </li>
                  <li>High yield per square meter.</li>
                </ul>
              </li>

              <li className="pb-4">
                <h2>Kale</h2>
                <h6>How It’s Ideal:</h6>
                <ul>
                  <li>
                    Kale adapts well to hydroponic systems and can handle
                    various light conditions.
                  </li>
                  <li>It’s a hardy crop that grows quickly.</li>
                </ul>
                <h6>Benefits:</h6>
                <ul>
                  <li>
                    Rich in vitamins and minerals, making it a popular
                    superfood.
                  </li>
                  <li>Good market demand.</li>
                </ul>
              </li>

              <li className="pb-4">
                <h2>Tomatoes</h2>
                <h6>How It’s Ideal:</h6>
                <ul>
                  <li>
                    Tomatoes grow well in hydroponic systems like Dutch buckets
                    or vertical setups.
                  </li>
                  <li>Controlled conditions help manage pests and diseases.</li>
                </ul>
                <h6>Benefits:</h6>
                <ul>
                  <li>High yield and consistent quality.</li>
                  <li>Versatile use in cooking and fresh consumption.</li>
                </ul>
              </li>

              <li className="pb-4">
                <h2>Cucumbers</h2>
                <h6>How It’s Ideal:</h6>
                <ul>
                  <li>
                    Cucumbers grow well in hydroponic systems that offer
                    vertical space.
                  </li>
                  <li>
                    They require stable temperatures and humidity, which
                    hydroponics can provide.
                  </li>
                </ul>
                <h6>Benefits:</h6>
                <ul>
                  <li>High yield and rapid growth.</li>
                  <li>Popular in salads and cooking.</li>
                </ul>
              </li>

              <li className="pb-4">
                <h2>Herbs (Basil, Mint, Coriander)</h2>
                <h6>How It’s Ideal:</h6>
                <ul>
                  <li>
                    Herbs have relatively short growth cycles and are
                    well-suited for hydroponic cultivation.
                  </li>
                  <li>
                    They thrive in a controlled environment with consistent
                    nutrient delivery.
                  </li>
                </ul>
                <h6>Benefits:</h6>
                <ul>
                  <li>High market value and demand for fresh herbs.</li>
                  <li>
                    Compact growth, making them suitable for smaller hydroponic
                    setups.
                  </li>
                </ul>
              </li>
              <li className="pb-4">
                <h2>Bell Peppers</h2>
                <h6>How It’s Ideal:</h6>
                <ul>
                  <li>
                    Bell peppers can be grown in hydroponic systems like Dutch
                    buckets or vertical farms.
                  </li>
                  <li>
                    They benefit from the controlled environment to produce
                    high-quality fruits.
                  </li>
                </ul>
                <h6>Benefits:</h6>
                <ul>
                  <li>High value and demand in the market.</li>
                  <li>Produces large, healthy fruits.</li>
                </ul>
              </li>

              <li className="pb-4">
                <h2>Strawberries</h2>
                <h6>How It’s Ideal:</h6>
                <ul>
                  <li>
                    Strawberries grow well in hydroponic systems that allow for
                    vertical cultivation.
                  </li>
                  <li>
                    They require specific light and nutrient conditions, which
                    hydroponics can provide.
                  </li>
                </ul>
                <h6>Benefits:</h6>
                <ul>
                  <li>
                    High market value and consumer preference for fresh, sweet
                    strawberries.
                  </li>
                  <li>Can be grown year-round in controlled conditions.</li>
                </ul>
              </li>

              <li className="pb-4">
                <h2>Green Beans</h2>
                <h6>How It’s Ideal:</h6>
                <ul>
                  <li>
                    Green beans thrive in hydroponic systems like vertical farms
                    or NFT.
                  </li>
                  <li>They grow quickly and yield abundantly.</li>
                </ul>
                <h6>Benefits:</h6>
                <ul>
                  <li>
                    Popular in various cuisines and has good market demand.
                  </li>
                  <li>
                    Efficient use of space and resources in hydroponic systems.
                  </li>
                </ul>
              </li>
              <li className="pb-4">
                <h2>Swiss Chard</h2>
                <h6>How It’s Ideal:</h6>
                <ul>
                  <li>
                    Swiss chard adapts well to hydroponic systems and grows
                    rapidly.
                  </li>
                  <li>
                    It thrives in a controlled environment with consistent
                    nutrient delivery.
                  </li>
                </ul>
                <h6>Benefits:</h6>
                <ul>
                  <li>
                    Nutrient-dense and visually appealing with its colorful
                    stems.
                  </li>
                  <li>High yield and relatively low maintenance.</li>
                </ul>
              </li>
            </ol>
            <h5>Conclusion</h5>
            <p>
              Hydroponic farming in India opens up new opportunities for growing
              high-value crops with greater control over environmental
              conditions. These top 10 hydroponic crops are not only suited to
              the Indian climate but also offer great potential for
              profitability and sustainability.
            </p>
            <p>
              For more information on setting up hydroponic systems and choosing
              the right crops, contact us at Royal Green House Construction Pvt
              Ltd (RGCPL). Our experts can help you get started with the best
              hydroponic solutions for your needs. Reach us at{" "}
              <a href="tel:+919960991166"> +91-9960-991-166</a>.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Top10_hydroponic_crops_for_india;
