import React from "react";
// import Slider from '../../Components/Slider';
import Intro from "../../Components/Intro";
import one from "../../Assets/new/slide/new/strsn.png";
import two from "../../Assets/new/slide/new/green.png";
import three from "../../Assets/new/slide/new/sunshine.png";
import { Container } from "react-bootstrap";
// import { Button } from 'react-bootstrap';
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import QuoteCards from "../../Components/QuoteCards";
import AgreeServicess from "../../Components/AgreeServicess";
import Supplies from "../../Components/Supplies";
// import GalleryofProjects from '../../Components/GalleryofProjects';
import CustomerExp from "../../Components/CustomersExp";
import "../../App.css";
import Enquiry from "../../Components/Enquiry";
import CustomSlider from "../../Components/CustomSlider";
// import Banner from '../../Components/Banner';
// import Ban1 from '../../Assets/ban2.jpeg';
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import gif from "../../Assets/gi.gif";
import BgVid from "../../Assets/rvid.mp4";
// import PopupMessage from "../../Components/PopupMessage";
// import GoogleTranslate from "../../Components/GoogleTranslate";

function Home() {
  var img = [
    {
      img: two,
      caption: "ROYAL GREEN HOUSE CONSTRUCTION(I) PVT. LTD.",
      subcaption:
        "We Are India's Fastest Growing Hi-Tech Polyhouse (Hydroponics) Setup Company.",
      moto: "Build Your Own affordable Hydroponic Farm With Our Expertise (At home also)",
    },
    {
      img: one,
      caption: "ROYAL GREEN HOUSE CONSTRUCTION(I) PVT. LTD.",
      subcaption:
        "We Are the largest project developer in hydroponic farming and soil-less farming.",
      moto: "The Best Harvests Anywhere In The World",
    },
    {
      img: three,
      caption: "ROYAL GREEN HOUSE CONSTRUCTION(I) PVT. LTD.",
      subcaption: "Leader In Vertical Hydroponic Farming With 40 To 60% Higher Revenue.",
      moto: "Build Extremely Profitable Hydroponic Farm With Us.",
    },
  ];
  return (
    <>
      <div>
        <video
          autoPlay
          muted
          loop
          id="background-video"
          preload="auto"
          rel="preload"
          style={{ width: "100%", height: "auto", position: "relative" }}
        >
          <source
            src={BgVid}
            type="video/mp4"
            alt="Top Hydroponics setup in India, Best Hydroponics setup in India"
          />
          Your browser does not support the video tag.
        </video>

        <div
          id="contentVid"
          style={{
            position: "absolute",
            top: "3%",
            left: 0,
            padding: "20px",
            color: "white",
            width: "100%",
          }}
        >
          <div className="text-start py-5">
            <div className="my-lg-5 mx-lg-5 py-lg-5">
              <h1 className="text-regular">
                <span className="heading1 py-3 hover-text" rel="preload">
                  ROYAL GREEN HOUSE
                </span>
                <br />
                <span className="heading3 text-theme hover-text" rel="preload">
                  CONSTRUCTION(I) PVT. LTD.
                  <br />
                </span>
                <span className="heading3 hover-text">
                  We Are India's Fastest Growing
                </span>
                <br />
                <span>Hi-Tech</span> Polyhouse and Hydroponics farm Setup
                Company
                <br />
                <span className="heading6 text-theme mb-2">
                  Build Your Own Affordable Hydroponic Farm With Our Expertise
                  (At Home Also)
                  <br />
                </span>
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* <Banner /> */}
      {/* <Slider images={img} /> */}
      <QuoteCards />

      <Container className="whitebgimg p-4" fluid>
        <Row>
          <Col lg={6} className="p-4 mt-2">
            <h5 className="p-2 hover-text" style={{ display: "inline" }}>
              LEADER IN VERTICAL HYDROPONIC FARMING
            </h5>
            <h5 className="p-2 hover-text" style={{ display: "inline" }}>
              WITH 40 TO 60% HIGHER REVENUE.
            </h5>

            <h5 className="p-2 hover-text">
              WITH 5000 SQFT AREA ANYWHERE IN INDIA, YOU CAN BUILD AN EXTREMELY
              PROFITABLE HYDROPONIC FARM.
            </h5>
            <h5 className="p-2 hover-text">
              WE BUILD YOUR FARM – YOU OPERATE FARM{" "}
            </h5>
            <h3 className="p-2 text-green">
              More Details :<a href="tel:+919960991166"> +91-9960-991-166</a>
            </h3>
          </Col>
          <Col lg={4} className="p-4 mt-3">
            <img
              src={gif}
              alt="Hydroponic Farm near me, hydroponic farms near me"
              className="img-fluid rounded"
              style={{ width: "auto", height: "300px" }}
            />
          </Col>
        </Row>
      </Container>

      {/* Text below the video */}
      <Container>
        <Row>
          <Col>
            <h5 className="text-start">
              Hi-Tech Polyhouse and Hydroponics Farm Setup in India
            </h5>
          </Col>
        </Row>
      </Container>
      <Intro />
      <Supplies />
      <AgreeServicess />
      {/* <GalleryofProjects /> */}
      <TestimonialsSlider />
      <CustomerExp reviewData="reviewData" />
      <CustomSlider />
      {/* <Press /> */}
      <Enquiry />
      {/* <PopupMessage /> */}
    </>
  );
}
export default Home;
