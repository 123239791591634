import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import cost_blog_img from "../../Assets/cost_blog_img.png";
import "./blog.css";
import "animate.css";
import "bootstrap-icons/font/bootstrap-icons.css";

function Understanding_the_Cost_of_Setting_Up_Different_Types_of_Protected_Cultivation_Structures() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={cost_blog_img}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ width: "90%" }}
          alt="cost of hydroponic and polyhouse farming"
        />
      </div>

      <Row className="m-3">
        <Col className="p-4 textJustify" sm={12} md={10} lg={8} id="content">
          <div id="blog1" className="border p-2">
            <h1 className="text-center p-3">
              Understanding the Cost of Setting Up Different Types of Protected
              Cultivation Structures
            </h1>{" "}
            <p className="pb-4">
              When planning for agricultural investments, especially in
              protected cultivation, understanding the setup costs per square
              meter is crucial. At Royal Green House, we specialize in providing
              a range of cost-effective, high-quality structures designed to
              support the growth of various crops in controlled environments.
              Here’s a breakdown of the costs and advantages for each type:
            </p>
            <p>
              <ol>
                <li>
                  <span className="fw-bold">
                    NVPH Polyhouse - ₹1200 per sqm:
                  </span>
                </li>
                <p>
                  The Natural Ventilated Polyhouse (NVPH) is ideal for regions
                  with moderate climate conditions. With strategic ventilation,
                  it allows adequate air circulation, making it suitable for
                  crops that need protection from extreme weather. This type of
                  polyhouse enables farmers to control humidity and temperature
                  to a significant extent.
                </p>
                <li>
                  <span className="fw-bold">
                    Fan Pad Polyhouse - ₹1800 per sqm:
                  </span>
                </li>
                <p>
                  The Fan Pad Polyhouse provides a high level of climate
                  control, making it perfect for areas with extreme
                  temperatures. Equipped with an evaporative cooling system (fan
                  and pad), it maintains optimal temperatures within the
                  structure, enabling the growth of high-value crops that
                  require stable, cool conditions.{" "}
                </p>
                <li>
                  <span className="fw-bold">
                    Tunnel Net House - ₹700 per sqm:
                  </span>
                </li>
                <p>
                  The Tunnel Net House is a simple, cost-effective structure
                  offering basic protection from pests and moderate climate
                  variations. Ideal for crops that need just a little extra
                  protection, it’s a popular choice among farmers seeking
                  affordable yet effective solutions for crop protection.
                </p>
                <li>
                  <span className="fw-bold">
                    Flat Net House - ₹600 per sqm:
                  </span>
                </li>
                <p>
                  A highly economical option, the Flat Net House provides
                  protection against direct sunlight and pests while enhancing
                  ventilation. It's particularly suited for nurseries and crops
                  that require shade. This setup is easy to install and
                  maintain, offering farmers a practical solution at a lower
                  investment.
                </p>
                <li>
                  <span className="fw-bold">
                    Rain Protected Wire Rope Net House - ₹900 per sqm:
                  </span>
                </li>
                <p>
                  For crops sensitive to rain or high humidity, the Rain
                  Protected Wire Rope Net House is an excellent choice. This
                  structure provides shelter from rainfall while maintaining
                  good air circulation, making it ideal for areas with frequent
                  rains.
                </p>
              </ol>
            </p>
            <p>
              Each of these structures provides unique advantages tailored to
              specific crop needs and environmental conditions. For those
              exploring hydroponic farming, we offer specialized solutions that
              integrate seamlessly within these structures, ensuring efficient
              growth with minimal water usage.
            </p>
            <p>
              For further inquiries or assistance in choosing the right
              structure, you can reach out to us via email at{" "}
              <a href="mailto:info@royalgreenhouse.co.in" target="_blank">
                info@royalgreenhouse.co.in
              </a>{" "}
              or visit us at our Satara location:
            </p>
            <p>
              <a href="https://maps.app.goo.gl/zaBudTaYEaCEqjAJ8">
                <i
                  className="fas fa-map-marker-alt"
                  style={{ color: "green", marginRight: "5px" }}
                ></i>
                #F2/B, Sairatn Building, Survey No 517/5, Visava Naka, Satara,
                Maharashtra, 415002
              </a>
              <br />
              <a href="tel:+919960991166">
                <i
                  className="fas fa-phone"
                  style={{ color: "green", marginRight: "5px" }}
                ></i>
                +91-9960-991-166
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
export default Understanding_the_Cost_of_Setting_Up_Different_Types_of_Protected_Cultivation_Structures;
