import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/step1.png";
import CardImg2 from "../../Assets/step2.png";
import CardImg3 from "../../Assets/step3.png";
import CardImg4 from "../../Assets/step4.png";
import Enquiry from "../../Components/Enquiry";
import Accordion from "react-bootstrap/Accordion";
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import NvphAImg from "../../Assets/gallery/27.png";
import nvphaFrame from "../../Assets/nvphFrame.png";

function nvphAframe() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              NVPH A-Frame
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i className="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">
                NVPH A-Frame
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="m-5 mb-2">
            <h6 className="text-green m-2">NVPH A-FRAME</h6>
            <h2 className="m-2">
              Naturally Ventilated Poly House with A Frame N.F.T. System
            </h2>
            {/* <p className='text-muted m-2'>NVPH A Frame is outdoor hydroponic farm setup. The outdoor hydroponics farm method is a type of controlled environment agriculture which allows you to grow various type of leafy greens and vine crops much densely then any other outdoor method, where you can save water use by up to 90%, requires 80 to 95 percent less area, and produce all year round.</p>
                        <p className='text-muted m-2'>
                        Royal Green House is a most recognised brand in the Indian Hydroponics Industry. We install and set up Hydroponics Project Development that allows you to avail the benefits of a completely automated farm with year round production.
                        </p> */}
            <Row className="text-justify">
              <Col lg={6}>
                <p className="text-muted m-2">
                  {" "}
                  A Naturally Ventilated Poly House with an A-Frame N.F.T.
                  (Nutrient Film Technique) System is an agricultural setup that
                  combines controlled environment technology with hydroponics
                  for plant cultivation. Here's a brief overview:
                </p>
                <p className="text-muted m-2">
                  1. *Naturally Ventilated Poly House*: This refers to a
                  greenhouse or polytunnel structure made of a translucent or
                  transparent material, such as polyethylene or polycarbonate.
                  It's designed to allow natural ventilation, meaning it has
                  openings or vents that enable airflow and temperature control
                  without the need for mechanical ventilation systems. This
                  helps maintain a favorable climate for plant growth.
                </p>
                <p className="text-muted m-2">
                  2. *A-Frame N.F.T. System*: The A-Frame N.F.T. system is a
                  hydroponic growing method where plants are grown in channels
                  or troughs in the shape of an "A." These channels are
                  typically made of materials like PVC or plastic, and a thin
                  film of nutrient-rich water flows over the plant roots,
                  providing them with essential nutrients. This system can be
                  placed within the naturally ventilated poly house.
                </p>
                <p className="text-muted m-2">
                  Key Benefits:
                  <ul>
                    <li className="text-muted">
                      Climate Control: The poly house offers protection from
                      adverse weather conditions and allows for temperature
                      regulation, while natural ventilation helps maintain a
                      comfortable environment for plants.
                    </li>
                    <li className="text-muted">
                      {" "}
                      Optimal Nutrient Delivery: The A-Frame N.F.T. system
                      ensures efficient nutrient delivery to plant roots,
                      promoting healthy growth and increased yields.
                    </li>
                    <li className="text-muted">
                      Year-round Cultivation: With the combination of controlled
                      environment and hydroponics, you can grow crops
                      year-round, regardless of external weather conditions.
                    </li>
                    <li className="text-muted">
                      Reduced Pest Pressure: The enclosed environment of the
                      poly house reduces the risk of pests and diseases, leading
                      to healthier plants.
                    </li>
                    <li className="text-muted">
                      {" "}
                      Water Efficiency: Hydroponic systems like N.F.T. are known
                      for their water efficiency compared to traditional
                      soil-based farming.
                    </li>
                    <li className="text-muted">
                      Space Utilization: A-frame designs can be space-efficient,
                      allowing for the cultivation of a significant number of
                      plants in a compact area. However, it's important to note
                      that managing a naturally ventilated poly house with an
                      A-Frame N.F.T. system requires expertise in greenhouse
                      management, hydroponics, and crop care. Proper monitoring
                      and control of factors like temperature, humidity,
                      nutrient levels, and ventilation are essential for
                      successful plant growth and crop production.
                    </li>
                  </ul>
                </p>
              </Col>
              <Col className="p-3" lg={6}>
                <img
                  src={NvphAImg}
                  alt="Nvph flatbed"
                  className="img-fluid rounded"
                  style={{ height: "650px", width: "100%" }}
                />
                <br />
                
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg={12} className=" p-5">
            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-seedling text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow More Food In Less Space
                    </Card.Title>
                    <Card.Text>
                      With protected cultivation and A frame system now we can
                      grow 70 percent more food in the same area.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-wheat-awn text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow anywhere you want
                    </Card.Title>
                    <Card.Text>
                      We can now grow our food anywhere with help of just water
                      and protected cultivation.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-hand-holding-droplet text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      {" "}
                      Less water{" "}
                    </Card.Title>
                    <Card.Text>
                      Now grow crops on 90% less water compared to field crops.
                      This farming is possible at any location even in water
                      scared zones.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-bolt text-green  fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      High value cash crops
                    </Card.Title>
                    <Card.Text>
                      We can grow super-crops or very high value cash crops with
                      controlled environment.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <h6 className="text-green m-2 text-center">
              Our Process In Some Steps
            </h6>
            <h4 className="m-2 text-center fw-bold">
              Building Commercially Successful Hydroponic Farm
            </h4>
          </Col>
        </Row>
        <Row className="p-2 m-3">
          {/* second cards */}
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg1} />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Site Feasibility
                </Card.Title>
                <Card.Text className=" text-white p-1">
                  We help you identify a site location to set up our hydroponic
                  project. We consider locality, levelling of land, examine
                  electricity and water also gather basic information of
                  surrounding that can help us test feasibility.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg2} />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Ideal Farm Construction
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once financial feasibility is analysed we start working on the
                  Construction of the Project. Based on site feasibility and
                  market research we choose between NVPH or Fan-Pad system and
                  also hydroponic system between NFT or Dutch Bucket.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg3} />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Farm Management{" "}
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once construction is completed, we work on automating the farm
                  using IoT devices as well as integrating with farm management.
                  At this stage, we also start training your field team to make
                  sure we continuously grow high quality produce.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg4} />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Contract & Markerting Your Product
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once our field team is ready and the complete farm is
                  automated, Our team of agronomists, sales and planning starts
                  working on the contract and set a selling mechanism for your
                  farm, with a schedule of crops.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Acordian */}
        <Row>
          <h4 className="m-2 text-center fw-bold">NVPH Specifications</h4>

          <Col lg={6}>
            <Accordion flush className="m-4 p-2">
              <Accordion.Item eventKey="0">
                <Accordion.Header>What is the type of system?</Accordion.Header>
                <Accordion.Body className="text-muted">
                  This is NVPH (Natural Ventilated Poly House). Where
                  temperature is maintained by creating vents on top side of
                  poly house.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What design style is being used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  We use ‘Butterfly Design’ style to create this NVPH. Butterfly
                  design is proven design to withstand wind speed upto 65km/hr.
                  Also Maintenance requirement for butterfly design is lower
                  compared to other designs.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Where can you construct this system?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  We can construct this almost anywhere where we can find strong
                  base. Mostly NVPH is installed on land, terrace, any hard
                  surface or concrete foundation.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  What is the bay size of NVPH?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  We strictly follow bay size of 8m X 4m (Width of NVPH should
                  be 35% of the desired length) as it provides ample of
                  sunlight, air flow and humidity.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  What is the ridge height of structure?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  We keep ridge height of playhouse at 6.5 meter.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>How ridge vent is planned? </Accordion.Header>
                <Accordion.Body className="text-muted">
                  In this butterfly design vents are top open.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>What is gutter height? </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Gutter is placed at 4.5meter.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  What gutter slope is maintained?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Gutter slope is maintained at 2%.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  What structure material is used?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Entire structure is made up of GI (Galvanised Iron) Material.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  What aerodynamics is considered for designing the structure?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  The structural design will be sound enough to withstand wind
                  speed minimum 120 km/hr and minimum load of 25kg/m2.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  What measures are considered to develop the structure?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Complete structure made of galvanized steel tubular pipes of
                  equivalent section conforming Indian Standards having wall
                  thickness 2mm.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header>
                  What thickness of columns is used?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  All columns are 76 mm outer diameter, with 2mm of thickness.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header>
                  What are trusses specification?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Bottom & top cord are of 60 mm outer diameter with 2 mm
                  thickness.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13">
                <Accordion.Header>
                  What trusses member & Purlin specification used?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Trusses members and purlin are of 48mm and 42 mm outer
                  diameter with 2mm thickness.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          <Col lg={6}>
            <Accordion flush className="m-4 p-2">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What specifications are followed for purlin members?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Purline members are of 33mm and 25 mm with 2mm thickness.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What thickness specifications are used for foundations?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  GI Pipes of minimum 60 mm OD or more to Foundation depth of 75
                  cm or more depending upon soil type and prevailing wind
                  condition, grouted with cement concrete mixture of 1:2:4 using
                  telescopic insertion of column.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  What foundation accessories are considered?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  For foundation all nuts & bolts will be of high tensile
                  strength and galvanized materials.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  How entrance room & door are designed?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  One entrance room of size 4m x 3m x 2m (L x W x H) will be
                  provided and covered with 200 micron UV stabilized transparent
                  plastic film.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  What are the size and specification of doors.{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Doors of size 2m width & 2.5m height double leaf made in
                  plastic/FRP sheets mounted in a suitable strong frame.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  What cladding material is used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  UV stabilized 200 micron transparent plastics films.
                  Conforming Indian Standards (IS 15827: 2009), multilayered,
                  anti-drip, anti-fog, anti-sulphur, diffused, clear and having
                  minimum 85% level of light transmittance.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  What quality of spring insert are used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Zigzag high carbon steel with spring action wire of 2-3 mm
                  diameter is inserted to fix the sheet into Aluminium Profile
                  or GI Profile.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  What curtains and Insect screen are used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Roll up UV stabilized 200 micron transparent plastic film as
                  curtains will be provided up 2.5 to 3.0 m height on one side
                  with manual opening and closing of curtains. 40 mesh nylon
                  Insect proof/ mono mono nets (UV stabilized) of equivalent
                  size will be fixed Inside the curtains.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  What quality of shade net is used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Anti UV stabilized 50% shading net with manually operated
                  mechanism for expanding and retracting. Size of the net will
                  be equal to the floor area of the greenhouse.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  How 4-way foggers assembly is used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  We use 65 Micron, LPD fitted, High pressure 4-way foggers with
                  lateral piping and PVC headers fittings. High pressure 5.5 KG
                  submersible pump with pressure gauge, Air release valve is
                  used. sand filter etc.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  What quality of vent opener are used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  All vent openers are made up of brass, Pushback auto locking
                  with gear vent opener on all sides are used. Totally 4 number
                  of openers are used.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header>
                  What kind of GI wires are used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Galvanised GI wire are used for the rope ways.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header>
                  What filtering mechanism is used for water filtration?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  We use sand filters to filter the water in system to help out
                  filter coco peat or other irregular substances.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13">
                <Accordion.Header>
                  What measures are used for fixing of cladding material?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  All ends/joints of plastic film will be fixed with a two way
                  aluminium/GI Profile with suitable locking arrangement along
                  with curtain top.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <TestimonialsSlider />
      <Enquiry />
    </>
  );
}

export default nvphAframe;
