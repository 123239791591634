import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import hydroponic_farm_img from "../../Assets/hydroponic_farm_img.jpeg";
import "./blog.css";
import "animate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

function Hydroponic_Farming() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={hydroponic_farm_img}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ width: "90%", maxWidth: "600px" }}
          alt="Hydroponics farm"
        />
      </div>

      <Row className="m-2">
        <Col
          className="p-4 textJustify"
          sm={12}
          md={9}
          xs={12}
          style={{ margin: "auto", fontSize: "1rem", lineHeight: "1.6"}}
          id="content"
        >
          <div
            id="blog1"
            className="border p-3"
            style={{ borderRadius: "10px" }}
          >
            <h1 className="text-center mb-3">Hydroponic Farming:</h1>{" "}
            <h2 className="text-center mb-4">
              How High-Tech Agriculture Adapts to Climate Change
            </h2>
            <p className="pb-3">
              In recent years, climate change has posed significant challenges
              to traditional farming practices. Rising temperatures,
              unpredictable rainfall, and extreme weather events have impacted
              agricultural yields, leading to concerns about global food
              security. As a result, innovative solutions like hydroponic
              farming have emerged, offering a high-tech, sustainable
              alternative that can thrive even in adverse climate conditions.
            </p>
            <h4>What is Hydroponic Farming?</h4>
            <p>
              Hydroponic farming is a method of growing plants without soil.
              Instead of relying on traditional earth-based methods, plants are
              grown in nutrient-rich water solutions, providing them with the
              exact nutrients they need for optimal growth. This approach offers
              numerous benefits, such as better resource management, increased
              yield, and the ability to grow crops year-round, regardless of
              outdoor climate conditions.
            </p>
            <p className="pb-4">
              In hydroponic systems, plants are housed in controlled
              environments, such as greenhouses or indoor farms. These systems
              are equipped with advanced technologies that regulate temperature,
              humidity, light, and nutrient levels, ensuring ideal growing
              conditions. This precision-based agriculture allows crops to grow
              faster, produce more, and use less water than conventional farming
              methods.
            </p>
            <h4>Adapting to Climate Change:</h4>
            <p>
              Climate change is reshaping the future of agriculture, and farmers
              are increasingly looking for ways to protect their crops from
              unpredictable weather patterns. Hydroponic farming provides a
              solution by offering a controlled environment that is not affected
              by external factors like drought, heatwaves, or floods. By
              reducing reliance on natural rainfall and using technology to
              monitor and manage water and nutrients, hydroponic systems can
              operate efficiently even in water-scarce regions.
            </p>
            <p className="pb-4">
              Additionally, hydroponics eliminates the need for large tracts of
              arable land, which are shrinking due to urbanization and
              desertification. This means that food can be grown in urban areas
              or regions with poor soil quality, bringing agriculture closer to
              consumers and reducing the carbon footprint associated with
              transporting food long distances.
            </p>
            <h4>High-Tech Solutions for Sustainable Farming:</h4>
            <p className="pb-4">
              One of the most exciting aspects of hydroponic farming is its
              integration with modern technology. IoT (Internet of Things)
              devices, automation, and data analytics play a critical role in
              optimizing hydroponic systems. Sensors placed in the growing
              environment track key variables such as temperature, humidity, pH
              levels, and nutrient concentrations. This data is then fed into
              automated systems that adjust conditions in real time to ensure
              the best possible growing environment for the plants.
            </p>
            <p className="pb-4">
              For example, IoT-based timers can control the frequency of
              irrigation and nutrient delivery, while automated climate control
              systems maintain the perfect temperature and humidity levels. This
              high-tech approach reduces human error, minimizes water usage, and
              maximizes the efficiency of nutrient delivery.
            </p>
            <h4>The Role of Royal Green House (RGCPL):</h4>
            <p className="pb-4">
              Royal Green House (RGCPL) is at the forefront of the hydroponic
              revolution in India. As a provider of advanced hydroponic
              solutions, RGCPL specializes in setting up and managing hydroponic
              systems that are tailored to the specific needs of growers.
              Whether it's for commercial farms or urban agriculture projects,
              RGCPL offers end-to-end solutions, including the design,
              installation, and maintenance of hydroponic setups.
            </p>
            <p className="pb-4">
              RGCPL is also an authorized distributor of Bluelab, a leading
              brand in precision growing tools. These tools, combined with
              RGCPL’s expertise, enable growers to monitor and optimize their
              hydroponic systems, ensuring healthy, high-yielding crops.
            </p>
            <p className="pb-4">
              With the increasing challenges posed by climate change, RGCPL’s
              hydroponic systems provide a viable solution for sustainable
              agriculture. By leveraging IoT-based automation, climate control,
              and precision farming tools, RGCPL helps growers maximize their
              yield while minimizing environmental impact.
            </p>
            <h4>Why Choose Hydroponics with RGCPL?</h4>
            <p>
              Hydroponic farming offers several advantages over traditional
              farming methods, particularly in the face of climate change. It
              uses up to 90% less water, allows for year-round crop production,
              and reduces the need for harmful pesticides. Furthermore, because
              hydroponic systems are highly efficient and scalable, they can be
              used to grow a wide variety of crops, from leafy greens and herbs
              to fruits and vegetables.
            </p>
            <p>
              RGCPL offers growers the expertise, equipment, and support needed
              to set up successful hydroponic farms. With over a decade of
              experience in the field, RGCPL has helped numerous farmers
              transition to hydroponics and achieve consistent, high-quality
              yields, regardless of climate challenges.
            </p>
            <div className="d-flex align-items-center mb-3">
              <FontAwesomeIcon icon={faPhone} className="me-2 text-success" />
              <span>
                Phone:{" "}
                <a
                  href="tel:+919960991166"
                  className="text-primary text-decoration-none"
                >
                  +91-9960-991-166
                </a>
              </span>
            </div>
            <div className="d-flex align-items-center mb-3">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="me-2 text-success"
              />
              <span>
                Email:{" "}
                <a
                  href="mailto:info@royalgreenhouse.co.in"
                  target="_blank"
                  className="text-primary text-decoration-none"
                >
                  info@royalgreenhouse.co.in
                </a>
              </span>
            </div>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faGlobe} className="me-2 text-success" />
              <span>
                Website:{" "}
                <a
                  href="http://www.royalgreenhouse.co.in/"
                  className="text-primary text-decoration-none"
                >
                  www.royalgreenhouse.co.in
                </a>
              </span>
            </div>
            <p>
              Hydroponic farming is the future of agriculture, and with
              companies like RGCPL leading the way, growers can adapt to climate
              change while ensuring sustainable and efficient food production
              for generations to come.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Hydroponic_Farming;
