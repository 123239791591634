import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import axios from "axios";
import Layout from "./Pages/Layout";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Blog from "./Pages/Blog";
import HowToReduceFutureMaintenanceInPolyhouseFarming from "./Pages/Blog/HowToReduceFutureMaintenanceInPolyhouseFarming";
import NVPHBlog from "./Pages/Blog/NVPHBlog";
import FanpadAFrame_Blog from "./Pages/Blog/FanpadAFrame_Blog";
import NVPHFlatbed_Blog from "./Pages/Blog/NVPHFlatbed_Blog";
import ClimateOverview_Blog from "./Pages/Blog/ClimateOverview_Blog";
import Hydroponic_Farming_In_India from "./Pages/Blog/Hydroponic_Farming_In_India";
import Commercial_Hydroponic_Farming from "./Pages/Blog/Commercial_Hydroponic_Farming";
import Polyhouse_Farming_Benefits_and_Setup from "./Pages/Blog/Polyhouse_Farming_Benefits_and_Setup";
import Types_of_Hydroponics_Systems from "./Pages/Blog/Types_of_Hydroponics_Systems";
import Reasons_to_invest_in_automated_dosing_systems from "./Pages/Blog/Reasons_to_invest_in_automated_dosing_systems";
import Downy_Mildew_Infestation_in_Cucumber from "./Pages/Blog/Downy_Mildew_Infestation_in_Cucumber";
import Hydroponic_Farming from "./Pages/Blog/Hydroponic_Farming";
import Guide_to_Poly_House_Subsidy_in_India_by_Royal_Green_House from "./Pages/Blog/Guide_to_Poly_House_Subsidy_in_India_by_Royal_Green_House";
import RGCPL_Leading_the_Way_in_Polyhouse_Construction_Hydroponics_Innovation_in_India from "./Pages/Blog/RGCPL_Leading_the_Way_in_Polyhouse_Construction_Hydroponics_Innovation_in_India";
import Ultimate_Guide_to_Polyhouse_Construction from "./Pages/Blog/Ultimate_Guide_to_Polyhouse_Construction";
import Understanding_the_Cost_of_Setting_Up_Different_Types_of_Protected_Cultivation_Structures from "./Pages/Blog/Understanding_the_Cost_of_Setting_Up_Different_Types_of_Protected_Cultivation_Structures";

import Contact from "./Pages/Contact";
import AdvanceHydroponicFarmingTechnology from "./Pages/AdvanceHydroponicFarmingTechnology";
import ExportShop from "./Pages/ExportShop";
import Products from "./Pages/Projects";
import Services from "./Pages/Services";
import NoPage from "./Pages/NoPage";
import Terms from "././Pages/Terms";
import Privacy from "././Pages/Privacy";
import Refund from "././Pages/Refund";
import FmcgProducts from "././Pages/FmcgProducts";
import CustomerReview from "././Pages/CustomerReview";
import NftAframe from "./Pages/NftAframe";
import NftFlatbed from "./Pages/NftFlatbed";
import DFTAFrame from "./Pages/DFTAFrame";
import DWC from "./Pages/DWC";
import DutchBucket from "./Pages/DutchBucket";
import FanbadAframe from "./Pages/FanbadAframe";
import FanbadFlatbed from "./Pages/FanbadFlatbed";
import IndoorVertical from "./Pages/IndoorVertical";
import OurProjects from "./Pages/OurProjects";
import Gallery from "./Pages/Gallery";
import WorkWithus from "./Pages/WorkWithus";
import Story from "./Pages/Story";
import NvphGrowbag from "./Pages/NvphGrowbag";
import NvphAframe from "./Pages/NvphAframe";
import NvphFlatbed from "./Pages/NvphFlatbed";
import NvphDutchBucket from "./Pages/NvphDutchBucket";
import RainProtectedWireRopeNetHouse from "./Pages/RainProtectedWireRopeNetHouse";
import RequestAQuote from "./Pages/RequestAQuote";

import Flat_Shade_Net_House from "./Pages/Flat_Shade_Net_House";
import Videos from "./Pages/Videos";
import Polycarbonet from "./Pages/Polycarbonet";
import ClimateControlled from "./Pages/ClimateControlled";
import ShadowHall from "./Pages/ShadowHall";
import Login from "./Pages/Login";
import Nvph from "./Pages/Nvph";
import DosingSystem from "./Pages/DosingSystem";
import TempControllers from "./Pages/TempControllers";
import LowcostGreenhouse from "./Pages/LowcostGreenhouse";
import Dashboard from "./Pages/Dashboard";
import FreshHerbs from "./Pages/FreshHerbs";
import Lettuces from "./Pages/Lettuces";
import VineCrops from "./Pages/VineCrops";
import LeafyGreens from "./Pages/LeafyGreens";
import Horticulture from "./Pages/Horticulture";
import Floriculture from "./Pages/Floriculture";
import TawkToChat from "./Components/TawkToChat/TawkToChat";
import TunnelShadeHouse from "./Pages/TunnelShadeHouse";
import Grow_Slab from "./Pages/Grow_Slab";
import Grow_bag from "./Pages/Grow_bag";
import Automation_Products_Pens from "./Pages/Automation_Products_Pens";

import Top10_hydroponic_crops_for_india from "./Pages/Blog/Top10_hydroponic_crops_for_india";
import Product1 from "./Pages/Automation_Products_Pens/Product1";
import Product2 from "./Pages/Automation_Products_Pens/Product2";
import Product3 from "./Pages/Automation_Products_Pens/Product3";
import Product4 from "./Pages/Automation_Products_Pens/Product4";
import Product5 from "./Pages/Automation_Products_Pens/Product5";
import Automation_Products_Monitors from "./Pages/Automation_Products_Monitors";
import Autogrow_EnviroSensor_IntelliClimate_CO2_Sensor from "./Pages/Automation_Products_Monitors/Autogrow_EnviroSensor_IntelliClimate_CO2_Sensor";
import Autogrow_IntelliClimate_Kit from "./Pages/Automation_Products_Monitors/Autogrow_IntelliClimate_Kit";
import Bluelab_External_Lockout_and_Alarm_Box from "./Pages/Automation_Products_Monitors/Bluelab_External_Lockout_and_Alarm_Box";
import Bluelab_Guardian_Monitor_Inline_WiFi from "./Pages/Automation_Products_Monitors/Bluelab_Guardian_Monitor_Inline_WiFi";
import Bluelab_Guardian_Monitor_WiFi from "./Pages/Automation_Products_Monitors/Bluelab_Guardian_Monitor_WiFi";
import Bluelab_IntelliDose_Controller_Kit from "./Pages/Automation_Products_Monitors/Bluelab_IntelliDose_Controller_Kit";
import Bluelab_IntelliLink from "./Pages/Automation_Products_Monitors/Bluelab_IntelliLink";
import Bluelab_PeriPod_L3 from "./Pages/Automation_Products_Monitors/Bluelab_PeriPod_L3";
import Bluelab_PeriPod_M3 from "./Pages/Automation_Products_Monitors/Bluelab_PeriPod_M3";
import Bluelab_pH_Controller_WiFi from "./Pages/Automation_Products_Monitors/Bluelab_pH_Controller_WiFi";
import Bluelab_pH_Probe_5M from "./Pages/Automation_Products_Monitors/Bluelab_pH_Probe_5M";
import Bluelab_pH_Probe from "./Pages/Automation_Products_Monitors/Bluelab_pH_Probe";
import Bluelab_PowerPod from "./Pages/Automation_Products_Monitors/Bluelab_PowerPod";
import Bluelab_Pro_Controller_Conductivity_Probe from "./Pages/Automation_Products_Monitors/Bluelab_Pro_Controller_Conductivity_Probe";
import Bluelab_Pro_Controller_WiFi from "./Pages/Automation_Products_Monitors/Bluelab_Pro_Controller_WiFi";
import Bluelab_Sample_Tube_and_Fittings from "./Pages/Automation_Products_Monitors/Bluelab_Sample_Tube_and_Fittings";
import Large_Pump_Cassette from "./Pages/Automation_Products_Monitors/Large_Pump_Cassette";
import Bluelab_PeriPod_M4 from "./Pages/Automation_Products_Monitors/Bluelab_PeriPod_M4";
import Pump_and_Cassette_L3 from "./Pages/Automation_Products_Monitors/Pump_and_Cassette_L3";
import Pump_and_Cassette_M3_M4 from "./Pages/Automation_Products_Monitors/Pump_and_Cassette_M3_M4";
import Pump_and_Cassette_replacement_for_pH_Controller from "./Pages/Automation_Products_Monitors/Pump_and_Cassette_replacement_for_pH_Controller";
import Replacement_pH_Controller_Tubing from "./Pages/Automation_Products_Monitors/Replacement_pH_Controller_Tubing";
import Replacement_Pump_Cassette from "./Pages/Automation_Products_Monitors/Replacement_Pump_Cassette";
import Automation_Products_Meters from "./Pages/Automation_Products_Meters";

import Battery_Cap_for_Truncheon_Meter_V2 from "./Pages/Automation_Products_Meters/Battery_Cap_for_Truncheon_Meter_V2";
import Bluelab_Combo_Meter_with_Leap_pH_Probe from "./Pages/Automation_Products_Meters/Bluelab_Combo_Meter_with_Leap_pH_Probe";
import Bluelab_Combo_Meter from "./Pages/Automation_Products_Meters/Bluelab_Combo_Meter";
import Bluelab_Commercial_Truncheon_Nutrient_Meter from "./Pages/Automation_Products_Meters/Bluelab_Commercial_Truncheon_Nutrient_Meter";
import Bluelab_Leap_pH_Probe from "./Pages/Automation_Products_Meters/Bluelab_Leap_pH_Probe";
import Bluelab_pH_Probe_5ms from "./Pages/Automation_Products_Meters/Bluelab_pH_Probe_5ms";
import Bluelab_Multimedia_pH_Meter from "./Pages/Automation_Products_Meters/Bluelab_Multimedia_pH_Meter";
import Bluelab_Pulse_Multimedia from "./Pages/Automation_Products_Meters/Bluelab_Pulse_Multimedia";
import Bluelab_Truncheon_Nutrient_Meter from "./Pages/Automation_Products_Meters/Bluelab_Truncheon_Nutrient_Meter";
import Shroud_for_Truncheon_Meter_V2 from "./Pages/Automation_Products_Meters/Shroud_for_Truncheon_Meter_V2";

import Automation_Products_Testing_and_Maintenance from "./Pages/Automation_Products_Testing_and_Maintenance";
import Bluelab_pH_Probe_KCl_Storage_Solution from "./Pages/Automation_Products_Testing_and_Maintenance/Bluelab_pH_Probe_KCl_Storage_Solution";
import Bluelab_pH_Probe_KCl_Storage_Solution_250ml from "./Pages/Automation_Products_Testing_and_Maintenance/Bluelab_pH_Probe_KCl_Storage_Solution_250ml";
import Bluelab_Probe_Care_Kit_Conductivity from "./Pages/Automation_Products_Testing_and_Maintenance/Bluelab_Probe_Care_Kit_Conductivity";
import Bluelab_Probe_Care_Kit_pH_and_Conductivity from "./Pages/Automation_Products_Testing_and_Maintenance/Bluelab_Probe_Care_Kit_pH_and_Conductivity";
import Bluelab_Probe_Care_Kit_pH from "./Pages/Automation_Products_Testing_and_Maintenance/Bluelab_Probe_Care_Kit_pH";
import Bluelab_Sample_Pot from "./Pages/Automation_Products_Testing_and_Maintenance/Bluelab_Sample_Pot";

import Greenhouse_Accessories from "./Pages/Greenhouse_Accessories";
import Poly_House_Gutter_Plate from "./Pages/Greenhouse_Accessories/Poly_House_Gutter_Plate";
import Shadenet_stitching_pin from "./Pages/Greenhouse_Accessories/Shadenet_stitching_pin";
import Tension_Hook_for_Polyhouse from "./Pages/Greenhouse_Accessories/Tension_Hook_for_Polyhouse";
import Shade_Net_Pulley_for_Greenhouse_Systems from "./Pages/Greenhouse_Accessories/Shade_Net_Pulley_for_Greenhouse_Systems";
import Durling_Clamps from "./Pages/Greenhouse_Accessories/Durling_Clamps";
import Zigzag_Spring_Clip from "./Pages/Greenhouse_Accessories/Zigzag_Spring_Clip";
import U_Shape_Support_Clip from "./Pages/Greenhouse_Accessories/U_Shape_Support_Clip";
import Self_Drilling_Screw_Set_with_EPDM_Washers from "./Pages/Greenhouse_Accessories/Self_Drilling_Screw_Set_with_EPDM_Washers";
import U_Shaped_Clamp_by_RGCPL from "./Pages/Greenhouse_Accessories/U_Shaped_Clamp_by_RGCPL";
import Pipe_Joiner_Connector_Kit_by_RGCPL from "./Pages/Greenhouse_Accessories/Pipe_Joiner_Connector_Kit_by_RGCPL";
import L_Bracket_Corner_Bracket_by_RGCPL from "./Pages/Greenhouse_Accessories/L_Bracket_Corner_Bracket_by_RGCPL";
import Wire_Tensioning_Cable_Guy_Wire from "./Pages/Greenhouse_Accessories/Wire_Tensioning_Cable_Guy_Wire";
import Galvanized_Iron_Wire from "./Pages/Greenhouse_Accessories/Galvanized_Iron_Wire";
import Galvanized_Iron_Channel_GI_Channel from "./Pages/Greenhouse_Accessories/Galvanized_Iron_Channel_GI_Channel";
import Greenhouse_Support_Clamp from "./Pages/Greenhouse_Accessories/Greenhouse_Support_Clamp";
import Twisted_Rope_Tensioning_Rope from "./Pages/Greenhouse_Accessories/Twisted_Rope_Tensioning_Rope";
import T_Joint_Pipe_Connector from "./Pages/Greenhouse_Accessories/T_Joint_Pipe_Connector";
import Pulley_Wheel_for_Greenhouse from "./Pages/Greenhouse_Accessories/Pulley_Wheel_for_Greenhouse";
import Greenhouse_Pipe_Clamp from "./Pages/Greenhouse_Accessories/Greenhouse_Pipe_Clamp";
import Greenhouse_Base_Plate_Corner_Support_Plate from "./Pages/Greenhouse_Accessories/Greenhouse_Base_Plate_Corner_Support_Plate";
import Greenhouse_Clamp_Set_Wire_Hook_Tensioner_Set from "./Pages/Greenhouse_Accessories/Greenhouse_Clamp_Set_Wire_Hook_Tensioner_Set";
import Greenhouse_Curtain_Pulley from "./Pages/Greenhouse_Accessories/Greenhouse_Curtain_Pulley";
import Greenhouse_Spring_Clip_Wire_Fastening_Hook from "./Pages/Greenhouse_Accessories/Greenhouse_Spring_Clip_Wire_Fastening_Hook";
import Greenhouse_Pipe_Saddle_Clamp from "./Pages/Greenhouse_Accessories/Greenhouse_Pipe_Saddle_Clamp";
import Greenhouse_Flat_Support_Plate from "./Pages/Greenhouse_Accessories/Greenhouse_Flat_Support_Plate";
import Round_Pipe_Clamp_Gable_Shaped_Polyhouse_Clamp from "./Pages/Greenhouse_Accessories/Round_Pipe_Clamp_Gable_Shaped_Polyhouse_Clamp";
import Pulley_with_Lock_Mechanism_Gable_Shaped_Polyhouse_Pulley from "./Pages/Greenhouse_Accessories/Pulley_with_Lock_Mechanism_Gable_Shaped_Polyhouse_Pulley";
import Plant_Support_Clip_for_vinecrops from "./Pages/Greenhouse_Accessories/Plant_Support_Clip_for_vinecrops";
import Greenhouse_Pipe_Bracket from "./Pages/Greenhouse_Accessories/Greenhouse_Pipe_Bracket";
import Greenhouse_Bolt_and_Nut_Kit from "./Pages/Greenhouse_Accessories/Greenhouse_Bolt_and_Nut_Kit";
import Greenhouse_Pulley_with_Lock from "./Pages/Greenhouse_Accessories/Greenhouse_Pulley_with_Lock";

import H_hook from "./Pages/Greenhouse_Accessories/H_hook";
import Greenhouse_Pipe_Support_Clamp from "./Pages/Greenhouse_Accessories/Greenhouse_Pipe_Support_Clamp";

import CartModal from "./Components/CartModal";
import Checkout from "./Components/Checkout";
import { CartProvider } from "./Contexts/CartContext";

function App() {
  const [galleryData, setGalleryData] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  const [isLogin, setIsLogin] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false); // State for cart modal visibility

  useEffect(() => {
    async function fetchGalleryData() {
      let tempgalleryData = await fetch(
        "//royalgreenhouse.co.in/crud.php?getGalleryData=true"
      )
        .then((response) => response.json())
        .then((response) => response)
        .catch((error) => {
          return "error";
        });
      if (tempgalleryData === "error") {
        console.log("php error");
      } else {
        setGalleryData(tempgalleryData);
      }
    }
    fetchGalleryData();

    async function fetchReviewData() {
      let tempReviewData = await fetch(
        "//royalgreenhouse.co.in/crud.php?getReviewData=true"
      )
        .then((response) => response.json())
        .then((response) => response)
        .catch((error) => {
          return "error";
        });
      if (tempReviewData === "error") {
        console.log("php error");
      } else {
        setReviewData(tempReviewData);
      }
    }
    fetchReviewData();
  }, []);

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen); // Toggle modal visibility
  };

  return (
    <CartProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout toggleCart={toggleCart} />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="fmcgProducts" element={<FmcgProducts />} />
            <Route path="NftAframe" element={<NftAframe />} />
            <Route path="NftFlatbed" element={<NftFlatbed />} />
            <Route path="DFTAFrame" element={<DFTAFrame />} />
            <Route path="DWC" element={<DWC />} />
            <Route path="DutchBucket" element={<DutchBucket />} />
            <Route path="fanbadAframe" element={<FanbadAframe />} />
            <Route path="fanbadFlatbed" element={<FanbadFlatbed />} />
            <Route path="indoorVertical" element={<IndoorVertical />} />
            <Route path="nvphgrowbag" element={<NvphGrowbag />} />
            <Route path="NvphAframe" element={<NvphAframe />} />
            <Route path="NvphFlatbed" element={<NvphFlatbed />} />
            <Route path="Grow_Slab" element={<Grow_Slab />} />
            <Route path="Grow_bag" element={<Grow_bag />} />
            <Route path="nvphdutchbucket" element={<NvphDutchBucket />} />
            <Route path="TunnelShadeHouse" element={<TunnelShadeHouse />} />
            <Route
              path="Flat_Shade_Net_House"
              element={<Flat_Shade_Net_House />}
            />
            <Route path="polycarbonet" element={<Polycarbonet />} />
            <Route path="climatecontrolled" element={<ClimateControlled />} />
            <Route
              path="RainProtectedWireRopeNetHouse"
              element={<RainProtectedWireRopeNetHouse />}
            />
            <Route path="shadowhall" element={<ShadowHall />} />
            <Route
              path="Automation_Products_Pens"
              element={<Automation_Products_Pens toggleCart={toggleCart} />}
            />
            <Route
              path="Product1"
              element={<Product1 toggleCart={toggleCart} />}
            />
            <Route
              path="Product2"
              element={<Product2 toggleCart={toggleCart} />}
            />
            <Route
              path="Product3"
              element={<Product3 toggleCart={toggleCart} />}
            />
            <Route
              path="Product4"
              element={<Product4 toggleCart={toggleCart} />}
            />
            <Route
              path="Product5"
              element={<Product5 toggleCart={toggleCart} />}
            />
            <Route
              path="Automation_Products_Monitors"
              element={<Automation_Products_Monitors toggleCart={toggleCart} />}
            />
            <Route
              path="Autogrow_EnviroSensor_IntelliClimate_CO2_Sensor"
              element={
                <Autogrow_EnviroSensor_IntelliClimate_CO2_Sensor
                  toggleCart={toggleCart}
                />
              }
            />
            <Route
              path="Autogrow_IntelliClimate_Kit"
              element={<Autogrow_IntelliClimate_Kit toggleCart={toggleCart} />}
            />
            <Route
              path="Bluelab_External_Lockout_and_Alarm_Box"
              element={
                <Bluelab_External_Lockout_and_Alarm_Box
                  toggleCart={toggleCart}
                />
              }
            />
            <Route
              path="Bluelab_Guardian_Monitor_Inline_WiFi"
              element={
                <Bluelab_Guardian_Monitor_Inline_WiFi toggleCart={toggleCart} />
              }
            />
            <Route
              path="Bluelab_Guardian_Monitor_WiFi"
              element={
                <Bluelab_Guardian_Monitor_WiFi toggleCart={toggleCart} />
              }
            />
            <Route
              path="Bluelab_IntelliDose_Controller_Kit"
              element={
                <Bluelab_IntelliDose_Controller_Kit toggleCart={toggleCart} />
              }
            />
            <Route
              path="Bluelab_IntelliLink"
              element={<Bluelab_IntelliLink toggleCart={toggleCart} />}
            />
            <Route
              path="Bluelab_PeriPod_L3"
              element={<Bluelab_PeriPod_L3 toggleCart={toggleCart} />}
            />
            <Route
              path="Bluelab_PeriPod_M3"
              element={<Bluelab_PeriPod_M3 toggleCart={toggleCart} />}
            />
            <Route
              path="Bluelab_pH_Controller_WiFi"
              element={<Bluelab_pH_Controller_WiFi toggleCart={toggleCart} />}
            />

            <Route
              path="Bluelab_pH_Probe"
              element={<Bluelab_pH_Probe toggleCart={toggleCart} />}
            />
            <Route
              path="Bluelab_pH_Probe_5M"
              element={<Bluelab_pH_Probe_5M toggleCart={toggleCart} />}
            />
            <Route
              path="Bluelab_PowerPod"
              element={<Bluelab_PowerPod toggleCart={toggleCart} />}
            />
            <Route
              path="Bluelab_Pro_Controller_Conductivity_Probe"
              element={
                <Bluelab_Pro_Controller_Conductivity_Probe
                  toggleCart={toggleCart}
                />
              }
            />
            <Route
              path="Bluelab_Pro_Controller_WiFi"
              element={<Bluelab_Pro_Controller_WiFi toggleCart={toggleCart} />}
            />
            <Route
              path="Bluelab_Sample_Tube_and_Fittings"
              element={
                <Bluelab_Sample_Tube_and_Fittings toggleCart={toggleCart} />
              }
            />
            <Route
              path="Large_Pump_Cassette"
              element={<Large_Pump_Cassette toggleCart={toggleCart} />}
            />
            <Route
              path="Bluelab_PeriPod_M4"
              element={<Bluelab_PeriPod_M4 toggleCart={toggleCart} />}
            />
            <Route
              path="Pump_and_Cassette_L3"
              element={<Pump_and_Cassette_L3 toggleCart={toggleCart} />}
            />
            <Route
              path="Pump_and_Cassette_M3_M4"
              element={<Pump_and_Cassette_M3_M4 toggleCart={toggleCart} />}
            />
            <Route
              path="Pump_and_Cassette_replacement_for_pH_Controller"
              element={
                <Pump_and_Cassette_replacement_for_pH_Controller
                  toggleCart={toggleCart}
                />
              }
            />
            <Route
              path="Replacement_pH_Controller_Tubing"
              element={
                <Replacement_pH_Controller_Tubing toggleCart={toggleCart} />
              }
            />
            <Route
              path="Replacement_Pump_Cassette"
              element={<Replacement_Pump_Cassette toggleCart={toggleCart} />}
            />

            <Route
              path="Automation_Products_Meters"
              element={<Automation_Products_Meters toggleCart={toggleCart} />}
            />
            <Route
              path="Shroud_for_Truncheon_Meter_V2"
              element={
                <Shroud_for_Truncheon_Meter_V2 toggleCart={toggleCart} />
              }
            />

            <Route
              path="Bluelab_Truncheon_Nutrient_Meter"
              element={
                <Bluelab_Truncheon_Nutrient_Meter toggleCart={toggleCart} />
              }
            />

            <Route
              path="Bluelab_Pulse_Multimedia"
              element={<Bluelab_Pulse_Multimedia toggleCart={toggleCart} />}
            />

            <Route
              path="Bluelab_pH_Probe_5ms"
              element={<Bluelab_pH_Probe_5ms toggleCart={toggleCart} />}
            />
            <Route
              path="Bluelab_Multimedia_pH_Meter"
              element={<Bluelab_Multimedia_pH_Meter toggleCart={toggleCart} />}
            />
            <Route
              path="Bluelab_Leap_pH_Probe"
              element={<Bluelab_Leap_pH_Probe toggleCart={toggleCart} />}
            />
            <Route
              path="Bluelab_Commercial_Truncheon_Nutrient_Meter"
              element={
                <Bluelab_Commercial_Truncheon_Nutrient_Meter
                  toggleCart={toggleCart}
                />
              }
            />
            <Route
              path="Bluelab_Combo_Meter"
              element={<Bluelab_Combo_Meter toggleCart={toggleCart} />}
            />
            <Route
              path="Bluelab_Combo_Meter_with_Leap_pH_Probe"
              element={
                <Bluelab_Combo_Meter_with_Leap_pH_Probe
                  toggleCart={toggleCart}
                />
              }
            />
            <Route
              path="Battery_Cap_for_Truncheon_Meter_V2"
              element={
                <Battery_Cap_for_Truncheon_Meter_V2 toggleCart={toggleCart} />
              }
            />

            <Route
              path="Automation_Products_Testing_and_Maintenance"
              element={
                <Automation_Products_Testing_and_Maintenance
                  toggleCart={toggleCart}
                />
              }
            />
            <Route
              path="Bluelab_pH_Probe_KCl_Storage_Solution"
              element={
                <Bluelab_pH_Probe_KCl_Storage_Solution
                  toggleCart={toggleCart}
                />
              }
            />
            <Route
              path="Bluelab_pH_Probe_KCl_Storage_Solution_250ml"
              element={
                <Bluelab_pH_Probe_KCl_Storage_Solution_250ml
                  toggleCart={toggleCart}
                />
              }
            />
            <Route
              path="Bluelab_Probe_Care_Kit_Conductivity"
              element={
                <Bluelab_Probe_Care_Kit_Conductivity toggleCart={toggleCart} />
              }
            />
            <Route
              path="Bluelab_Probe_Care_Kit_pH_and_Conductivity"
              element={
                <Bluelab_Probe_Care_Kit_pH_and_Conductivity
                  toggleCart={toggleCart}
                />
              }
            />
            <Route
              path="Bluelab_Probe_Care_Kit_pH"
              element={<Bluelab_Probe_Care_Kit_pH toggleCart={toggleCart} />}
            />
            <Route
              path="Bluelab_Sample_Pot"
              element={<Bluelab_Sample_Pot toggleCart={toggleCart} />}
            />

            <Route
              path="Greenhouse_Accessories"
              element={<Greenhouse_Accessories toggleCart={toggleCart} />}
            />
            <Route
              path="Poly_House_Gutter_Plate"
              element={<Poly_House_Gutter_Plate toggleCart={toggleCart} />}
            />
            <Route
              path="Shadenet_stitching_pin"
              element={<Shadenet_stitching_pin toggleCart={toggleCart} />}
            />
            <Route
              path="Tension_Hook_for_Polyhouse"
              element={<Tension_Hook_for_Polyhouse toggleCart={toggleCart} />}
            />
            <Route
              path="Shade_Net_Pulley_for_Greenhouse_Systems"
              element={
                <Shade_Net_Pulley_for_Greenhouse_Systems
                  toggleCart={toggleCart}
                />
              }
            />
            <Route
              path="Durling_Clamps"
              element={<Durling_Clamps toggleCart={toggleCart} />}
            />
            <Route
              path="Zigzag_Spring_Clip"
              element={<Zigzag_Spring_Clip toggleCart={toggleCart} />}
            />
            <Route
              path="U_Shape_Support_Clip"
              element={<U_Shape_Support_Clip toggleCart={toggleCart} />}
            />
            <Route
              path="Self_Drilling_Screw_Set_with_EPDM_Washers"
              element={
                <Self_Drilling_Screw_Set_with_EPDM_Washers
                  toggleCart={toggleCart}
                />
              }
            />
            <Route
              path="U_Shaped_Clamp_by_RGCPL"
              element={<U_Shaped_Clamp_by_RGCPL toggleCart={toggleCart} />}
            />
            <Route
              path="Pipe_Joiner_Connector_Kit_by_RGCPL"
              element={
                <Pipe_Joiner_Connector_Kit_by_RGCPL toggleCart={toggleCart} />
              }
            />
            <Route
              path="L_Bracket_Corner_Bracket_by_RGCPL"
              element={
                <L_Bracket_Corner_Bracket_by_RGCPL toggleCart={toggleCart} />
              }
            />
            <Route
              path="Wire_Tensioning_Cable_Guy_Wire"
              element={
                <Wire_Tensioning_Cable_Guy_Wire toggleCart={toggleCart} />
              }
            />
            <Route
              path="Galvanized_Iron_Wire"
              element={<Galvanized_Iron_Wire toggleCart={toggleCart} />}
            />
            <Route
              path="Galvanized_Iron_Channel_GI_Channel"
              element={
                <Galvanized_Iron_Channel_GI_Channel toggleCart={toggleCart} />
              }
            />
            <Route
              path="Greenhouse_Support_Clamp"
              element={<Greenhouse_Support_Clamp toggleCart={toggleCart} />}
            />
            <Route
              path="Twisted_Rope_Tensioning_Rope"
              element={<Twisted_Rope_Tensioning_Rope toggleCart={toggleCart} />}
            />
            <Route
              path="T_Joint_Pipe_Connector"
              element={<T_Joint_Pipe_Connector toggleCart={toggleCart} />}
            />
            <Route
              path="Pulley_Wheel_for_Greenhouse"
              element={<Pulley_Wheel_for_Greenhouse toggleCart={toggleCart} />}
            />
            <Route
              path="Greenhouse_Pipe_Clamp"
              element={<Greenhouse_Pipe_Clamp toggleCart={toggleCart} />}
            />
            <Route
              path="Greenhouse_Base_Plate_Corner_Support_Plate"
              element={
                <Greenhouse_Base_Plate_Corner_Support_Plate
                  toggleCart={toggleCart}
                />
              }
            />
            <Route
              path="Greenhouse_Clamp_Set_Wire_Hook_Tensioner_Set"
              element={
                <Greenhouse_Clamp_Set_Wire_Hook_Tensioner_Set
                  toggleCart={toggleCart}
                />
              }
            />
            <Route
              path="Greenhouse_Curtain_Pulley"
              element={<Greenhouse_Curtain_Pulley toggleCart={toggleCart} />}
            />
            <Route
              path="Greenhouse_Spring_Clip_Wire_Fastening_Hook"
              element={
                <Greenhouse_Spring_Clip_Wire_Fastening_Hook
                  toggleCart={toggleCart}
                />
              }
            />
            <Route
              path="Greenhouse_Pipe_Saddle_Clamp"
              element={<Greenhouse_Pipe_Saddle_Clamp toggleCart={toggleCart} />}
            />
            <Route
              path="Greenhouse_Flat_Support_Plate"
              element={
                <Greenhouse_Flat_Support_Plate toggleCart={toggleCart} />
              }
            />
            <Route
              path="Round_Pipe_Clamp_Gable_Shaped_Polyhouse_Clamp"
              element={
                <Round_Pipe_Clamp_Gable_Shaped_Polyhouse_Clamp
                  toggleCart={toggleCart}
                />
              }
            />
            <Route
              path="Pulley_with_Lock_Mechanism_Gable_Shaped_Polyhouse_Pulley"
              element={
                <Pulley_with_Lock_Mechanism_Gable_Shaped_Polyhouse_Pulley
                  toggleCart={toggleCart}
                />
              }
            />
            <Route
              path="Plant_Support_Clip_for_vinecrops"
              element={
                <Plant_Support_Clip_for_vinecrops toggleCart={toggleCart} />
              }
            />
            <Route
              path="Greenhouse_Pipe_Bracket"
              element={<Greenhouse_Pipe_Bracket toggleCart={toggleCart} />}
            />
            <Route
              path="Greenhouse_Bolt_and_Nut_Kit"
              element={<Greenhouse_Bolt_and_Nut_Kit toggleCart={toggleCart} />}
            />
            <Route
              path="Greenhouse_Pulley_with_Lock"
              element={<Greenhouse_Pulley_with_Lock toggleCart={toggleCart} />}
            />

            <Route path="H_hook" element={<H_hook toggleCart={toggleCart} />} />
            <Route
              path="Greenhouse_Pipe_Support_Clamp"
              element={
                <Greenhouse_Pipe_Support_Clamp toggleCart={toggleCart} />
              }
            />

            <Route path="login" elemen t={<Login setLogin={setIsLogin} />} />
            <Route
              path="dashboard"
              element={
                <Dashboard
                  isLogin={isLogin}
                  galleryData={galleryData}
                  reviewData={reviewData}
                />
              }
            />
            <Route path="nvph" element={<Nvph />} />
            <Route path="dosingsystem" element={<DosingSystem />} />
            <Route path="tempcontrollers" element={<TempControllers />} />
            <Route path="lowcostgreenhouse" element={<LowcostGreenhouse />} />
            <Route path="requestaquote" element={<RequestAQuote />} />
            <Route path="OurProjects" element={<OurProjects />} />
            <Route
              path="gallery"
              element={<Gallery galleryData={galleryData} />}
            />
            <Route
              path="customerReview"
              element={<CustomerReview reviewData={reviewData} />}
            />
            <Route path="blog" element={<Blog />} />
            <Route
              path="HowToReduceFutureMaintenanceInPolyhouseFarming"
              element={<HowToReduceFutureMaintenanceInPolyhouseFarming />}
            />
            <Route path="NVPHBlog" element={<NVPHBlog />} />
            <Route path="FanpadAFrame_Blog" element={<FanpadAFrame_Blog />} />
            <Route path="NVPHFlatbed_Blog" element={<NVPHFlatbed_Blog />} />
            <Route
              path="ClimateOverview_Blog"
              element={<ClimateOverview_Blog />}
            />
            <Route
              path="Hydroponic_Farming_In_India"
              element={<Hydroponic_Farming_In_India />}
            />
            <Route
              path="Commercial_Hydroponic_Farming"
              element={<Commercial_Hydroponic_Farming />}
            />
            <Route
              path="Polyhouse_Farming_Benefits_and_Setup"
              element={<Polyhouse_Farming_Benefits_and_Setup />}
            />
            <Route
              path="Types_of_Hydroponics_Systems"
              element={<Types_of_Hydroponics_Systems />}
            />
            <Route
              path="Top10_hydroponic_crops_for_india"
              element={<Top10_hydroponic_crops_for_india />}
            />

            <Route
              path="Reasons_to_invest_in_automated_dosing_systems"
              element={<Reasons_to_invest_in_automated_dosing_systems />}
            />
            <Route
              path="Downy_Mildew_Infestation_in_Cucumber"
              element={<Downy_Mildew_Infestation_in_Cucumber />}
            />
            <Route path="Hydroponic_Farming" element={<Hydroponic_Farming />} />
            <Route
              path="Guide_to_Poly_House_Subsidy_in_India_by_Royal_Green_House"
              element={
                <Guide_to_Poly_House_Subsidy_in_India_by_Royal_Green_House />
              }
            />
            <Route
              path="RGCPL_Leading_the_Way_in_Polyhouse_Construction_Hydroponics_Innovation_in_India"
              element={
                <RGCPL_Leading_the_Way_in_Polyhouse_Construction_Hydroponics_Innovation_in_India />
              }
            />
            <Route
              path="Ultimate_Guide_to_Polyhouse_Construction"
              element={<Ultimate_Guide_to_Polyhouse_Construction />}
            />
            <Route
              path="Understanding_the_Cost_of_Setting_Up_Different_Types_of_Protected_Cultivation_Structures"
              element={
                <Understanding_the_Cost_of_Setting_Up_Different_Types_of_Protected_Cultivation_Structures />
              }
            />

            <Route path="contact" element={<Contact />} />
            <Route path="products" element={<Products />} />
            <Route path="services" element={<Services />} />
            <Route path="WorkWithus" element={<WorkWithus />} />
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="refund" element={<Refund />} />
            <Route
              path="advancehydroponicfarmingtechnology"
              element={<AdvanceHydroponicFarmingTechnology />}
            />
            <Route path="exportshop" element={<ExportShop />} />
            <Route path="story" element={<Story />} />
            <Route path="videos" element={<Videos />} />
            <Route path="freshherbs" element={<FreshHerbs />} />
            <Route path="lettuces" element={<Lettuces />} />
            <Route path="vinecrops" element={<VineCrops />} />
            <Route path="leafygreens" element={<LeafyGreens />} />
            <Route path="Horticulture" element={<Horticulture />} />
            <Route path="Floriculture" element={<Floriculture />} />
            <Route path="Checkout" element={<Checkout />} />
          </Route>
        </Routes>

        {isCartOpen && <CartModal toggleCart={toggleCart} />}
        <TawkToChat />
      </BrowserRouter>
    </CartProvider>
  );
}

export default App;
