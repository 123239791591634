import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory6 from "../../Assets/accessory6.jpeg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Self_Drilling_Screw_Set_with_EPDM_Washers = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 52,
      name: "Self-Drilling Screw Set with EPDM Washers",
      price: 250,
      image: accessory6,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory6}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Self-Drilling Screw Set with EPDM Washers
            </h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The Self-Drilling Screw Set is crafted from high-quality
                  stainless steel and comes with EPDM washers. These screws are
                  designed to fasten plastic films, nets, and covers to
                  greenhouse and polyhouse frames, ensuring secure and
                  watertight attachment.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>Material: stainless Steel with EPDM Washers </li>
                    <li>Color: Silver </li>
                    <li>Length: 1.5 Inches</li>

                    <li>Diameter: 5 mm </li>

                    <li>Brand: RGCPL</li>
                    <li>Durability: 7+ Years</li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>Self-Drilling Tip.</li>
                  <li>stainless Steel.</li>
                  <li>EPDM Washers.</li>
                  <li>Versatility.</li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>Item Code: RGCPL-0001</li>
                    <li>Delivery Time: 7-8 Days </li>
                    <li>Port of Dispatch: Maharashtra, India </li>
                    <li>Production Capacity: 2000 Pieces Per Day </li>
                    <li>Packaging Details: Large Bags </li>
                    <li>Packing Quantity: 500 Clips Per Bag </li>
                    <li>Payment Terms: Advance Payment </li>
                    <li>Payment Mode: RTGS / NEFT / IMPS</li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <h5 className="mt-3">Why Choose Self-Drilling Screw Set?</h5>
        <p className="text-justify">
          <ul>
            <li>
              <span className="fw-bold">No Pre-Drilling Required:</span> The
              self-drilling tip simplifies installation, saving time and effort.
            </li>
            <li>
              <span className="fw-bold">Waterproof Seal:</span>Each screw comes
              with an WPDM washer, providing a watertight seal and preventing
              moisture infiltration.
            </li>
            <li>
              <span className="fw-bold">Strong Grip:</span>Designed to securely
              fasten materials like polyfilm, shade nets and plastic covers to
              greenhouse structures.
            </li>
            <li>
              <span className="fw-bold">Corrosion Resistance:</span>Made from
              stainless steel, these screws are resistant to rust, ensuring
              long-lasting durability.
            </li>
            <li>
              <span className="fw-bold">Efficient:</span>Reduces installation
              time and labour, making it a cost-effective solution for
              agricultural setups.
            </li>
          </ul>
        </p>

        <h5 className="mt-3">Key Features:</h5>
        <p className="text-justify">
          <ul>
            <li>
              <span>No Pre-Drilling Needed:</span> Self-drilling tip allows for
              easy, fast installation.{" "}
            </li>
            <li>
              <span>EPDM Washers:</span> Provides a waterproof seal, preventing
              water leakage and protecting materials from moisture damage.{" "}
            </li>
            <li>
              <span>Corrosion Resistant:</span> Made from stainless steel,
              offering high resistance to rust and wear over time.{" "}
            </li>
            <li>
              <span>Quick Installation:</span> Saves time and labor by reducing
              the need for additional tools.
            </li>
          </ul>
        </p>
        <h5 className="mt-3">Applications:</h5>

        <p className="text-justify">
          <ul>
            <li>
              Greenhouses & Polyhouses: Attaches plastic coverings and films to
              metal or plastic frames securely.
            </li>
            <li>
              Insect-Proof Net Houses: Ensures that nets are tightly and
              securely fastened to structures.
            </li>
            <li>
              Agricultural Structures: Suitable for fastening materials in
              various agricultural setups.
            </li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Self_Drilling_Screw_Set_with_EPDM_Washers;
