import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory16 from "../../Assets/accessory16.jpeg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const T_Joint_Pipe_Connector = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 62,
      name: "T-Joint Pipe Connector",
      price: 250,
      image: accessory16,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory16}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">T-Joint Pipe Connector</h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The T-Joint Pipe Connector is designed for connecting multiple
                  pipes in greenhouse frameworks. Made from galvanized iron,
                  this connector provides a sturdy and reliable joint, essential
                  for building robust greenhouse structures.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span> Galvanized Iron
                      (GI)
                    </li>
                    <li>
                      <span className="fw-bold">Type:</span> T-Joint
                    </li>
                    <li>
                      <span className="fw-bold">Size:</span> 1.5 inches (custom
                      sizes available)
                    </li>
                    <li>
                      <span className="fw-bold">Length:</span> Available in
                      custom lengths
                    </li>
                    <li>
                      <span className="fw-bold">Weight:</span> 300 grams
                    </li>
                    <li>
                      <span className="fw-bold">Usage/Application:</span> For
                      creating various structural designs within the greenhouse.
                    </li>
                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 2-3 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>Eco-friendly: Made from sustainable materials.</li>
                  <li>
                    Durable Construction: Ensures long-lasting connections under
                    various weather conditions.
                  </li>
                  <li>
                    Strong Load-Bearing Capacity: Capable of supporting heavy
                    loads without failure.
                  </li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Large
                      Bags
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <h5 className="mt-3">Importance of T-Joint Pipe Connector:</h5>
        <p className="text-justify">
          T-Joint connectors are vital for maintaining the structural integrity
          of greenhouses, allowing for flexible design while ensuring secure
          connections.
        </p>
        <h5 className="mt-3">Key Benefits of T-Joint Pipe Connector:</h5>

        <p className="text-justify">
          <ul>
            <li>Facilitates quick assembly of greenhouse frames.</li>
            <li>Allows flexible design and layout options.</li>
            <li>
              Ensures that connections remain secure under pressure from snow or
              wind.
            </li>
          </ul>
        </p>
        <h5 className="mt-3">Applications of T-Joint Pipe Connector:</h5>
        <p className="text-justify">
          <ul>
            <li>
              Used in greenhouse construction to connect vertical and horizontal
              pipes.
            </li>
            <li>
              Ideal for creating various structural designs within the
              greenhouse.
            </li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default T_Joint_Pipe_Connector;
