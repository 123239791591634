import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";

import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/gallery/product1.jpg";
import CardImg2 from "../../Assets/gallery/product2.jpg";
import CardImg3 from "../../Assets/gallery/product4.jpg";
import CardImg4 from "../../Assets/gallery/product3.jpg";
import CardImg5 from "../../Assets/gallery/product5.jpg";
import RomaineLettuce from "../../Assets/romaine.png";
import ButterheadLettuce from "../../Assets/Butterhead.png";
import LolloRossaLettuce from "../../Assets/lollorossa.png";
import RedCabbage from "../../Assets/red-cabbage.png";
function Lettuces() {
  return (
    <Container fluid>
      <Row className="headingimg">
        <Col className="m-5">
          <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
            {" "}
            Products
          </h1>
          <div className="text-white m-4 fw-bold text-center">
            <a href="#" className=" text-white m-3 text-decoration-none">
              Home
            </a>
            <i className="fa-solid fa-circle-arrow-right fa-beat"></i>
            <a className="text-white text-decoration-none m-3">Products</a>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="mt-4 pt-4 ">
          <h2 className="text-green m-3 text-center fw-bold">
            Our Fresh Produce
          </h2>
          <h6 className="text-center m-4 fw-bold">
            All our produce is fresh, nutritious and hand-picked. <br /> We are
            dedicated to deliver to you the top-shelf produce.
          </h6>
        </Col>
      </Row>
      
      <Row className="p-2">
        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg1} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Vine Crops
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg2} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Lettuces
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg3} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Leafy Greens
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

       
      </Row>

      <Row className="row justify-content-center">
      <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg4} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Fresh Herbs
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg5} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Floriculture
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="mt-4 pt-4 ">
          <h1 className="text-green m-3 text-center fw-bold">Lettuces</h1>
          
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Romaine Lettuce
              </h2>
              <p className="text-center ">
                Romaine is one of the most versatile types of lettuce.
                <br />
                Flavour: It has a sweet, gentle flavour and a crisp bite that
                stands up well to punchy flavours.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Good source of beta carotene</li>
                    <li>High amount of Vitamin A, B6, K and C</li>
                    <li>
                      High in minerals like calcium, phosphorus, magnesium and
                      potassium
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Improves muscle performance</li>
                    <li>Helps prevent eye conditions like cataracts</li>
                    <li>Maintains cardiovascular health</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img src={RomaineLettuce} alt="lettuce hydroponic farming" className="img-fluid p-3" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <img
                src={ButterheadLettuce}
                alt="greenhouse farming"
                className="img-fluid p-3"
              />
            </Col>
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Butterhead Lettuce
              </h2>
              <p className="text-center ">
                Butterhead is known for its soft, buttery leaves.
                <br />
                Flavour: Sweet with a mild taste.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Rich in Vitamin K</li>
                    <li>Contains carotenoid antioxidants</li>
                    <li>Contains a good amount of iron and calcium</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Protects eyes from degeneration</li>
                    <li>Promotes reproduction</li>
                    <li>Strengthens bones</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Lollo Rossa Lettuce
              </h2>
              <p className="text-center ">
                Lollo Rosso is favoured for its texture, colour, and curly
                edges.
                <br />
                Flavour: It gives a sweet, slightly bitter, and a nutty flavour.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Rich in vitamins A, C</li>
                    <li>Contains high amounts of folate</li>
                    <li>Contains more antioxidants than regular lettuce</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Improves eyesight</li>
                    <li>Helps relieve asthma and allergy symptoms</li>
                    <li>Improves and induces sleep</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img
                src={LolloRossaLettuce}
                alt="lettuce farming"
                className="img-fluid p-3"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <img src={RedCabbage} alt="cabbage polyhouse" className="img-fluid p-3" />
            </Col>
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Red Cabbage
              </h2>
              <p className="text-center ">
                Product info Lorem ipsum dolor sit amet consectetur, nihil
                deleniti? Possimus, recusandae.
                <br /> Added to salads or coleslaw
              </p>

              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Rich in vitamin C, K</li>
                    <li>High in antioxidants, especially anthocyanins</li>
                    <li>Good source of folate, vitamin B6 and magnesium</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Linked to a reduced risk of cardiovascular disease</li>
                    <li>
                      Promote the balance of gut microbes and probiotics in your
                      digestive system
                    </li>
                    <li>Helps build and maintain healthy bones</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Lettuces;
