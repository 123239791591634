import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory10 from "../../Assets/accessory10.jpeg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Wire_Tensioning_Cable_Guy_Wire = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 56,
      name: "Wire Tensioning Cable (Guy Wire)",
      price: 250,
      image: accessory10,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory10}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Wire Tensioning Cable (Guy Wire)</h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The RGCPL Wire Tensioning Cable, often referred to as a guy
                  wire, is specifically designed for greenhouse and poly house
                  structures. These cables are crucial for ensuring stability
                  and rigidity in such structures, especially under windy
                  conditions. Made from high-tensile steel and galvanized for
                  corrosion resistance, the wire can handle heavy loads and
                  provide long-lasting service even in extreme weather.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span> Galvanized
                      Steel
                    </li>
                    <li>
                      <span className="fw-bold">Color:</span> Black
                    </li>
                    <li>
                      <span className="fw-bold">Diameter:</span> 3 mm
                    </li>
                    <li>
                      <span className="fw-bold">Length:</span> 50 Meters (custom
                      sizes available)
                    </li>

                    <li>
                      <span className="fw-bold">Usage/Application:</span>{" "}
                      Greenhouse/Poly House Structural Support
                    </li>
                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 3-5 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>
                    Eco-friendly: Manufactured with sustainable practices.
                  </li>
                  <li>
                    High strength: Capable of withstanding significant tension
                    and load.
                  </li>
                  <li>
                    Corrosion-resistant: Protects against rust and environmental
                    wear.
                  </li>
                  <li>Structure: GI (Galvanized Iron)</li>
                  <li>Built Type: Corner Support</li>
                  <li>Type: Greenhouse/Poly House Structural Support</li>
                  <li>Pattern: RGCPL</li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Large
                      Bags
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <h5 className="mt-3">
          Importance of Wire Tensioning Cable (Guy Wire):
        </h5>
        <p className="text-justify">
          Wire tensioning cables are critical in securing and maintaining the
          structural integrity of greenhouses and poly houses. They offer
          support by preventing the structure from moving or collapsing under
          stress, ensuring the safety of plants and equipment.
        </p>
        <h5 className="mt-3">
          Key Benefits of Wire Tensioning Cable (Guy Wire):
        </h5>

        <p className="text-justify">
          <ul>
            <li>
              <span className="fw-bold">Corrosion-resistant:</span> Ensures
              longevity and can withstand harsh environmental conditions.
            </li>
            <li>
              <span className="fw-bold">High tensile strength:</span>Provides
              strong support, preventing structural sway and collapse.
            </li>
            <li>
              <span className="fw-bold">Durable and long-lasting:</span>{" "}
              Designed for long-term use, reducing the need for frequent
              replacements.
            </li>
          </ul>
        </p>
        <h5 className="mt-3">Applications:</h5>
        <p className="text-justify">
          <ul>
            <li>
              Used in greenhouse structures to stabilize the framework and
              protect against wind loads.
            </li>
            <li>
              Ideal for poly houses and shade houses, where maintaining tension
              and structural integrity is essential.
            </li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Wire_Tensioning_Cable_Guy_Wire;
