import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory3 from "../../Assets/accessory3.jpeg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const U_Shape_Support_Clip = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 49,
      name: "U-Shape Support Clip",
      price: 250,
      image: accessory3,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory3}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">U-Shape Support Clip</h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The U-Shape Support Clip is a highly durable clip made from
                  galvanized iron, designed for agricultural structures such as
                  greenhouses, polyhouses, and insect net houses. It offers a
                  secure fit for supporting pipes and framework, providing
                  essential structural stability.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>Material: Galvanized Iron (GI) </li>
                    <li>Color: Silver </li>
                    <li>Height: 3 Inches</li>
                    <li>Width: 2 Inches </li>
                    <li>Thickness: 3 mm </li>

                    <li>Brand: RGCPL</li>
                    <li>Durability: 5+ Years</li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>High tensile strength for securing large structures.</li>
                  <li>Easy to install with a simple clamp design.</li>
                  <li>Weather-resistant, Suitable for all climates.</li>
                  <li>Eco-friendly, made from sustainable materials.</li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>Item Code: RGCPL-0001</li>
                    <li>Delivery Time: 7-8 Days </li>
                    <li>Port of Dispatch: Maharashtra, India </li>
                    <li>Production Capacity: 2000 Pieces Per Day </li>
                    <li>Packaging Details: Large Bags </li>
                    <li>Packing Quantity: 500 Clips Per Bag </li>
                    <li>Payment Terms: Advance Payment </li>
                    <li>Payment Mode: RTGS / NEFT / IMPS</li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <h5 className="mt-3">Importance of Zigzag Spring Clips</h5>
        <p className="text-justify">
          Zigzag Spring Clips are essential for securing the plastic film or
          shade net to the GI pipes in poly houses or greenhouses. They ensure
          that the covering material remains tightly fixed to the structure,
          protecting plants from external weather conditions such as wind and
          rain. RGCPL's clips are made from high-quality GI material to ensure
          long-lasting performance, even under the most demanding environmental
          conditions.{" "}
        </p>

        <h5 className="mt-3">Key Features:</h5>
        <p className="text-justify">
          <ul>
            <li>
              Durability: The clip is made from galvanized iron, making it
              resistant to rust and corrosion.
            </li>
            <li>
              Easy to Install: Designed for simple installation without the need
              for specialized tools.
            </li>
            <li>
              Weather Resistant: Its galvanized coating protects it from
              moisture and harsh outdoor conditions.
            </li>
            <li>
              Support: Provides strong, reliable support to framework and
              netting in greenhouses or insect-proof net houses.
            </li>
          </ul>
        </p>
        <h5 className="mt-3">Applications:</h5>

        <p className="text-justify">
          <ul>
            <li>Greenhouses: Provides stability to greenhouse frameworks.</li>
            <li>
              Polyhouses: Reinforces the structure of polyhouses to withstand
              weather elements.
            </li>
            <li>
              Insect Net Houses: Secures netting for effective pest control.
            </li>
          </ul>
          <h5 className="mt-3">Why Choose the U-Shape Support Clip?</h5>
          <ul className="text-justify">
            <li>
              Ideal for those seeking a reliable and long-lasting structural
              solution.
            </li>
            <li>
              Rust and corrosion resistance due to the GI coating, ideal for use
              in wet or humid environments.
            </li>
            <li>
              Provides high tensile strength to support large agricultural
              structures without failure.{" "}
            </li>
            For inquiries and orders, contact{" "}
            <span className="fw-bold">RGCPL</span> today and ensure your poly
            house structure is equipped with the best gutter system for
            long-term success!
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default U_Shape_Support_Clip;
