import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import climateImg from "../../Assets/gallery/climatePondicherry.png";
import "animate.css";

function ClimateOverview_Blog() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid className="p-3">
      <div className="d-flex justify-content-center mb-4">
        <img
          src={climateImg}
          className={`card-img-top img-fluid rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ maxWidth: "100%", height: "auto" }}
          alt="climate overview for hydroponic farming"
        />
      </div>

      <Row>
        <Col className="p-2 text-justify" xs={12} md={9} lg={8} id="content">
          <div className="border p-3">
            <h1 className="text-center mb-3">
              Climate Overview for Hydroponic Farming in Pondicherry, India
            </h1>
            <h5>Introduction</h5>
            <p>
              Welcome to the Climate Overview for Hydroponic Farming in
              Pondicherry, India.
            </p>
            <h5>Location and Climate</h5>
            <p>Pondicherry, India</p>
            <p>Tropical wet and dry climate</p>

            <h5>Temperature</h5>
            <p>Average: 72°F to 97°F</p>
            <p>Optimal for hydroponic crops: 65°F to 80°F</p>

            <h5>Rainfall</h5>
            <p>Wet season: July to December</p>
            <p>Dry season: December to June</p>

            <h5>Cloud Cover</h5>
            <p>Clearest skies: November to April</p>

            <h5>Humidity</h5>
            <p>High humidity throughout the year</p>

            <h5>Wind</h5>
            <p>Moderate wind, peaks in June</p>

            <h5>Growing Season</h5>
            <p>Year-round growing season</p>

            <h5>Solar Energy</h5>
            <p>Abundant solar energy</p>

            <h5>Best Time for Farming</h5>
            <p>Late December to mid-March (outdoor)</p>
            <p>Wet season (indoor)</p>

            <h5>Conclusion</h5>
            <p>
              Pondicherry offers favorable conditions for hydroponic farming.
            </p>
            <p>Adapt and optimize for best results.</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ClimateOverview_Blog;
