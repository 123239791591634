import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Enquiry from "../../Components/Enquiry";
import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/step1.png";
import CardImg2 from "../../Assets/step2.png";
import CardImg3 from "../../Assets/step3.png";
import CardImg4 from "../../Assets/step4.png";
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import PolyCarbonate from "../../Assets/polycarb.jpg";
import "../../App.css";
function Polycarbonet() {
  return (
    <>
      <Container fluid>
        <Row className="headingimgnew1">
          <Col className="m-5">
            <h1 className="text-dark m-4 mx-4 px-2 fw-bold text-center">
              Polycarbonet
            </h1>
            <div className="text-dark m-4 fw-bold text-center">
              <a href="/" className=" text-dark m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-dark text-decoration-none m-3">
                Polycarbonet
              </a>
            </div>
          </Col>
        </Row>
        <Row className="m-5 ps-5">
          <h1>DESIGN</h1>
          <p className="display-6 fst-italic">Future Of Polyhouse Design</p>
          <p className="fw-bold display-1 text-warning">
            The Designs made for India
          </p>
       
        </Row>

        
        <Row className="justify-content-center">
          <Col lg={8} md={10} sm={12} className="p-1 ">
            <img src={PolyCarbonate} alt="polycarbonet" className="img-fluid rounded" style={{ width: '100%', height: 'auto' }} />
          </Col>
          <Row className="justify-content-center p-1">
            <Col
              lg={4}
              md={6}
              sm={12}
              className="p-2  d-flex align-items-center justify-content-center"
              
            >
              <img
                src={PolyCarbonate}
                alt="polycarbonet image"
                className="img-fluid rounded w-100 h-100 object-cover"

              />
            </Col>
            <Col
              lg={4}
              md={6}
              sm={12}
              className="p-2  d-flex align-items-center justify-content-center"
            >
              <img
                src={PolyCarbonate}
                alt="polyhouse"
                className="img-fluid rounded w-100 h-100 object-cover"
                
              />
            </Col>
          </Row>
        </Row>


        <Row>
          <Col className="m-5 mb-2">
            <h6 className="text-green m-2">Polycarbonet</h6>
            <h2 className="m-2">Polycarbonet</h2>

            <Row>
              <Col lg={6}>
                <p className="text-muted m-2">
                  The best greenhouse material is polycarbonate. It provides up
                  to 60% better thermal insulation than greenhouse glass and is
                  nearly impossible to break. Its flexibility is
                  builder-friendly, and it costs less than its competition.
                  Polycarbonate is 200 times stronger than regular glass and
                  virtually shatter-resistant.
                </p>
                <p className="text-muted m-2">
                  Polycarbonate panels can diffuse light from the sun, spreading
                  it evenly across your entire greenhouse. Unlike glass
                  structures, polycarbonate can help provide light and warmth to
                  every corner at once, whenever there’s sunshine. Not to
                  mention, it also doubles as a natural UV filter, keeping
                  harmful rays away from your delicate plants.
                </p>
                <p className="text-muted m-2">
                  The value of a polycarbonate greenhouse dramatically outweighs
                  the actual cost. Once an expensive material, polycarbonate is
                  now streamlined and improved to make these quality products
                  much more accessible.Polycarbonate doesn’t require regular
                  cleaning. The low-maintenance material requires less upkeep
                  than glass and should only need cleaning once or twice a year.
                </p>
              </Col>
              <Col className="p-3" lg={6}>
                <img
                  src={PolyCarbonate}
                  alt="polyhouse farming"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className=" p-5">
            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-seedling text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow More Food In Less Space
                    </Card.Title>
                    <Card.Text>
                      With protected cultivation and A frame system now we can
                      grow 70 percent more food in the same area.{" "}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-wheat-awn text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow anywhere you want
                    </Card.Title>
                    <Card.Text>
                      We can now grow our food anywhere with help of just water
                      and protected cultivation.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-hand-holding-droplet text-green text-center fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title className="text-center">Less water</Card.Title>
                    <Card.Text>
                      Now grow crops on 90% less water compared to field crops.
                      This farming is possible at any location even in water
                      scared zones.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-bolt text-green text-center fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      High value cash crops
                    </Card.Title>
                    <Card.Text>
                      Not only our daily crops but we can grow super-crops or
                      very high value cash crops with controlled environment.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <h6 className="text-green m-2 text-center">
              Our Process In Some Steps
            </h6>
            <h4 className="m-2 text-center fw-bold">
              Building Commercially Successful Hydroponic Farm
            </h4>
          </Col>
        </Row>
        <Row className="p-2 m-3">
          {/* second cards */}
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg1}  alt="Step One"/>
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Site Feasibility
                </Card.Title>
                <Card.Text className=" text-white p-1">
                  We help you identify a site location to set up our hydroponic
                  project. We consider locality, levelling of land, examine
                  electricity and water also gather basic information of
                  surrounding that can help us test feasibility.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg2}  alt="Step Two"/>
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Ideal Farm Construction
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once financial feasibility is analysed we start working on the
                  Construction of the Project. Based on site feasibility and
                  market research we choose between NVPH or Fan-Pad system and
                  also hydroponic system between NFT or Dutch Bucket.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg3}  alt="Step Three"/>
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Farm Management{" "}
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once construction is completed, we work on automating the farm
                  using IoT devices as well as integrating with farm management.
                  At this stage, we also start training your field team to make
                  sure we continuously grow high quality produce.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg4}  alt="Step Four"/>
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Contract & Markerting Your Product
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once our field team is ready and the complete farm is
                  automated, Our team of agronomists, sales and planning starts
                  working on the contract and set a selling mechanism for your
                  farm, with a schedule of crops.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        
        
        <Row>
          <Row className="p-5">
            <h2 className="fw-bold"><kbd>Physical Specs</kbd></h2>
            <div className="row">
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Gutter height</h5>
                <p>4m - 4.5m</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Ridge Height</h5>
                <p>7m - 8m</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Top Vent</h5>
                <p>1.2m - 1.4m</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Grid Size</h5>
                <p>8m X 4m</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Gutter Slope</h5>
                <p>Upto 2%</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Gutter Thickness</h5>
                <p>1.6mm with Trapezoidal Shape</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Apron Height</h5>
                <p>0.5m</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Side Ventilation</h5>
                <p>4m</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">GI Pipe</h5>
                <p>300GSM - 350GSM</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Nuts, Bolts and Connectors</h5>
                <p>22-Micron Zinc Ferrous Coated</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Crimped Foundation</h5>
                <p>60mm - 76mm Diameter with 2mm Thickness</p>
              </div>
            </div>
          </Row>
          <Row className="p-5">
            <h2 className=" fw-bold"><kbd>Structural Specs</kbd></h2>
            <div className="row">
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Cooling System</h5>
                <p>4m - 4.5m</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Covering Material</h5>
                <p>4m - 4.5m</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Secondary Layer</h5>
                <p>4m - 4.5m</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Insect Net</h5>
                <p>4m - 4.5m</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Top Vent</h5>
                <p>Fixed/Automatic</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Side Curtain</h5>
                <p>Manual/Automatic</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Secondary Layer</h5>
                <p>Manual/Automatic</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Trelising</h5>
                <p>4m - 4.5m</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Construction</h5>
                <p>GI Pipe Frame</p>
              </div>
              <div className="col-sm-12 col-md-3 col-xl-3 col-12 mt-5">
                <h5 className="fw-bold">Door</h5>
                <p>4m - 4.5m</p>
              </div>
            </div>
          </Row>
          </Row>
      </Container>
      <TestimonialsSlider />
      <Enquiry />
    </>
  );
}

export default Polycarbonet;
