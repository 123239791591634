import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/step1.png";
import CardImg2 from "../../Assets/step2.png";
import CardImg3 from "../../Assets/step3.png";
import CardImg4 from "../../Assets/step4.png";
import Enquiry from "../../Components/Enquiry";
import Accordion from "react-bootstrap/Accordion";
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import NvphFlatbed from "../../Assets/gallery/29.png";
import '../../App.css';
import { Link } from 'react-router-dom';


import NftFlat1 from "../../Assets/NftFlat1.png";

function NftFlatbed() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              NFT Flatbed
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">NFT Flatbed</a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="m-3 mb-2">
            <h6 className="text-green m-2">NFT Flatbed</h6>
            <h2 className="m-2">NFT Flatbed System</h2>
            <Row>
              <Col lg={6}>
                <p className="text-muted m-2 text-justify">
                  {" "}
                  The NFT flatbed hydroponic system is designed to optimize
                  space while ensuring efficient crop production. Using the
                  Nutrient Film Technique (NFT), this system delivers a
                  continuous, thin film of nutrients directly to plant roots,
                  promoting healthy growth and maximizing yields. Constructed
                  with 100% lead-free, food-grade materials, it prioritizes
                  safety and sustainability in food production. The system
                  features serration for proper nutrient flow, which is crucial
                  for maintaining plant health. The ideal size for the flatbed
                  and NFT setup is 3.5 meters, providing ample space for
                  effective crop growth.
                </p>
                <p className="text-muted m-2 text-justify">
                  This NFT flatbed system is ideal for those looking to maintain
                  consistent crop production throughout the year, regardless of
                  external weather conditions. The flatbed design allows for
                  efficient use of space, making it suitable for various growing
                  environments, whether commercial or personal. Additionally,
                  the system is built with durability in mind, featuring a
                  sturdy stand with a 2mm thick powder coating that ensures
                  long-lasting performance. This setup is perfect for growers
                  seeking to optimize their cultivation space while ensuring the
                  highest standards of food safety and quality.
                </p>
              </Col>
              <Col className="p-3" lg={6}>
                <img
                  src={NftFlat1}
                  alt="nft flatbed"
                  className="img-fluid rounded"
                  
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center">
  <Col className="p-5" lg={6}>
    <img 
      src={NvphFlatbed} 
      alt="nutrient film technique" 
      className="img-fluid rounded" 
    />
  </Col>
  <Col className="p-5 d-flex align-items-center" lg={6}>
    <div className="container d-flex justify-content-center">
      <div className="table-responsive" style={{ maxWidth: '800px' }}>
        <h2 className="text-center mb-4">NFT Channel Specifications</h2>
        <table className="table table-bordered table-striped" style={{ border: '2px solid #000' }}>
          <thead className="thead-dark">
            <tr>
              <th>Specification</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Channel Material</td>
              <td>UPVC (100% lead-free), Food-grade quality</td>
            </tr>
            <tr>
              <td>Channel Size</td>
              <td>100/50 (100 mm wide x 50 mm deep) or 100/80 (100 mm wide x 80 mm deep)</td>
            </tr>
            <tr>
              <td>Serration</td>
              <td>Serrated channels for proper nutrient flow</td>
            </tr>
            <tr>
              <td>Channel Length</td>
              <td>Standard length of 3.5 meters</td>
            </tr>
            <tr>
              <td>Channel Slope</td>
              <td>1-3% gradient for efficient nutrient flow</td>
            </tr>
            <tr>
              <td>Channel Spacing</td>
              <td>25-30 cm between channels for adequate air circulation and light penetration</td>
            </tr>
            <tr>
              <td>Support Structure</td>
              <td>Stands are powder-coated with two layers of Ivory texture to prevent rusting and to reflect sunlight.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Col>
</Row>


<Row>
          <h3 className="p-5 text-center bgyellow">
            <Link
              to="https://wa.me/+919960991166"
              className="text-warning"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us for a detailed quotation.
              <sub className="text-white">Click here</sub>
            </Link>
          </h3>
        </Row>



        <Row>
          <Col lg={12} className=" p-5">
            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-seedling text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow More Food In Less Space
                    </Card.Title>
                    <Card.Text>
                      With protected cultivation and A frame system now we can
                      grow 70 percent more food in the same area.{" "}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-wheat-awn text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow anywhere you want
                    </Card.Title>
                    <Card.Text>
                      We can now grow our food anywhere with help of just water
                      and protected cultivation.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-hand-holding-droplet text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">Less water</Card.Title>
                    <Card.Text>
                      Now grow crops on 90% less water compared to field crops.
                      This farming is possible at any location even in water
                      scared zones.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-bolt text-green  fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      High value cash crops
                    </Card.Title>
                    <Card.Text>
                      Not only our daily crops but we can grow super-crops or
                      very high value cash crops with controlled environment.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <h6 className="text-green m-2 text-center">
              Our Process In Some Steps
            </h6>
            <h4 className="m-2 text-center fw-bold">
              Building Commercially Successful Hydroponic Farm
            </h4>
          </Col>
        </Row>
        <Row className="p-2 m-3">
          {/* second cards */}
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg1}  alt="Step One" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Site Feasibility
                </Card.Title>
                <Card.Text className=" text-white p-1">
                  We help you identify a site location to set up our hydroponic
                  project. We consider locality, levelling of land, examine
                  electricity and water also gather basic information of
                  surrounding that can help us test feasibility.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg2}  alt="Step Two" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Ideal Farm Construction
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once financial feasibility is analysed we start working on the
                  Construction of the Project. Based on site feasibility and
                  market research we choose between NVPH or Fan-Pad system and
                  also hydroponic system between NFT or Dutch Bucket.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg3}  alt="Step Three" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Farm Management{" "}
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once construction is completed, we work on automating the farm
                  using IoT devices as well as integrating with farm management.
                  At this stage, we also start training your field team to make
                  sure we continuously grow high quality produce.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg4}  alt="Step Four" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Contract & Markerting Your Product
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once our field team is ready and the complete farm is
                  automated, Our team of agronomists, sales and planning starts
                  working on the contract and set a selling mechanism for your
                  farm, with a schedule of crops.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

       
      </Container>
      <TestimonialsSlider />
      <Enquiry />
    </>
  );
}

export default NftFlatbed;
