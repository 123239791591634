import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "animate.css";
import subsidy_img from "../../Assets/subsidy_img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

function Guide_to_Poly_House_Subsidy_in_India_by_Royal_Green_House() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={subsidy_img}
          className={`card-img-top img-fluid rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{
            maxWidth: "90%",
            width: "100%",
            height: "auto",
            marginTop: "20px",
          }}
          alt="commercial hydroponic farm"
        />
      </div>

      <Row className="mx-2 mx-md-5 my-4">
        <Col
          className="p-3 text-justify"
          sm={12}
          md={9}
          style={{
            fontSize: "1rem",
            lineHeight: "1.6",
            color: "#333",
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
          }}
        >
          <div style={{ padding: "20px" }}>
            <h1 className="text-center pb-3">
              Guide to Poly House Subsidy in India by Royal Green House
            </h1>

            <p>
              Polyhouse farming has emerged as a promising method for year-round
              agriculture in India, where controlling environmental factors such
              as temperature, humidity, and light can significantly enhance crop
              yield and quality. However, the cost of setting up a polyhouse can
              be prohibitive for many farmers. This is where government
              subsidies play a crucial role, making polyhouse farming more
              accessible and economically viable. At Royal Green House (RGCPL),
              we believe in empowering farmers by providing high-quality
              polyhouse solutions and guiding them through the subsidy process.
              Here’s an overview of polyhouse subsidy schemes available in India
              and how to apply for them.
            </p>

            <h4>Types of Subsidies Available for Polyhouse Farming:</h4>
            <p>
              <ol>
                <li>
                <span className="fw-bold">National Horticulture Board (NHB) Subsidy</span>
                  <ul>
                    <li><span className="fw-bold">Subsidy Percentage:</span> 50% of the total project cost.</li>
                    <li>
                    <span className="fw-bold">Coverage:</span> This subsidy is not limited to just polyhouse
                      construction. It also covers other essential aspects of
                      setting up a polyhouse, including:
                      <ul>
                        <li>Drip and fogging systems</li>
                        <li>Bed preparation materials and labor</li>
                        <li>Planting material and initial setup costs</li>
                        <li>
                          Basic infrastructure such as packing facilities, labor
                          quarters, and irrigation equipment
                        </li>
                      </ul>
                    </li>
                    <li>
                    <span className="fw-bold">Eligibility:</span> Farmers and agricultural enterprises looking
                      to build polyhouses for commercial cultivation can apply.
                    </li>
                  </ul>
                </li>
                <li><span className="fw-bold">National Horticulture Mission (NHM) Subsidy</span></li>
                <ul>
                  <li>
                  <span className="fw-bold">Subsidy Percentage:</span> 50% for polyhouse construction and
                    drip/fogging systems, subject to rates set by the state
                    horticulture department.
                  </li>
                  <li>
                  <span className="fw-bold">Coverage:</span> While this subsidy focuses on polyhouse
                    construction, it also includes the installation of drip and
                    fogging systems. However, it may not cover ancillary costs
                    such as labor quarters or packing halls.
                  </li>
                  <li>
                  <span className="fw-bold">Eligibility:</span> Farmers are advised to check with their
                    respective state horticulture departments, as subsidy rates
                    and eligibility may vary by state.
                  </li>
                </ul>
              </ol>
            </p>
            <h4>Benefits of Polyhouse Farming:</h4>
            <p>
              Polyhouse farming offers multiple advantages for both small-scale
              and commercial farmers, including:
              <ul>
                <li>
                <span className="fw-bold">Controlled Environment:</span> By regulating temperature, humidity,
                  and light levels, polyhouses can protect crops from adverse
                  weather conditions, resulting in higher yields.
                </li>
                <li>
                <span className="fw-bold">Improved Crop Quality:</span> With controlled conditions, crops are
                  healthier and free from environmental stress, making them more
                  resilient and marketable.
                </li>
              </ul>
            </p>

            <h4>Popular Crops for Polyhouse Cultivation:</h4>
            <p>
              Polyhouses are versatile and suitable for a wide variety of crops,
              including:
              <ul>
                <li><span className="fw-bold">Fruits:</span> Papaya, strawberry, and others.</li>
                <li>
                <span className="fw-bold">Vegetables:</span> Capsicum, cabbage, cauliflower, radish, spinach,
                  tomato, and more.
                </li>
                <li>
                <span className="fw-bold">Flowers:</span> Carnation, gerbera, marigold, orchid, and rose.
                </li>
              </ul>
            </p>

            <h4>Steps to Apply for a Polyhouse Subsidy:</h4>
            <p>
              <ol>
                <li>
                <span className="fw-bold">Contact the Local Horticulture Department:</span> Farmers should
                  first approach their state or district horticulture office to
                  inquire about specific subsidy schemes available in their
                  region.
                </li>
                <li>
                <span className="fw-bold">Prepare a Detailed Project Report:</span> This report should include
                  the design, cost estimate, and expected outcomes for the
                  polyhouse project. RGCPL can assist in preparing this document
                  for our clients.
                </li>
                <li>
                <span className="fw-bold">Submit Required Documents:</span> Along with the project report,
                  farmers will need to submit identification documents, land
                  ownership or lease agreements, and bank details.
                </li>
                <li>
                <span className="fw-bold">Inspection and Approval:</span> After submission, the horticulture
                  department will inspect the proposed site and verify details
                  before approving the subsidy.
                </li>
                <li>
                <span className="fw-bold">Receive Subsidy:</span> Once approved, the subsidy is disbursed,
                  either directly to the farmer’s account or through
                  reimbursement post-construction.
                </li>
              </ol>
            </p>

            <h4>How Royal Green House (RGCPL) Can Help:</h4>
            <p>
              At RGCPL, we offer end-to-end solutions for polyhouse farming,
              from designing and constructing polyhouses to providing drip and
              fogging systems, grow bags, and expert consultation. We also guide
              farmers through the subsidy application process to ensure they
              make the most of the financial support available to them.
            </p>
            <p>
              Whether you're an individual farmer or a large-scale agricultural
              business, RGCPL is committed to helping you succeed with polyhouse
              farming. For more information on polyhouse subsidies and to
              discuss your project, feel free to contact us.
            </p>
            <p>
              <h5>Contact Us:</h5>
              <div className="d-flex align-items-center mb-3">
                <FontAwesomeIcon icon={faPhone} className="me-2 text-success" />
                <span>
                  Phone:{" "}
                  <a
                    href="tel:+919960991166"
                    className="text-primary text-decoration-none"
                  >
                    +91-9960-991-166
                  </a>
                </span>
              </div>
              <div className="d-flex align-items-center mb-3">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="me-2 text-success"
                />
                <span>
                  Email:{" "}
                  <a
                    href="mailto:info@royalgreenhouse.co.in"
                    target="_blank"
                    className="text-primary text-decoration-none"
                  >
                    info@royalgreenhouse.co.in
                  </a>
                </span>
              </div>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faGlobe} className="me-2 text-success" />
                <span>
                  Website:{" "}
                  <a
                    href="http://www.royalgreenhouse.co.in/"
                    className="text-primary text-decoration-none"
                  >
                    www.royalgreenhouse.co.in
                  </a>
                </span>
              </div>
            </p>
            <p>
              By leveraging subsidies and working with a trusted partner like
              Royal Green House, farmers can embark on a profitable and
              sustainable journey with polyhouse farming.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Guide_to_Poly_House_Subsidy_in_India_by_Royal_Green_House;
