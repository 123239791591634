import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/step1.png";
import CardImg2 from "../../Assets/step2.png";
import CardImg3 from "../../Assets/step3.png";
import CardImg4 from "../../Assets/step4.png";
import Enquiry from "../../Components/Enquiry";
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import DutchBucket1 from "../../Assets/DutchBucket1.png";
import "../../App.css";
import { Link } from "react-router-dom";

function DutchBucket() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              Dutch Bucket / Bato Bucket System
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className="text-white m-3 text-decoration-none">
                Home
              </a>
              <i className="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">
                Dutch Bucket / Bato Bucket System
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="m-3 mb-2">
            <h6 className="text-green m-2">Dutch Bucket / Bato Bucket System</h6>
            <h2 className="m-2">Understanding the Dutch Bucket / Bato Bucket System</h2>

            <Row>
              <Col lg={6}>
                <p className="text-muted m-2 text-justify">
                  <h2>
                    What is the Dutch Bucket (Bato Bucket) Hydroponics System?
                  </h2>{" "}
                  The <span className="fw-bold">Dutch Bucket Hydroponics System</span>, also known as the <span className="fw-bold">Bato Bucket System</span>, is a popular choice for <span className="fw-bold">growing large plants</span> in both home and commercial gardens. Renowned for its <span className="fw-bold">versatility, affordability, and efficiency</span>, this system excels in maximizing space and water usage while ensuring <span className="fw-bold">optimal aeration</span> to the plants. It is particularly effective for managing <span className="fw-bold">large vining plants</span> such as <span className="fw-bold">cucumbers, squash, peppers, beans</span>, and <span className="fw-bold">tomatoes</span>.
                </p>
                <p className="text-muted m-2 text-justify">
                  <h2>How the Dutch Bucket System Works</h2>
                  Operating on the principle of the <span className="fw-bold">ebb and flow method</span>, the <span className="fw-bold">Dutch Bucket System</span> works by feeding nutrient solution into each bucket and then draining it back to a central reservoir. This <span className="fw-bold">recirculating system</span> simplifies hydroponic gardening, whether you're growing <span className="fw-bold">indoors or outdoors</span>. Additionally, it can be adapted for <span className="fw-bold">aquaponics</span>, though this version requires a more complex setup.
                </p>
                <p className="text-muted m-2 text-justify">
                  <h2>Components Needed for the Dutch Bucket System</h2>
                  To set up a Dutch Bucket (Bato Bucket) System, you will need the following components:
                  <ul>
                    <li> Dutch (Bato) Buckets</li>
                    <li> Bench or floor space</li>
                    <li> Growing media (such as vermiculite, hydroton, or perlite)</li>
                    <li> Lids for covering the buckets</li>
                    <li> A return line and siphon elbows for each bucket</li>
                    <li> PVC pipes, irrigation line, and connections</li>
                    <li> Drip emitters (one per bucket, or more depending on the plant's needs)</li>
                    <li> A submersible pump and a tank or reservoir</li>
                  </ul>
                  Each component works together to ensure efficient nutrient delivery and drainage.
                </p>
                <p className="text-muted m-2 text-justify">
                  <h2>Best Practices for the Dutch Bucket System</h2>
                  The Dutch Bucket System is ideal for a variety of plants, including those requiring <span className="fw-bold">high nutrient levels</span>. For larger plants or those with greater nutrient needs, it’s advisable to use additional drip emitters to ensure proper nutrient delivery. Adequate support for <span className="fw-bold">vining plants</span> is also essential for healthy growth and positioning.
                </p>
                <p className="text-muted m-2 text-justify">
                  The growing media in a <span className="fw-bold">Dutch Bucket System</span> should retain water effectively without holding excess moisture. Ideal materials include:
                  <ul>
                    <li>Coconut coir</li>
                    <li>Clay balls</li>
                    <li>Vermiculite</li>
                    <li>Perlite</li>
                  </ul>
                  These materials can be used alone or in combination for optimal plant growth.
                </p>
                <p className="text-muted m-2 text-justify">
                  <h2>Why Opt for the Dutch Bucket System?</h2>
                  The <span className="fw-bold">Dutch Bucket System</span> offers an <span className="fw-bold">easy setup</span> and efficient <span className="fw-bold">recirculation of nutrients</span>. It’s perfect for both beginners and experienced hydroponic growers looking to cultivate large, nutrient-demanding plants like <span className="fw-bold">tomatoes, cucumbers</span>, and <span className="fw-bold">peppers</span>. This system not only conserves water but also maximizes plant growth, making it an ideal choice for enhancing yields with minimal effort.
                </p>
                <p className="text-muted m-2 text-justify">
                  <h3 className="text-muted m-2">Conclusion</h3>
                  Whether you're a home gardener or a commercial grower, the <span className="fw-bold">Dutch Bucket (Bato Bucket) System</span> offers a scalable, efficient solution for cultivating large plants in a hydroponic setup. Its simple design and reliable performance make it an excellent choice for anyone looking to start with <span className="fw-bold">hydroponic gardening</span> or elevate their growing operation.
                </p>
              </Col>
              <Col className="p-3" lg={6}>
                <img
                  src={DutchBucket1}
                  alt="Dutch Bucket System"
                  className="img-fluid rounded"
                  style={{ width: "100%", height: "auto" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <h3 className="p-5 text-center bgyellow">
            <Link
              to="https://wa.me/+919960991166"
              className="text-warning"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us for a detailed quotation.
              <sub className="text-white">Click here</sub>
            </Link>
          </h3>
        </Row>

        <Row>
          <Col lg={12} className="p-5">
            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i className="fa-solid fa-seedling text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow More Food In Less Space
                    </Card.Title>
                    <Card.Text>
                      With protected cultivation and the A-frame system, you can grow 70 percent more food in the same area.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i className="fa-solid fa-wheat-awn text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow Anywhere You Want
                    </Card.Title>
                    <Card.Text>
                      Grow your food anywhere with just water and protected cultivation.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i className="fa-solid fa-hand-holding-droplet text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">Less Water</Card.Title>
                    <Card.Text>
                      Grow crops with 90% less water compared to traditional field crops. Ideal for water-scarce zones.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i className="fa-solid fa-bolt text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      High Value Cash Crops
                    </Card.Title>
                    <Card.Text>
                      Grow high-value cash crops with a controlled environment for better profitability.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <h6 className="text-green m-2 text-center">
              Our Process In Some Steps
            </h6>
            <h4 className="m-2 text-center fw-bold">
              Building a Commercially Successful Hydroponic Farm
            </h4>
          </Col>
        </Row>
        <Row className="p-2 m-3">
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg1} alt="Step One" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Site Feasibility
                </Card.Title>
                <Card.Text className="text-white p-1">
                  We assist in identifying a suitable site location for your hydroponic project. We evaluate locality, land leveling, electricity, water availability, and other surrounding factors to assess feasibility.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg2} alt="Step Two" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Ideal Farm Construction
                </Card.Title>
                <Card.Text className="text-white p-1">
                  After analyzing financial feasibility, we start constructing the project. We decide between NVPH or Fan-Pad systems and select the hydroponic system, whether NFT or Dutch Bucket.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg3} alt="Step Three" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Farm Management
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Post-construction, we automate the farm using IoT devices and integrate it with farm management systems. We also train your field team to ensure high-quality produce.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg4} alt="Step Four" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Contract & Marketing Your Product
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once the field team is ready and the farm is automated, our agronomists and sales team work on contracts and set up a sales mechanism for your farm, including a crop schedule.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <TestimonialsSlider />
      <Enquiry />
    </>
  );
}

export default DutchBucket;
