import React from 'react';
import { useCart } from "../../Contexts/CartContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';

const CartModal = ({ onClose }) => {
  const { cartItems, updateQuantity, removeFromCart } = useCart();
  const navigate = useNavigate();
  
  // Define GST rate
  const GST_RATE = 0.18;

  // Calculate the total price and GST
  const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  const gstAmount = totalPrice * GST_RATE;
  const totalWithGST = totalPrice + gstAmount;

  return (
    <div 
      className="cart-modal" 
      style={{
        width: '400px', 
        padding: '20px', 
        position: 'fixed', 
        top: '80px', 
        right: '20px', 
        backgroundColor: '#E2DFD2', 
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
        zIndex: 1000, 
        borderRadius: '8px'
      }}
    >
      <div style={{ textAlign: 'right', marginBottom: '20px' }}>
        <FontAwesomeIcon 
          icon={faTimes} 
          onClick={onClose} 
          style={{ cursor: 'pointer', fontSize: '24px' }} 
        />
      </div>
      
      {cartItems.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <>
          {cartItems.map(item => (
            <div key={item.id} className="cart-item d-flex align-items-center mb-3">
              <img 
                src={item.image || 'path/to/default-image.png'} 
                alt={item.name} 
                className="cart-item-image" 
                style={{ width: '50px', height: '50px', marginRight: '10px', objectFit: 'cover' }} 
              />
              <div className="cart-item-details flex-grow-1">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0" style={{ fontSize: '14px' }}>{item.name}</h5>
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center border rounded" style={{ padding: '0 5px' }}>
                      <button 
                        className="btn btn-outline-secondary btn-sm" 
                        onClick={() => updateQuantity(item.id, item.quantity - 1)} 
                        disabled={item.quantity <= 1}
                      >
                        -
                      </button>
                      <span className="mx-2">{item.quantity}</span>
                      <button 
                        className="btn btn-outline-secondary btn-sm" 
                        onClick={() => updateQuantity(item.id, item.quantity + 1)}
                      >
                        +
                      </button>
                    </div>
                    <button 
                      className="btn btn-danger btn-sm ml-3" 
                      onClick={() => removeFromCart(item.id)}
                      style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
                <p className="mb-0">₹{item.price * item.quantity}</p>
              </div>
            </div>
          ))}

          {/* Display Total Price and GST */}
          <div className="total-price" style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '10px' }}>
            Subtotal: ₹{totalPrice.toFixed(2)}
          </div>
          <div className="gst-amount" style={{ fontSize: '14px', marginTop: '5px' }}>
            GST (18%): ₹{gstAmount.toFixed(2)}
          </div>
          <div className="total-with-gst" style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '10px' }}>
            Total Price (incl. GST): ₹{totalWithGST.toFixed(2)}
          </div>

          {/* Action Buttons */}
          <div className="d-flex justify-content-between mt-4">
            <button 
              onClick={() => {
                onClose();
                navigate(-1);
              }} 
              className="btn btn-secondary w-50" 
              style={{ marginRight: '10px' }}
            >
              Continue Shopping
            </button>
            <Link 
              to="/Checkout" 
              className="btn btn-primary w-50"
            >
              Checkout
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default CartModal;

