import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import IntelliDose_img from "../../Assets/IntelliDose_img.webp";
import Bluelab_IntelliDose_img from "../../Assets/Bluelab_IntelliDose_img.webp";
import blb_blog_img from "../../Assets/blb_blog_img.webp";
import "./blog.css";
import "animate.css";
import { Button } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";

function Reasons_to_invest_in_automated_dosing_systems() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <h1 className="text-center p-3">
        5 Reasons You Need to Invest in Automated Dosing Systems on Your Farm
      </h1>{" "}
      <Row className="m-5">
        <Col className="p-4 mt-5 textJustify" sm={12} md={6} lg={6}>
          <div className="d-flex justify-content-center">
            <img
              src={IntelliDose_img}
              className={` img-fluid p-3 rounded 
              }`}
              style={{ width: "100%", height: "600px" }}
              alt="Bluelab products"
            />
          </div>
        </Col>
        <Col className="p-4 textJustify" sm={12} md={6} lg={6} id="content">
          <div id="blog1" className="border p-2">
            <p className="mt-3 text-justify">
              At Royal Green House (RGCPL), we believe in harnessing the power
              of technology to boost farm efficiency and productivity. Automated
              dosing systems are a game-changer for modern agriculture,
              especially in hydroponics and other controlled environment
              farming. Here are five reasons why investing in an automated
              dosing system is essential for your farm's success:
            </p>
            <ol>
              <li>
                <span className="fw-bold">
                  Precision in Nutrient Delivery:{" "}
                </span>
                Manual nutrient mixing can be inconsistent, leading to over or
                underfeeding your plants. Automated dosing systems ensure
                precise nutrient delivery based on real-time data from your
                hydroponic system. This accuracy improves plant health and
                maximizes yields, giving you consistently better results.
              </li>
              <li>
                <span className="fw-bold">
                  Increased Efficiency and Time-Saving:{" "}
                </span>
                Automated systems take the guesswork out of nutrient management,
                allowing you to save time spent on manual adjustments. You can
                program your system to monitor pH levels, electrical
                conductivity (EC), and nutrient concentration. The system does
                the work, giving you more time to focus on other essential
                farming tasks.
              </li>
              <li>
                <span className="fw-bold">Cost-Effective Over Time: </span>
                While the initial investment in automated dosing systems may
                seem high, the long-term savings are substantial. By reducing
                nutrient waste and preventing crop loss due to human error, you
                save money on resources and improve your farm’s overall
                profitability. Precision management also lowers water and
                nutrient use, helping cut costs.
              </li>
              <li>
                <span className="fw-bold">Consistent Growth Environment: </span>
                Plants thrive in stable, well-controlled environments. Automated
                dosing systems monitor and adjust nutrient levels, pH, and water
                supply, creating a consistent growth environment for your crops.
                This uniformity leads to healthier plants and maximized yields,
                especially for large-scale or commercial operations.
              </li>
              <li>
                <span className="fw-bold">Data-Driven Decision Making: </span>
                Automated dosing systems often come equipped with smart
                technology and monitoring capabilities. These systems generate
                real-time data, allowing you to make informed decisions based on
                trends in your farm’s nutrient and water use. With this data,
                you can optimize conditions for better plant performance and
                identify potential issues early.
              </li>
            </ol>
          </div>
        </Col>
      </Row>
      <Row className="m-5">
        <Col className="p-4 mt-5 textJustify" sm={12} md={6} lg={6}>
          <h3>Shop Now with RGCPL</h3>
          <p>
            At Royal Green House (RGCPL), we provide top-tier automated dosing
            systems and other hydroponic automation tools designed to enhance
            your farming experience. Explore our products at{" "}
            <a href="https://royalgreenhouse.co.in">
              https://royalgreenhouse.co.in
            </a>{" "}
            or contact us at <a href="tel:+919960991166"> +91-9960-991-166</a>{" "}
            to learn more about how we can help you elevate your farm’s
            performance.
          </p>
          <i>
            Invest in technology today for a greener, more efficient tomorrow!
          </i>
        </Col>
        <Col className="p-4 mt-5 textJustify" sm={12} md={6} lg={6}>
          <div className="d-flex justify-content-center">
            <img
              src={Bluelab_IntelliDose_img}
              className={` img-fluid p-3 rounded 
              }`}
              style={{ width: "100%", height: "600px" }}
              alt="Greenhouse accessories"
            />
          </div>
        </Col>
      </Row>
      <Row className="m-5 justify-content-center">
        <Col className="p-4 mt-5 textJustify" sm={12} md={10} lg={8}>
          <div className="d-flex justify-content-center">
            <img
              src={blb_blog_img}
              className="img-fluid p-3 rounded"
              style={{ width: "100%", height: "600px" }}
              alt="Automated dosing system"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Reasons_to_invest_in_automated_dosing_systems;
