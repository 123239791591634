import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import GalleryofProjects from "../../Components/GalleryofProjects";

import Enquiry from "../../Components/Enquiry";
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/gallery/29.png";
import CardImg2 from "../../Assets/gallery/nflatbed.png";
import CardImg3 from "../../Assets/gallery/1.png";
import CardImg4 from "../../Assets/gallery/flatbed.png";
import CardImg5 from "../../Assets/lightbag.jpg";
import CardImg6 from "../../Assets/gallery/multispan.jpg";
import CardImg7 from "../../Assets/gallery/vine.png";
import CardImg8 from "../../Assets/gallery/old/74.png";

import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import CustomSlider from "../../Components/CustomSlider";
import "../../App.css";
function ourProjects() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              {" "}
              Our Hydroponic Projects
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="#" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i className="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a href="#" className="text-white text-decoration-none m-3">
                Our Hydroponic Projects
              </a>
            </div>
          </Col>
        </Row>
        <GalleryofProjects />
        <div className="text-center m-1">
          <Link to="/gallery" className="btn btn-success">
            <i className="fa-solid fa-circle-arrow-right p-2 fa-beat"></i>{" "}
            Checkout our gallery for more photos
          </Link>
        </div>

        <Row>
          <Col className="m-5 mb-2">
            <h6 className="text-green m-2 text-center">
              We help building businesses
            </h6>
            <h2 className="m-2 text-center fw-bold">
              Some of the brands who chose us to build their business
            </h2>
          </Col>
        </Row>
        <CustomSlider />
        <Row>
          <Col className="m-5 mb-2">
            <h6 className="text-green m-2 text-center">
              We give you the flexibility to assess the models that work best
              for you.
            </h6>
            <h2 className="m-2 text-center fw-bold">
              Our Standard Commercial Models
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Carousel className="p-3" variant="dark">
              <Carousel.Item>
                <Row className="p-3">
                  <Col lg={3} className="p-3">
                    <Card className="square boxShadow">
                      <Card.Img
                        variant="top"
                        src={CardImg8}
                        className="curveImg "
                        alt="hydroponic projects"
                      />
                      <Card.Body className="font-weight-bolder text-dark ">
                        <Card.Title className="text-center fa-beat ">
                          Naturally Ventilated Polyhouse (NVPH)
                        </Card.Title>
                        <Card.Text>
                          An NVPH (Naturally Ventilated Polyhouse) uses
                          Naturalventilation for climate control. Made of
                          galvanized iron/steel with UV-stabilized polyethylene
                          film, it features roof and side vents for
                          airflow.Cost-effective and energy-efficient, it's
                          ideal for moderate climates, enhancing crop yield
                          while promoting sustainable farming.
                        </Card.Text>
                        <div className="text-center">
                          <Link
                            to="/PolyhouseShadenet"
                            className="px-4 btn btn-dark"
                          >
                            Learn More
                          </Link>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={3} className="p-3">
                    <Card className="boxShadow square">
                      <Card.Img
                        variant="top"
                        src={CardImg5}
                        className="curveImg "
                        alt="polyhouse projects"
                      />
                      <Card.Body className="font-weight-bolder text-dark">
                        <Card.Title className="text-center fa-beat">
                          Fan & Pad Polyhouse (Climate Control)
                        </Card.Title>
                        <Card.Text>
                          A Fan Pad Polyhouse uses fans and Cooling pads for
                          climate control. Fans expel hot air, while
                          water-soaked pads cool incoming air. Made from
                          galvanized iron/steel with UV-stabilized polyethylene
                          film, it ensures precise temperature and humidity
                          management, enhancing crop yeilds and quality in
                          various climates.{" "}
                        </Card.Text>
                        <div className="text-center">
                          <Link
                            to="/IndoorVertical"
                            className="px-4 btn btn-dark"
                          >
                            Learn More
                          </Link>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col lg={3} className="p-3">
                    <Card className="square boxShadow">
                      <Card.Img
                        variant="top"
                        src={CardImg6}
                        className="curveImg "
                        alt="farming"
                      />
                      <Card.Body className="font-weight-bolder text-dark">
                        <Card.Title className="text-center fa-beat">
                          Shade Net House (Tunnel)
                        </Card.Title>
                        <Card.Text>
                          A Tunnel Net House is a protective structure with a
                          semi-circular frame covered by netting. It shields
                          crops from pests, harsh weather, and excessive
                          sunlight. Made of galvanized iron or steel, it's
                          cost-effective, easy to install, and promotes healthy
                          plant growth in diverse climates. Ideal for
                          vegetables, flowers, and delicate plants.
                        </Card.Text>
                        <div className="text-center">
                          <Link
                            to="/MultispanShadenet"
                            className="px-4 btn btn-dark"
                          >
                            Learn More
                          </Link>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col lg={3} className="p-3">
                    <Card className="square boxShadow">
                      <Card.Img
                        variant="top"
                        src={CardImg7}
                        className="curveImg "
                        alt="commercial hydroponic farming"
                      />
                      <Card.Body className="font-weight-bolder text-dark">
                        <Card.Title className="text-center fa-beat">
                          Wire Rope Net House (Rain Protected)
                        </Card.Title>
                        <Card.Text>
                          A Rain Protected Wire Rope Net House with a solarig
                          sheet on top offers enhanced protection. The solarig
                          sheet shields crops from rain and UV rays, while the
                          wire rope and net structure provide ventilation and
                          support. This setup ensures year-round crop
                          protection, maintaining healthy growth even during the
                          rainy season.
                        </Card.Text>
                        <div className="text-center">
                          <Link
                            to="/NvphDutchBucket"
                            className="px-4 btn btn-dark"
                          >
                            Learn More
                          </Link>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row className="p-3">
                  <Col lg={3} className="p-3">
                    <Card className="square boxShadow">
                      <Card.Img
                        variant="top"
                        src={CardImg1}
                        className="curveImg "
                        alt="commercial farming"
                      />
                      <Card.Body className="font-weight-bolder text-dark">
                        <Card.Title className="text-center fa-beat">
                          Flat Shade Net House
                        </Card.Title>
                        <Card.Text>
                          A Flat Shade Net House uses a flat roof design with
                          UV- stabilized netting to protect crops from excessive
                          sunlight and pests. Made of galvanized iron/steel
                          frames, it's cost-effective, easy to construct, and
                          suitable for a variety of crops. It ensures optimal
                          light diffusion, promoting healthy plant growth in
                          diverse climates.
                        </Card.Text>
                        <div className="text-center">
                          <Link to="/NvphAframe" className="px-4 btn btn-dark">
                            Learn More
                          </Link>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={3} className="p-3">
                    <Card className="square boxShadow">
                      <Card.Img
                        variant="top"
                        src={CardImg2}
                        className="curveImg "
                        alt="farmings setups"
                      />
                      <Card.Body className="font-weight-bolder text-dark">
                        <Card.Title className="text-center fa-beat">
                          NFT Flatbed
                        </Card.Title>
                        <Card.Text>
                          To setup an NFT Flatbed system with a maximum length
                          of 3.5 meters and 9 NFT channels per bed, arrange the
                          channels in parallel on the bed, ensuring each has
                          slight slope (1-3%) for nutrient flow. Position the
                          inlet and outlet for nutrient Solution at opposite
                          ends, and ensure adequate spacing for plant growth and
                          maintenance.
                        </Card.Text>
                        <div className="text-center">
                          <Link to="/NvphFlatbed" className="px-4 btn btn-dark">
                            Learn More
                          </Link>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={3} className="p-3">
                    <Card className="square boxShadow mb-5">
                      <Card.Img
                        variant="top"
                        src={CardImg3}
                        className="curveImg "
                        alt="hydroponic farm setup near me"
                      />
                      <Card.Body className="font-weight-bolder text-dark">
                        <Card.Title className="text-center fa-beat">
                          NFT A-Frame
                        </Card.Title>
                        <Card.Text>
                          For an NFT A-Frame setup within a 3.5-meter length,
                          construct a 5-layer system on a stand. Each layer
                          should be spaced evenly to allow for efficient
                          nutrient flow and plant growth. Ensure proper support
                          and alignment to maintain stability and optimize the
                          use of vertical space. Enhances growth, space
                          efficiency, and overall yield.
                        </Card.Text>
                        <div className="text-center">
                          <Link
                            to="/FanbadAframe"
                            className="px-4 btn btn-dark"
                          >
                            Learn More
                          </Link>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={3} className="p-3">
                    <Card className="square boxShadow">
                      <Card.Img
                        variant="top"
                        src={CardImg4}
                        className="curveImg "
                        alt="farming projects"
                      />
                      <Card.Body className="font-weight-bolder text-dark">
                        <Card.Title className="text-center fa-beat">
                          Vine Crops
                        </Card.Title>
                        <Card.Text>
                          For vine crops, set up double lines of grow slabs with
                          four-way aero drippers. Space the slabs appropriately
                          to ensure optimal root growth and nutrient absorption.
                          Position the aero drippers to deliver precise water
                          and nutrient distribution, maximizing plant health and
                          yield. Ensures even growth, high productivity, and
                          efficient resource use.
                        </Card.Text>
                        <div className="text-center">
                          <Link
                            to="/FanbadFlatbed"
                            className="px-4 btn btn-dark"
                          >
                            Learn More
                          </Link>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>

      <TestimonialsSlider />
      <Enquiry />
    </>
  );
}

export default ourProjects;
