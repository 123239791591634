import React, { useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../App.css";
import CartModal from "../../Components/CartModal";
import accessory1 from "../../Assets/accessory1.jpeg";
import accessory2 from "../../Assets/accessory2.jpeg";
import accessory3 from "../../Assets/accessory3.jpeg";
import accessory4 from "../../Assets/accessory4.jpeg";
import accessory5 from "../../Assets/accessory5.jpeg";
import accessory6 from "../../Assets/accessory6.jpeg";
import accessory7 from "../../Assets/accessory7.jpeg";
import accessory8 from "../../Assets/accessory8.jpeg";
import accessory9 from "../../Assets/accessory9.jpeg";
import accessory10 from "../../Assets/accessory10.jpeg";
import accessory11 from "../../Assets/accessory11.jpeg";
import accessory12 from "../../Assets/accessory12.jpeg";
import accessory13 from "../../Assets/accessory13.jpeg";
import accessory14 from "../../Assets/accessory14.jpeg";
import accessory15 from "../../Assets/accessory15.jpeg";
import accessory16 from "../../Assets/accessory16.jpeg";
import accessory17 from "../../Assets/accessory17.jpeg";
import accessory18 from "../../Assets/accessory18.jpeg";
import accessory19 from "../../Assets/accessory19.jpeg";
import accessory20 from "../../Assets/accessory20.jpeg";
import accessory21 from "../../Assets/accessory21.jpeg";
import accessory22 from "../../Assets/accessory22.jpeg";
import accessory23 from "../../Assets/accessory23.jpeg";
import accessory24 from "../../Assets/accessory24.jpeg";
import accessory25 from "../../Assets/accessory25.jpeg";
import accessory26 from "../../Assets/accessory26.jpeg";
import accessory27 from "../../Assets/accessory27.jpeg";
import accessory28 from "../../Assets/accessory28.jpeg";
import accessory29 from "../../Assets/accessory29.jpeg";
import accessory30 from "../../Assets/accessory30.jpeg";
import accessory31 from "../../Assets/accessory31.jpeg";
import accessory32 from "../../Assets/accessory32.jpeg";
import accessory33 from "../../Assets/accessory33.jpeg";
import accessory34 from "../../Assets/accessory34.jpeg";

function Greenhouse_Accessories() {
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 12; // Number of products per page

  // List of products
  const products = [
    {
      id: 47,
      name: "Polyhouse Tension Hook for Curtain Control",
      // price: 18816,
      image: accessory1,
      link: "/Tension_Hook_for_Polyhouse",
    },
    {
      id: 48,
      name: "Shade Net Pulley for Greenhouse Systems",
      //price: 18900,
      image: accessory2,
      link: "/Shade_Net_Pulley_for_Greenhouse_Systems",
    },
    {
      id: 49,
      name: "U-Shape Support Clip",
      //price: 15120,
      image: accessory3,
      link: "/U_Shape_Support_Clip",
    },
    {
      id: 50,
      name: "Durling Clamps",
      //price: 7560,
      image: accessory4,
      link: "/Durling_Clamps",
    },
    {
      id: 51,
      name: "Zigzag Spring Clip",
      //price: 9072,
      image: accessory5,
      link: "/Zigzag_Spring_Clip",
    },
    {
      id: 52,
      name: "Self-Drilling Screw Set with EPDM Washers",
      //price: 9072,
      image: accessory6,
      link: "/Self_Drilling_Screw_Set_with_EPDM_Washers",
    },
    {
      id: 53,
      name: "U-Shaped Clamp (Saddle Clamp) by RGCPL",
      //price: 9072,
      image: accessory7,
      link: "/U_Shaped_Clamp_by_RGCPL",
    },
    {
      id: 54,
      name: "Pipe Joiner/Connector Kit by RGCPL",
      //price: 9072,
      image: accessory8,
      link: "/Pipe_Joiner_Connector_Kit_by_RGCPL",
    },
    {
      id: 55,
      name: "L-Bracket (Corner Bracket) by RGCPL",
      //price: 9072,
      image: accessory9,
      link: "/L_Bracket_Corner_Bracket_by_RGCPL",
    },
    {
      id: 56,
      name: "Wire Tensioning Cable (Guy Wire)",
      //price: 9072,
      image: accessory10,
      link: "/Wire_Tensioning_Cable_Guy_Wire",
    },
    {
      id: 57,
      name: "Galvanized Iron Wire",
      //price: 9072,
      image: accessory11,
      link: "/Galvanized_Iron_Wire",
    },
    {
      id: 58,
      name: "GI Profile",
      //price: "100/per 4 Meter ",
      image: accessory12,
      link: "/Galvanized_Iron_Channel_GI_Channel",
    },
    {
      id: 59,
      name: "Greenhouse Support Clamp",
      //price: 9072,
      image: accessory13,
      link: "/Greenhouse_Support_Clamp",
    },
    {
      id: 60,
      name: "Twisted Rope (Tensioning Rope)",
      //price: 9072,
      image: accessory14,
      link: "/Twisted_Rope_Tensioning_Rope",
    },
    {
      id: 61,
      name: "Shade Net Stitching Pins for Polyhouse by Royal Green House ",
      //price: "₹5/- per piece",
      image: accessory15,
      link: "/Shadenet_stitching_pin",
    },
    {
      id: 62,
      name: "T-Joint Pipe Connector",
      //price: 9072,
      image: accessory16,
      link: "/T_Joint_Pipe_Connector",
    },
    {
      id: 63,
      name: "Pulley Wheel for Greenhouse",
      //price: 9072,
      image: accessory17,
      link: "/Pulley_Wheel_for_Greenhouse",
    },
    {
      id: 64,
      name: "Greenhouse Pipe Clamp",
      //price: 44,
      image: accessory18,
      link: "/Greenhouse_Pipe_Clamp",
    },
    {
      id: 65,
      name: "Greenhouse Base Plate (Corner Support Plate)",
      //price: 9072,
      image: accessory19,
      link: "/Greenhouse_Base_Plate_Corner_Support_Plate",
    },
    {
      id: 66,
      name: "Greenhouse Clamp Set (Wire, Hook & Tensioner Set)",
      //price: 9072,
      image: accessory20,
      link: "/Greenhouse_Clamp_Set_Wire_Hook_Tensioner_Set",
    },
    {
      id: 67,
      name: "Greenhouse Curtain Pulley",
      //price: 9072,
      image: accessory21,
      link: "/Greenhouse_Curtain_Pulley",
    },
    {
      id: 68,
      name: "Greenhouse Spring Clip (Wire Fastening Hook)",
      //price: 9072,
      image: accessory22,
      link: "/Greenhouse_Spring_Clip_Wire_Fastening_Hook",
    },
    {
      id: 69,
      name: "Greenhouse Pipe Saddle Clamp",
      //price: 9072,
      image: accessory23,
      link: "/Greenhouse_Pipe_Saddle_Clamp",
    },
    {
      id: 70,
      name: "Greenhouse Flat Support Plate",
      //price: 9072,
      image: accessory24,
      link: "/Greenhouse_Flat_Support_Plate",
    },
    {
      id: 71,
      name: "Round Pipe Clamp (Gable Shaped Polyhouse Clamp)",
      //price: 9072,
      image: accessory25,
      link: "/Round_Pipe_Clamp_Gable_Shaped_Polyhouse_Clamp",
    },
    {
      id: 72,
      name: "Pulley with Lock Mechanism (Gable Shaped Polyhouse Pulley)",
      //price: 9072,
      image: accessory26,
      link: "/Pulley_with_Lock_Mechanism_Gable_Shaped_Polyhouse_Pulley",
    },
    {
      id: 73,
      name: "Plant Support Clip for Vine crops",
      //price: 1.25,
      image: accessory27,
      link: "/Plant_Support_Clip_for_vinecrops",
    },
    {
      id: 74,
      name: "Greenhouse Pipe Bracket (Corner Mount Bracket)",
      //price: 9072,
      image: accessory28,
      link: "/Greenhouse_Pipe_Bracket",
    },
    {
      id: 75,
      name: "Greenhouse Bolt and Nut Kit (General Frame Installation)",
      //price: 9072,
      image: accessory29,
      link: "/Greenhouse_Bolt_and_Nut_Kit",
    },
    {
      id: 76,
      name: "Greenhouse Pulley with Lock (Ventilation Mechanism)",
      //price: 9072,
      image: accessory30,
      link: "/Greenhouse_Pulley_with_Lock",
    },

    {
      id: 78,
      name: "Poly House Gutter Plate by RGCPL – Manufactured in India  ",
      //price: "450/- per piece",
      image: accessory32,
      link: "/Poly_House_Gutter_Plate",
    },
    {
      id: 79,
      name: "H-Hook",
      // price: 4,
      image: accessory33,
      link: "/H_hook",
    },
    {
      id: 80,
      name: "Greenhouse Pipe Support Clamp (Bracket Type)",
      // price: 9072,
      image: accessory34,
      link: "/Greenhouse_Pipe_Support_Clamp",
    },
  ];

  // Calculate index of the first and last product on the current page
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <Container>
        <Row>
          {currentProducts.map((product) => (
            <Col key={product.id} sm={12} md={6} lg={4}>
              <Card className="mb-4 text-center">
                <Card.Img
                  variant="top"
                  src={product.image}
                  className="mx-auto"
                />
                <Card.Body>
                  <Card.Title className="text-center">
                    {product.name}
                  </Card.Title>
                  <Card.Text>
                    <Link to="/Contact" className="btn btn-link">
                      Contact Us for the 
                      Price Details
                    </Link>
                  </Card.Text>
                  <Button
                    variant="primary"
                    as={Link}
                    to={product.link}
                    className="mx-auto"
                  >
                    View Details
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <nav>
          <ul className="pagination justify-content-center">
            {[
              ...Array(Math.ceil(products.length / productsPerPage)).keys(),
            ].map((number) => (
              <li
                key={number}
                className={`page-item ${
                currentPage === number + 1 ? "active" : ""
                }`}
              >
                <Button
                  onClick={() => paginate(number + 1)}
                  className="page-link"
                >
                  {number + 1}
                </Button>
              </li>
            ))}
          </ul>
        </nav>
      </Container>
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
}
export default Greenhouse_Accessories;
