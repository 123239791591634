import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory29 from "../../Assets/accessory29.jpeg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Greenhouse_Bolt_and_Nut_Kit = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 75,
      name: "Greenhouse Bolt and Nut Kit (General Frame Installation)",
      price: 250,
      image: accessory29,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory29}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Greenhouse Bolt and Nut Kit (General Frame Installation)
            </h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The Bolt and Nut Kit is essential for securely fastening frame
                  components in greenhouse structures. Designed for heavy loads
                  and long-term durability, this set ensures a stable and
                  weather-resistant connection.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span> Galvanized Iron
                    </li>
                    <li>
                      <span className="fw-bold">Color:</span> Silver
                    </li>

                    <li>
                      <span className="fw-bold">Size:</span> Various lengths for
                      different construction needs
                    </li>
                    <li>
                      <span className="fw-bold">Usage/Application:</span>Used
                      for corner reinforcement and connection of polyhouse or
                      greenhouse pipes
                    </li>
                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 7-8 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>
                    <span className="fw-bold">High tensile strength:</span>{" "}
                    Ideal for securing heavy polyhouse frames.
                  </li>
                  <li>
                    <span className="fw-bold">Corrossion-Resistant:</span>
                    Galvanized steel ensures protection against rust.
                  </li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Large
                      Bags
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <h5 className="mt-3">Key Benefits of Greenhouse Bolt and Nut Kit:</h5>
        <p className="text-justify">
          <ul>
            <li>Versatile: Suitable for a variety of frame installations.</li>
            <li>Durable: Long-lasting under heavy tension and weather conditions.
            </li>
          </ul>
        </p>

        <h5 className="mt-3">Importance of Greenhouse Bolt and Nut Kit:</h5>
        <p className="text-justify">
          Bolt and nut kits are the backbone of any polyhouse or greenhouse
          assembly. A secure fastening system ensures that the structure remains
          stable under various loads, particularly in areas prone to high winds
          or heavy materials. Without proper fastening, the greenhouse could
          collapse or suffer from significant damage.
        </p>

        <h5 className="mt-3">Applications of Greenhouse Bolt and Nut Kit:</h5>
        <p className="text-justify">
          <ul>
            <li>Fastening frame components and structural elements.</li>
            <li>Used in attaching base plates, brackets, and polyhouse joints.
            </li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Greenhouse_Bolt_and_Nut_Kit;
