import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";

import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/gallery/product1.jpg";
import CardImg2 from "../../Assets/gallery/product2.jpg";
import CardImg3 from "../../Assets/gallery/product4.jpg";
import CardImg4 from "../../Assets/gallery/product3.jpg";
import CardImg5 from "../../Assets/gallery/product5.jpg";
import Gerbera from "../../Assets/gerbera.png";
import AloeVera from "../../Assets/AloeVera.png";
import Rose from "../../Assets/rose.png";
import Strawberry from "../../Assets/Strawberry.png";
import Tulip from "../../Assets/Tulip.png";

import "../../App.css";
function Horticulture() {
  return (
    <Container fluid>
      <Row className="headingimg">
        <Col className="m-5">
          <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
            {" "}
            Products
          </h1>
          <div className="text-white m-4 fw-bold text-center">
            <a href="#" className=" text-white m-3 text-decoration-none">
              Home
            </a>
            <i className="fa-solid fa-circle-arrow-right fa-beat"></i>
            <a className="text-white text-decoration-none m-3">Products</a>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="mt-4 pt-4 ">
          <h2 className="text-green m-3 text-center fw-bold">
            Our Fresh Produce
          </h2>
          <h6 className="text-center m-4 fw-bold">
            All our produce is fresh, nutritious and hand-picked. <br /> We are
            dedicated to deliver to you the top-shelf produce.
          </h6>
        </Col>
      </Row>
      <Row className="p-2">
        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg1} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Vine Crops
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg2} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Lettuces
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg3} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Leafy Greens
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="row justify-content-center">
        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg4} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Fresh Herbs
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg5} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Horticulture
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col sm={12} className="mt-4 pt-4 ">
          <h1 className="text-green m-3 text-center fw-bold">Horticulture</h1>
          
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Gerbera
              </h2>
              <p className="text-justify ">
                Gerbera is a vibrant and cheerful flowering plant often used in
                bouquets and floral arrangements.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Appearance:</span>
                    <li>Large, vibrant, daisy-like blooms.</li>
                    <li>Wide range of bright colors.</li>
                    <li>Prominent central disc with petals.</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Uses:</span>
                    <li>Popular in floral arrangements and bouquets</li>
                    <li>Brightens up indoor and outdoor spaces</li>
                    <li>Adds color to gardens and decor</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img src={Gerbera} alt="horticulture farming" className="img-fluid p-3" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <img src={AloeVera} alt="aloevera farming" className="img-fluid p-3" />
            </Col>
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Aloe Vera
              </h2>
              <p className="text-center ">
                Aloe Vera- A hardy succulent with thick, gel-filled leaves.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Uses:</span>
                    <li>Ingredients in skincare and cosmetic products</li>
                    <li>Used in pharmaceuticals for its healing properties</li>
                    <li>Incorporated into dietary supplements and beverages</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Soothes skin and reduces inflammation</li>
                    <li>Supports hydration and skin healing</li>
                    <li>Aids in digestive health and detoxification</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Rose
              </h2>
              <p className="text-center ">
                Rose, often used in beauty products and fragrances.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Uses:</span>
                    <li>Utilized in perfumes and fragrances</li>
                    <li>Incorporated into skincare and cosmetic products</li>
                    <li>Used in culinary applications for rosewater</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold">Appearance:</span>
                    <li>Large, vibrant petals in various colors</li>
                    <li>Distinctive fragrance with a soft texture</li>
                    <li>Often presented in elegant, multi-layered blooms</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img src={Rose} alt="rose in hydroponics" className="img-fluid p-3" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <img src={Strawberry} alt="strawberry farming" className="img-fluid p-3" />
            </Col>
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Strawberry
              </h2>
              <p className="text-center ">
                Strawberry, renowned for its sweet and juicy flavor, is one of
                the most beloved fruits globally.
                <br />
               <span className="fw-bold"> Flavour:</span> Sweet and slightly tangy with a juicy, refreshing
                taste.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Uses:</span>
                    <li>Used in flavoring for beverages and candies</li>
                    <li>Incorporated into jams, jellies, and preserves</li>
                    <li>
                      Utilized in cosmetic products for fragrance and
                      exfoliation
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Rich in antioxidants for cellular health</li>
                    <li>Supports heart health and immune function</li>
                    <li>Promotes healthy skin and digestion</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3 ">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Tulip
              </h2>
              <p className="text-justify ">
                Tulip, admired for its elegant blooms and vibrant colors, is a
                popular flower in gardens and floral arrangements.
                <br />
                Appearance: Sleek, cup-shaped petals in a variety of bright,
                striking colors.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Uses:</span>
                    <li>Popular in floral arrangements and bouquets</li>
                    <li>Utilized in landscaping for vibrant garden displays</li>
                    <li>Used in floral design for events and decorations</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Appearance:</span>
                    <li>Elegant, cup-shaped blooms in various colors</li>
                    <li>Distinctive smooth, lance-shaped leaves</li>
                    <li>Graceful and symmetrical flower structure</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img src={Tulip} alt="horticulture" className="img-fluid p-3" />
            </Col>
          </Row>
        </Col>
      </Row>

    </Container>
  );
}

export default Horticulture;
