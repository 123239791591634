import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Enquiry from "../../Components/Enquiry";
// import Accordion from 'react-bootstrap/Accordion';
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import Lowcost from "../../Assets/lowcost.jpg";
function LowcostGreenhouse() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-3">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              Low Cost Greenhouse Setup
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">
                Low Cost Greenhouse Setup
              </a>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="m-3 mb-2">
            <h6 className="text-green m-2">Low Cost Greenhouse Setup</h6>
            <h2 className="m-2">Low Cost Greenhouse Setup</h2>

            <Row>
              <Col lg={6} className="text-justify">
                <p className="text-muted m-2">
                  A low-cost greenhouse is a basic building made of locally
                  accessible materials like bamboo and timber. The cladding
                  materials are made of ultraviolet (UV) film.
                </p>
                <p className="text-muted m-2">
                  There is no specialized control device for managing
                  environmental conditions within the greenhouse, unlike
                  conventional or high-tech greenhouses. However simple
                  techniques are used to increase or decrease temperature and
                  humidity. Even the intensity of light can be lowered by using
                  shading materials such as nets. During the summer, the
                  temperature can be lowered by opening the side walls.
                </p>
                <p className="text-muted m-2">
                  To build a small greenhouse on a budget, some cheap
                  construction ideas include using reclaimed or recycled
                  materials, PVC pipes for the frame, plastic sheeting for
                  glazing, simple and easy-to-build designs, a raised bed
                  flooring, a simple irrigation system, natural light, and a
                  simple heating and cooling system
                </p>
              </Col>
              <Col className="p-3" lg={6}>
                <img src={Lowcost} alt="vine" className="img-fluid rounded" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <TestimonialsSlider />
      <Enquiry />
    </>
  );
}

export default LowcostGreenhouse;
