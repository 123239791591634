import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory9 from "../../Assets/accessory9.jpeg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const L_Bracket_Corner_Bracket_by_RGCPL = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 55,
      name: "L-Bracket (Corner Bracket) by RGCPL",
      price: 250,
      image: accessory9,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory9}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">L-Bracket (Corner Bracket) by RGCPL</h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  RGCPL’s L-Bracket, also known as a corner bracket, is a
                  high-strength support used for securing joints at right angles
                  in greenhouses and poly houses. These brackets are
                  manufactured using galvanized iron, ensuring durability and
                  resistance to harsh weather conditions. They play a crucial
                  role in stabilizing the framework by providing firm corner
                  joints.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span> Galvanized Iron
                      (GI)
                    </li>
                    <li>
                      <span className="fw-bold">Color:</span> Silver
                    </li>
                    <li>
                      <span className="fw-bold">Length:</span> 10 Inches
                    </li>
                    <li>
                      <span className="fw-bold">Thickness:</span> 4 mm
                    </li>
                    <li>
                      <span className="fw-bold">Shape:</span> L-shaped
                    </li>

                    <li>
                      <span className="fw-bold">Usage/Application:</span> Corner
                      support for agriculture structures
                    </li>
                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 5-7 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>Eco-friendly</li>
                  <li>High strength</li>
                  <li>Corrosion-resistant</li>
                  <li>Structure: GI (Galvanized Iron)</li>
                  <li>Built Type: Corner Support</li>
                  <li>Type: Greenhouse/Poly House Structural Support</li>
                  <li>Pattern: RGCPL</li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Large
                      Bags
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <h5 className="mt-3">Why Choose RGCPL for L-Brackets?</h5>
        <p className="text-justify">
          RGCPL's L-Brackets are made with high-quality galvanized iron,
          providing excellent support and durability in polyhouse structures.
          They are resistant to Corrosion and capable of handling heavy loads.
          Choosing RGCPL ensures that you are getting brackets manufactured
          under strict quality controls, with reliable shipping and competitive
          pricing.
        </p>

        <h5 className="mt-3">Importance of L-Brackets:</h5>
        <p className="text-justify">
          L-brackets are essential for reinforcing corner joints in poly house
          and greenhouse structures. These brackets provide the necessary
          rigidity to support the overall framework and prevent structural
          shifting over time.
        </p>
        <h5 className="mt-3">Key Benefits:</h5>

        <p className="text-justify">
          <ul>
            <li>
              <span className="fw-bold">Strength and Stability:</span>{" "}
              Reinforces corner joints, providing structural Stability under
              stress.
            </li>
            <li>
              <span className="fw-bold">Corrosion Resistance:</span>Galvanized
              iron construction ensures protection from rust, extending the life
              of the bracket.
            </li>
            <li>
              <span className="fw-bold">Heavy-Duty Performance:</span> Can
              withstand the weight of polyhouse panels and the forces of
              weather, including strong winds.
            </li>
            <li>
              <span className="fw-bold">Easy installation:</span> Quick to
              install with pre-drilled holes for screws or bolts.
            </li>
          </ul>
        </p>
        <h5 className="mt-3">Applications:</h5>
        <p className="text-justify">
          <ul>
            <li>
              Securing corner joints in polyhouse and greenhouse frameworks.
            </li>
            <li>Providing right-angle support in general construction.</li>
            <li>
              Reinforcing the structural integrity of agricultural buildings.
            </li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default L_Bracket_Corner_Bracket_by_RGCPL;
