import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Commercial_Hydroponic_Img from "../../Assets/gallery/Commercial_Hydroponic_Img.jpeg";
import { Link } from "react-router-dom";
import "animate.css";

function Commercial_Hydroponic_Farming() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={Commercial_Hydroponic_Img}
          className={`card-img-top img-fluid rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{
            maxWidth: "90%",
            width: "100%",
            height: "auto",
            marginTop: "20px",
          }}
          alt="commercial hydroponic farm"
        />
      </div>

      <Row className="mx-2 mx-md-5 my-4">
        <Col
          className="p-3 text-justify"
          sm={12}
          md={9}
          style={{
            fontSize: "1rem",
            lineHeight: "1.6",
            color: "#333",
            backgroundColor: "#f8f9fa",
            borderRadius: "8px",
          }}
        >
          <div style={{ padding: "20px" }}>
            <h1 className="text-center pb-3">Commercial Hydroponic Farming</h1>
            <h2 className="text-center mb-4">A New Frontier in Agriculture</h2>
            <p>
              Commercial hydroponic farming is transforming agriculture by
              offering a more efficient, sustainable way to grow crops. Unlike
              traditional farming, it uses water-based solutions instead of
              soil, allowing crops to grow faster and with fewer resources.
            </p>

            <h4>High Productivity and Profitability</h4>
            <p>
              Commercial hydroponic farming can{" "}
              <span style={{ fontWeight: "bold" }}>maximize yield</span> in less
              space, especially with vertical farming techniques. Crops grown
              hydroponically can yield{" "}
              <span style={{ fontWeight: "bold" }}>40-60% higher</span> than
              soil-based methods, a valuable asset for profit-focused farmers.
            </p>
            <p>
              The <span style={{ fontWeight: "bold" }}>year-round growing</span>{" "}
              season is another advantage. Unlike traditional agriculture,
              hydroponics enables{" "}
              <span style={{ fontWeight: "bold" }}>continuous production</span>,
              providing fresh produce even in off-seasons for steady revenue.
            </p>

            <h4>Water and Resource Efficiency</h4>
            <p>
              Hydroponic systems are water-efficient, using{" "}
              <span style={{ fontWeight: "bold" }}>up to 90% less water</span>{" "}
              than traditional methods by recirculating water. For water-scarce
              areas, this feature makes agriculture feasible and sustainable.
            </p>

            <h4>Pest Control and Reduced Chemicals</h4>
            <p>
              In hydroponics, crops are grown in{" "}
              <span style={{ fontWeight: "bold" }}>
                controlled environments
              </span>
              , reducing pest exposure and reliance on pesticides. As a result,
              the produce is cleaner, healthier, and increasingly organic.
            </p>

            <h4>
              Urban Farming and{" "}
              <Link to="/VineCrops" style={{ textDecoration: "none" }}>
                Fresh Produce
              </Link>
            </h4>
            <p>
              Hydroponics supports{" "}
              <span style={{ fontWeight: "bold" }}>urban farming</span>, making
              it easier to grow food in cities, closer to consumers, and reduces
              transport costs while ensuring fresher produce. Rooftops and
              greenhouses in urban areas offer innovative farming spaces.
            </p>

            <h4>Initial Costs vs. Long-Term Gains</h4>
            <p>
              While the <span style={{ fontWeight: "bold" }}>initial cost</span>{" "}
              of hydroponic farming is high, it’s often offset by quicker crop
              cycles, higher yields, and lower resource consumption, making it
              profitable in just a few years.
            </p>
            <p>
              In conclusion, commercial hydroponic farming is a{" "}
              <span style={{ fontWeight: "bold" }}>
                profitable, sustainable, and efficient
              </span>{" "}
              alternative to traditional farming. With the growing demand for
              fresh, healthy, and locally-produced food, hydroponics is poised
              to become a significant part of agriculture’s future.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Commercial_Hydroponic_Farming;
