import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory30 from "../../Assets/accessory30.jpeg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Greenhouse_Pulley_with_Lock = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 76,
      name: "Greenhouse Pulley with Lock (Ventilation Mechanism)",
      price: 250,
      image: accessory30,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory30}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Greenhouse Pulley with Lock (Ventilation Mechanism)
            </h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The Greenhouse Pulley with Lock Mechanism allows for easy
                  roll-up and down of polyhouse sidewalls, providing efficient
                  ventilation and temperature control. Its locking system
                  ensures the structure stays secure during extreme weather.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span> High-Strength
                      Plastic with GI Lock
                    </li>
                    <li>
                      <span className="fw-bold">Color:</span> White and Silver
                    </li>

                    <li>
                      <span className="fw-bold">Diameter:</span> 3 inches
                      (pulley wheel)
                    </li>

                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 5 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>
                    <span className="fw-bold">Smooth Rolling:</span> Designed
                    for easy sidewall adjustments.
                  </li>
                  <li>
                    <span className="fw-bold">Lock Mechanism:</span>
                    Secure the ventilation system in place.
                  </li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Large
                      Bags
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <h5 className="mt-3">Key Benefits of Greenhouse Pulley with Lock:</h5>
        <p className="text-justify">
          <ul>
            <li>Reliable Control: Easy to lock and adjust as needed.</li>
            <li>Durable and Weather-Proof: Ideal for outdoor polyhouses.</li>
          </ul>
        </p>

        <h5 className="mt-3">Importance of Greenhouse Pulley with Lock:</h5>
        <p className="text-justify">
          Proper ventilation is vital for maintaining an optimal growing
          environment inside a greenhouse. The pulley with a lock mechanism
          allows for precise control of airflow, helping to regulate temperature
          and humidity for better plant growth. Additionally, the lock ensures
          stability during adverse weather, preventing unwanted sidewall
          movements.
        </p>

        <h5 className="mt-3">Applications of Greenhouse Pulley with Lock:</h5>
        <p className="text-justify">
          <ul>
            <li>
              Used in roll-up systems for adjusting sidewalls in greenhouses.
            </li>
            <li>Essential for regulating temperature and airflow.</li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Greenhouse_Pulley_with_Lock;
