import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory5 from "../../Assets/accessory5.jpeg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Zigzag_Spring_Clip = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 51,
      name: "Zigzag Spring Clip",
      price: 250,
      image: accessory5,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory5}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Zigzag Spring Clip</h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  Royal Green House offers Zigzag Spring Clips for securely
                  attaching shade nets or polyfilm in greenhouses and net
                  houses. Made from galvanized steel, these clips provide
                  excellent tension, ensuring that covering materials remain
                  firmly in place, even in adverse weather conditions. Designed
                  for long-lasting durability, these clips are ideal for use in
                  agricultural operations requiring robust and reliable
                  attachment solutions.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Structure" className="custom-tab">
                  Structure
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>Material: Galvanized Iron (GI) </li>
                    <li>Color: Silver </li>
                    <li>Height: 2.5 Inches</li>
                    <li>Length: 6 Inches </li>
                    <li>Thickness: 2 mm </li>
                    <li>Shape: Zigzag </li>
                    <li>Usage/Application: Greenhouse / Poly House </li>
                    <li>Brand: RGCPL</li>
                    <li>Durability: 5 Years</li>
                    <li>Application: Fixing Plastic/Net on GI Pipes</li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>Eco-friendly</li>
                  <li>Easy to use and install</li>
                  <li>Durable and weather-resistant</li>
                  <li>Strong grip for plastic film and nets</li>
                  <li>Corrosion-resistant</li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="Structure">
                <h4>Structure</h4>
                <ul>
                  <li>Structure: GI (Galvanized Iron) </li>
                  <li>Built Type: Zigzag Spring Clip for GI Pipe </li>
                  <li>
                    Type: Used for all types of Greenhouse and Poly House
                    structures{" "}
                  </li>
                  <li>Pattern: RGCPL</li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>Item Code: RGCPL-0001</li>
                    <li>Delivery Time: 7-8 Days </li>
                    <li>Port of Dispatch: Maharashtra, India </li>
                    <li>Production Capacity: 2000 Pieces Per Day </li>
                    <li>Packaging Details: Large Bags </li>
                    <li>Packing Quantity: 500 Clips Per Bag </li>
                    <li>Payment Terms: Advance Payment </li>
                    <li>Payment Mode: RTGS / NEFT / IMPS</li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <h5 className="mt-3">Why Choose RGCPL for Zigzag Spring Clips?</h5>
        <p className="mt-1 text-justify">
          RGCPL’s Zigzag Spring Clips are trusted across the agriculture and
          greenhouse industry. Manufactured in-house, these clips are designed
          to ensure superior grip and durability, making them the perfect choice
          for securing plastic or shade net covers in greenhouses and poly
          houses.
        </p>
        <h5 className="mt-3">Importance of Zigzag Spring Clips</h5>
        <p className="text-justify">
          Zigzag Spring Clips are essential for securing the plastic film or
          shade net to the GI pipes in poly houses or greenhouses. They ensure
          that the covering material remains tightly fixed to the structure,
          protecting plants from external weather conditions such as wind and
          rain. RGCPL's clips are made from high-quality GI material to ensure
          long-lasting performance, even under the most demanding environmental
          conditions.{" "}
        </p>
        <h5 className="mt-3">Key Benefits of RGCPL’s Zigzag Spring Clips:</h5>
        <p className="text-justify">
          <ul>
            <li><span className="fw-bold">
              Secure Fastening:</span> Ensures that the plastic film or shade net
              remains tightly attached to the structure, even in strong winds.
            </li>
            <li><span className="fw-bold">
              Durability:</span> With a lifespan of up to 5 years, RGCPL's clips
              provide long-term reliability.
            </li>
            <li><span className="fw-bold">
              Weather-Resistant:</span> The galvanized coating prevents corrosion,
              making them ideal for outdoor applications.
            </li>
            <li><span className="fw-bold">
              Cost-Effective:</span> A durable and affordable solution for greenhouse
              and poly house needs.
            </li>
            <li><span className="fw-bold">
              Eco-Friendly:</span> Made with sustainable materials for environmentally
              responsible agriculture.
            </li>
          </ul>
        </p>
        <h5 className="mt-3">
          Types of Zigzag Spring Clips Available from RGCPL:
        </h5>
        <p className="text-justify">
          <ol>
            <li><span className="fw-bold">
              Standard Zigzag Spring Clips:</span> Ideal for all-purpose use in
              greenhouses and poly houses.{" "}
            </li>
            <li><span className="fw-bold">
              Heavy-Duty Zigzag Spring Clips:</span> Designed for larger structures or
              regions with high wind speeds.{" "}
            </li>
            <li><span className="fw-bold">
              Flexible Zigzag Spring Clips:</span> Offers a balance between grip
              strength and flexibility for quick installation.{" "}
            </li>
            <li><span className="fw-bold">
              PVC Coated Zigzag Spring Clips:</span> Adds an extra layer of protection
              to avoid damage to delicate materials.
            </li>
          </ol>
        </p>
        <h5 className="mt-3">
          Installation Methods and Techniques for Zigzag Spring Clips:
        </h5>
        <p className="text-justify">
          <ul>
            <li><span className="fw-bold">
              Manual Installation:</span> Simply slide the clip over the plastic film
              and GI pipe for a secure fit.
            </li>
            <li><span className="fw-bold">
              Clip Pliers:</span> For faster installation, use specialized clip pliers
              to fix multiple clips efficiently.
            </li>
            <li><span className="fw-bold">
              Overlapping System:</span> Overlap clips for added security in areas
              prone to high winds or pressure.
            </li>
            <li><span className="fw-bold">
              Uniform Spacing:</span> Ensure clips are spaced evenly for uniform grip
              and tension across the structure.
            </li>
          </ul>
        </p>
        <h5 className="mt-3">Manufacturing Excellence by RGCPL:</h5>
        <p className="text-justify">
          RGCPL takes pride in manufacturing high-quality Zigzag Spring Clips,
          ensuring that each piece meets industry standards for durability and
          performance. By producing locally in India, we provide affordable
          pricing and ensure fast, efficient delivery across the country.{" "}
        </p>
        <p className="text-justify">
          Choose RGCPL for your Zigzag Spring Clip needs and ensure that your
          greenhouse or poly house remains securely covered with high-quality
          products. Our clips are crafted to withstand tough environmental
          conditions, keeping your structure safe and intact.
          <ul>
            <li>Manufacturer of Zigzag Spring Clips in India</li>
            <li>Greenhouse Clips by RGCPL</li>
            <li>Durable and Corrosion-Resistant Zigzag Spring Clips</li>
            <li>Best Spring Clips for Poly House Structures</li>
            <li>Eco-Friendly Spring Clips for Sustainable Agriculture </li>
            For inquiries and orders, contact{" "}
            <span className="fw-bold">RGCPL</span> today and ensure your poly
            house structure is equipped with the best gutter system for
            long-term success!
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Zigzag_Spring_Clip;
