import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import cucumber_blog from "../../Assets/cucumber_blog.png";
import "animate.css";

function Downy_Mildew_Infestation_in_Cucumber() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={cucumber_blog}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          alt="infestation in cucumber, polyhouse farming"
          style={{
            width: "100%",
            maxWidth: "70%",
            height: "auto",
          }}
        />
      </div>

      <Row className="m-md-5 m-3">
        <Col
          sm={12}
          md={9}
          style={{
            padding: "2rem",
            textAlign: "justify",
            border: "1px solid #dee2e6",
            borderRadius: "0.25rem",
          }}
        >
          <h1
            className="text-center"
            style={{ padding: "1rem", fontSize: "2rem" }}
          >
            Downy Mildew Infestation in Cucumber (🥒)
          </h1>
          <p style={{ paddingBottom: "1.5rem" }}>
            Downy mildew (Oomycota) is one of the most destructive foliar
            diseases affecting cucumber, particularly in late-season crops. It
            thrives in humid environments and can spread rapidly in greenhouses
            through air currents, contaminated tools, and clothing. Optimum
            infection temperatures range between 16°C and 22°C, with rapid
            spread at warmer temperatures.
          </p>

          <h4>Causes:</h4>
          <ul>
            <li>
              Fluctuating temperature and humidity levels inside the greenhouse
              (high humidity)
            </li>
            <li>Improper ventilation</li>
          </ul>

          <h4>Symptoms:</h4>
          <ul>
            <li>
              Initial signs appear on older, lower leaves as angular yellow
              spots on the upper surface, and purplish-grey to black fungal
              growth on the underside.
            </li>
            <li>
              As the disease progresses, necrotic spots form, leading to leaf
              chlorophyll loss and wilting.
            </li>
          </ul>

          <h4>Management:</h4>
          <ul>
            <li>
              Maintain optimal greenhouse conditions with proper ventilation.
            </li>
            <li>Collect and destroy infected plant debris.</li>
            <li>Ensure regular sanitation practices.</li>
            <li>Remove weeds and alternate host plants.</li>
            <li>Minimize leaf wetness and practice effective deleafing.</li>
          </ul>

          <p style={{ paddingBottom: "1.5rem" }}>
            At Royal Green House (RGCPL), we provide advanced greenhouse
            solutions to help growers optimize their environments and combat
            diseases like downy mildew. For expert guidance, contact us at{" "}
            <a href="tel:+919960991166">+91-9960-991-166</a> or visit{" "}
            <a href="https://royalgreenhouse.co.in">
              https://royalgreenhouse.co.in
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Downy_Mildew_Infestation_in_Cucumber;
