import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory21 from "../../Assets/accessory21.jpeg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Greenhouse_Curtain_Pulley = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 67,
      name: "Greenhouse Curtain Pulley",
      price: 250,
      image: accessory21,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory21}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Greenhouse Curtain Pulley</h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The Greenhouse Curtain Pulley is a specialized accessory used
                  in greenhouses to roll up and down side or roof curtains. It
                  plays an essential role in regulating temperature and
                  ventilation inside greenhouses by allowing easy adjustment of
                  the curtains.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span>Galvanized Iron
                      (GI) with nylon wheel
                    </li>
                    <li>
                      <span className="fw-bold">Wheel Type:</span> Pulley with
                      smooth-rolling function
                    </li>
                    <li>
                      <span className="fw-bold">Mounting:</span> Hook and clamp
                      mounting for secure attachment
                    </li>
                    <li>
                      <span className="fw-bold">Functionality:</span> Used to
                      operate rolling curtain systems
                    </li>

                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 2-3 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>
                    Corrosion-resistant: Galvanized surface for rust prevention.
                  </li>
                  <li>
                    Smooth Operation: Nylon pulley ensures easy movement without
                    friction.
                  </li>
                  <li>
                    Easy to Mount: Hook attachment for simple installation.
                  </li>
                  <li>
                    Durable: Built to withstand frequent use and environmental
                    exposure.
                  </li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Large
                      Bags
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <h5 className="mt-3">Importance of Greenhouse Curtain Pulley:</h5>
        <p className="text-justify">
          <ul>
            <li>
              <span className="fw-bold">Ventilation Control:</span> It is
              crucial for controlling the airflow in a greenhouse, helping to
              regulate the temperature and humidity, which directly affects
              plant growth.
            </li>
            <li>
              <span className="fw-bold">Ease of Operation:</span> Provides a
              simple mechanism for greenhouse owners to adjust curtains,
              improving convenience and overall operational efficiency.
            </li>
            <li>
              <span className="fw-bold">Climate Management:</span> Allows
              precise regulation of the internal greenhouse climate, protecting
              crops from overheating or excessive humidity.
            </li>
          </ul>{" "}
        </p>
        <h5 className="mt-3">Key Benefits of Greenhouse Curtain Pulley:</h5>

        <p className="text-justify">
          <ul>
            <li>
              Provides smooth and controlled movement for greenhouse curtains.
            </li>
            <li>Ensures easy regulation of temperature and air ventilation.</li>
            <li>Low maintenance with durable and rust-resistant parts.</li>
          </ul>
        </p>
        <h5 className="mt-3">Applications of Greenhouse Curtain Pulley:</h5>
        <p className="text-justify">
          <ul>
            <li>
              Used in greenhouses, polyhouses, and shade houses for controlling
              curtains.
            </li>
            <li>
              Helps regulate light and temperature for optimal plant growth.
            </li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Greenhouse_Curtain_Pulley;
