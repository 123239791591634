import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/step1.png";
import CardImg2 from "../../Assets/step2.png";
import CardImg3 from "../../Assets/step3.png";
import CardImg4 from "../../Assets/step4.png";
import Enquiry from "../../Components/Enquiry";
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import vineImg from "../../Assets/gallery/23.png";
function NvphDutchBucket() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              NVPH Vine Crops
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">
                NVPH Vine Crops
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="m-5 mb-2">
            <h6 className="text-green m-2">Indoor / Outdoor Horizontal</h6>
            <h2 className="m-2">
              Naturally ventilated polyhouse with Vine Crops
            </h2>
            <Row>
              <Col lg={6}>
                <p className="text-muted m-2">
                  Naturally ventilated polyhouse with a grow bags is hydroponic
                  farm setup. The hydroponics farm method is a type of
                  controlled environment agriculture which allows you to grow
                  various types of leafy greens and vine crops much more densely
                  than any other form, where you can save water use by up to
                  90%, requires 80 to 95 per cent less area, and produce all
                  year round.{" "}
                </p>
                {/* <p>A vine is any plant with a growth habit of trailing or scandent stems, lianas or runners. The word vine can also refer to such stems or runners themselves, for instance, when used in wicker work.In parts of the world, including the British Isles, the term "vine" usually applies exclusively to grapevines while the term "climber" is used for all climbing plants. Vine Crop NFT Systems are a popular choice for commercial hydroponic growers of tomatoes, capsicums/Bell pepper, beans, cucumbers, Eggplants and other vine crops.  </p> */}
                <p className="text-muted m-2">
                  Residential and small-scale commercial food production can
                  take many forms. Traditional home gardens that utilize native
                  soil may be the most common, but interest in growing
                  vegetables is not limited to those with suitable outdoor and
                  in-ground sites. In many cases, a gardener may not have access
                  to a plot of soil, or the soil may be of such poor quality
                  that growing in the ground is not an option. Soilless
                  production and hydroponics are options for many and enable
                  small-scale vegetable production where traditional gardens
                  would be impossible. The growing systems and techniques
                  involved in soilless growing can enable those in urban areas
                  with small spaces, a sunny patio, or a range of other
                  locations and situations to enjoy growing their own food.
                  Growing plants without using soil has been done for many
                  years, but the science and practice of these methods continues
                  to develop and expand opportunities for commercial growers and
                  gardeners alike. Vine Crop NFT Systems are a popular choice
                  for commercial hydroponic growers of tomatoes, capsicums/Bell
                  pepper, beans, cucumbers, Eggplants and other vine crops.
                </p>
              </Col>
              <Col className="p-3" lg={6}>
                <img src={vineImg} alt="Nvph dutchbucket" className="img-fluid rounded" />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="m-5 mb-2">
            <h6 className="text-green m-2">Vine Crops</h6>
            <h3 className="m-2">1. Cucumber</h3>

            <Row>
              <Col lg={6}>
                {" "}
                <img src={vineImg} alt="naturally ventilated polyhouse" className="img-fluid rounded" />
              </Col>
              <Col lg={6}>
                <p className="text-muted m-2">
                  The cucumber is a creeping vine that roots in the ground and
                  grows up trellises or other supporting frames, wrapping around
                  supports with thin, spiraling tendrils. The plant may also
                  root in a soilless medium, whereby it will sprawl along the
                  ground in lieu of a supporting structure. The vine has large
                  leaves that form a canopy over the fruits. The fruit of
                  typical cultivars of cucumber is roughly cylindrical, but
                  elongated with tapered ends, and may be as large as 62
                  centimeters (24 in) long and 10 centimeters (4 in) in
                  diameter. Cucumber fruits consist of 95% water (see nutrition
                  table). In botanical terms, the cucumber is classified as a
                  pepo, a type of botanical berry with a hard outer rind and no
                  internal divisions. However, much like tomatoes and squashes,
                  it is often perceived, prepared, and eaten as a vegetable.
                </p>
                <h5 className="m-2">Flowering and pollination</h5>
                <p className="text-muted m-2">
                  Most cucumber cultivars are seeded and require pollination.
                  For this purpose, thousands of honey beehives are annually
                  carried to cucumber fields just before bloom. Cucumbers may
                  also be pollinated via bumblebees and several other bee
                  species. Most cucumbers that require pollination are
                  self-incompatible, thus requiring the pollen of another plant
                  in order to form seeds and fruit
                </p>
                <h5 className="m-2">Nutrition, aroma, and taste</h5>
                <p className="text-muted m-2">
                  Raw cucumber (with peel) is 95% water, 4% carbohydrates, 1%
                  protein, and contains negligible fat. A 100-gram (3+1⁄2-ounce)
                  reference serving provides 65 kilojoules (16 kilocalories) of
                  food energy. It has a low content of micronutrients: it is
                  notable only for vitamin K, at 16% of the Daily Value.
                  Depending on variety, cucumbers may have a mild melon aroma
                  and flavor, in part resulting from unsaturated aldehydes, such
                  as (E,Z)-nona-2,6-dienal, and the cis- and trans- isomers of
                  2-nonenal. The slightly bitter taste of cucumber rind results
                  from cucurbitacins.
                </p>
                <h5 className="m-2">Cultivation and Varieties</h5>
                <p className="text-muted m-2">
                  Cultivated for at least 3,000 years, the cucumber originated
                  from India, where a great many varieties have been observed,
                  along with its closest living relative In general cultivation,
                  cucumbers are classified into three main cultivar groups:
                  slicing, pickled, and seedless/burpless
                </p>
                <p className="text-muted m-2">
                  In India protected cultivation area is around 30000 ha which
                  contribute 0.23% of horticulture cropped area in India (Shweta
                  et al. 2014). Cucumber is a semi-tropical vegetable and grows
                  best under polyhouse condition. In polyhouse under stable
                  environmental, with favorable condition and optimum nutrients,
                  less pest-disease infestation, the cucumber plant grows
                  speedily and produces greatly. The optimum temperature for
                  better development of fruits is 14-200°C. Cucumber requires
                  mild climate and it does well under greenhouse condition. The
                  total area of cucumber, growing in India is 78,000 hectares
                  with an annual production of 11.42 lakh MT (National
                  Horticulture Board 2016-17). Now-a-days, greenhouse is most
                  effective against adverse climatic conditions. Vegetable
                  production under polyhouse by using advanced technology to
                  make control over the environment increased crop productivity
                  per unit area and produced the quality of vegetables . It
                  further, protects the crops from adverse conditions like extra
                  solar radiation, high temperature, rain, pest and disease. It
                  also helps to increase photosynthetic rate which results in
                  increased productivity and improved quality of food under
                  better management. Other important factors affecting increased
                  crop productivity under polyhouse are number of plants per
                  unit area and good management practices.
                </p>
              </Col>
            </Row>
            <h3 className="m-2">2. Tomato</h3>
            <h5 className="m-2">Fruit versus vegetable</h5>

            <p className="text-muted m-2">
              Botanically, a tomato is a fruit—a berry, consisting of the ovary,
              together with its seeds, of a flowering plant. However, the tomato
              is considered a "culinary vegetable" because it has a much lower
              sugar content than culinary fruits; because it is more savoury
              than sweet, it is typically served as part of a salad or main
              course of a meal, rather than as a dessert. Tomatoes are not the
              only food source with this ambiguity; bell peppers, cucumbers,
              green beans, eggplants, avocados, and squashes of all kinds (such
              as zucchini and pumpkins) are all botanically fruit, yet cooked as
              vegetables. A raw tomato is 95% water, contains 4% carbohydrates,
              and has less than 1% each of fat and protein. In a 100 grams (3.5
              oz) reference amount, raw tomatoes supply 18 kilocalories and are
              a moderate source of vitamin C (17% of the Daily Value), but
              otherwise have no significant micronutrient content. Numerous
              varieties of the tomato plant are widely grown in temperate
              climates across the world, with greenhouses allowing for the
              production of tomatoes throughout all seasons of the year. Tomato
              plants typically grow to 1–3 meters (3–10 ft) in height. They are
              vines that have a weak stem that sprawls and typically needs
              support. Tomato plants are vines, initially decumbent, typically
              growing 180 cm (6 ft) or more above the ground if supported,
              although erect bush varieties have been bred, generally 100 cm (3
              ft 3 in) tall or shorter. Tomato vines are typically pubescent,
              meaning covered with fine short hairs. The hairs facilitate the
              vining process, turning into roots wherever the plant is in
              contact with the ground and moisture, especially if the vine's
              connection to its original root has been damaged or severed.
              Although in culinary terms, tomato is regarded as a vegetable, its
              fruit is classified botanically as a berry. As a true fruit, it
              develops from the ovary of the plant after fertilization, its
              flesh comprising the pericarp walls. The fruit contains hollow
              spaces full of seeds and moisture, called locular cavities. These
              vary, among cultivated species, according to type
            </p>
            <h5 className="m-2">Cultivation</h5>

            <p className="text-muted m-2">
              The tomato is grown worldwide for its edible fruits, with
              thousands of cultivars. A fertilizer with an NPK ratio of 5–10–10
              is often sold as tomato fertilizer or vegetable fertilizer.. On
              average there are 150,000 seeds in a pound of tomato seeds.{" "}
            </p>
            <h5 className="m-2">Hydroponic and greenhouse cultivation</h5>

            <p className="text-muted m-2">
              Tomatoes are often grown in greenhouses in cooler climates. In
              more temperate climates, it is not uncommon to start seeds in
              greenhouses during the late winter for future transplant.
              Greenhouse tomato production in large-acre commercial greenhouses
              and owner-operator stand-alone or multiple-bay greenhouses is on
              the increase, providing fruit during those times of the year when
              field-grown fruit is not readily available. Smaller sized fruit
              (cherry and grape), or cluster tomatoes (fruit-on-the-vine) are
              the fruit of choice for the large commercial greenhouse operators.
              Hydroponic technique is often used in hostile growing
              environments, as well as high-density plantings
            </p>
            <h5 className="m-2">Picking and ripening</h5>

            <p className="text-muted m-2">
              To facilitate transportation and storage, tomatoes are often
              picked unripe (green) and ripened in storage with ethylene
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className=" p-5">
            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-seedling text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow More Food In Less Space
                    </Card.Title>
                    <Card.Text>
                      With protected cultivation and A frame system now we can
                      grow 70 percent more food in the same area.{" "}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-wheat-awn text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow anywhere you want
                    </Card.Title>
                    <Card.Text>
                      We can now grow our food anywhere with help of just water
                      and protected cultivation.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-hand-holding-droplet text-green text-center fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title className="text-center">Less water</Card.Title>
                    <Card.Text>
                      Now grow crops on 90% less water compared to field crops.
                      This farming is possible at any location even in water
                      scared zones.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-bolt text-green text-center fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      High value cash crops
                    </Card.Title>
                    <Card.Text>
                      Not only our daily crops but we can grow super-crops or
                      very high value cash crops with controlled environment.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <h6 className="text-green m-2 text-center">
              Our Process In Some Steps
            </h6>
            <h4 className="m-2 text-center fw-bold">
              Building Commercially Successful Hydroponic Farm
            </h4>
          </Col>
        </Row>
        <Row className="p-2 m-3">
          {/* second cards */}
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg1} />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Site Feasibility
                </Card.Title>
                <Card.Text className=" text-white p-1">
                  We help you identify a site location to set up our hydroponic
                  project. We consider locality, levelling of land, examine
                  electricity and water also gather basic information of
                  surrounding that can help us test feasibility.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg2} />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Ideal Farm Construction
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once financial feasibility is analysed we start working on the
                  Construction of the Project. Based on site feasibility and
                  market research we choose between NVPH or Fan-Pad system and
                  also hydroponic system between NFT or Dutch Bucket.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg3} />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Farm Management{" "}
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once construction is completed, we work on automating the farm
                  using IoT devices as well as integrating with farm management.
                  At this stage, we also start training your field team to make
                  sure we continuously grow high quality produce.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg4} />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Contract & Markerting Your Product
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once our field team is ready and the complete farm is
                  automated, Our team of agronomists, sales and planning starts
                  working on the contract and set a selling mechanism for your
                  farm, with a schedule of crops.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <TestimonialsSlider />
      <Enquiry />
    </>
  );
}

export default NvphDutchBucket;
