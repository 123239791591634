import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import PromoVid from "../../Assets/hydroponic video/promo.mp4";
import "../../App.css";
function Intro() {
  return (
    <>
      <Container fluid className="whitebgimg">
        <Row className="p-2">
          <Col lg={6} className="p-4">
            <div className="ratio ratio-16x9">
              <video controls muted className="rounded-4 p-2">
                <source src={PromoVid} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {/* <iframe
                                src={PromoVid}
                                title="Vimeo video"
                                allowFullScreen
                                autoplay="false"
                                className='rounded-4 p-2'
                            ></iframe> */}
            </div>
          </Col>
          <Col lg={6} className="p-4">
            <div className="p-2">
              <h3 className="p-2 hover-text">Farm Development</h3>

              <p className="px-2">
                Get in-depth feasibility analysis that covers all technical and
                financial feasibility indicators of the project.
              </p>
              <p className="px-2">
                Royal Green House is the largest project developer in hydroponic
                farming and soil-less farming.
              </p>
              <p className="px-2 fst-italic">
                {" "}
                "Build Your Own Hydrophonic Farm With Our Expertise (At home
                also)"
              </p>
            </div>
            <div className="p-2">
              <Link to="/Contact" className="btn btn-outline-success">
                <i className="fa-solid fa-circle-arrow-right fa-beat"></i> Learn
                More
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Intro;
