import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import nvphImg from "../../Assets/gallery/blogNVPHA.png";
import fanbadaFrame from "../../Assets/gallery/1.png";
import NvphFlatbed from "../../Assets/gallery/nflatbed.png";
import climateImg from "../../Assets/gallery/climatePondicherry.png";
import blog1Img from "../../Assets/gallery/blog1Img.png";
import Blog6Img from "../../Assets/Blog6Img.jpeg";
import Commercial_Hydroponic_Img from "../../Assets/gallery/Commercial_Hydroponic_Img.jpeg";
import polyhouse_bog_img from "../../Assets/polyhouse_blog_img.png";
import Hydroponics_types_img from "../../Assets/Hydroponics_types_img.png";
import Top10_hydroponic_crops_img from "../../Assets/Top10_hydroponic_crops_img.png";
import IntelliDose_img from "../../Assets/IntelliDose_img.webp";
import cucumber_blog from "../../Assets/cucumber_blog.png";
import hydroponic_farm_img from "../../Assets/hydroponic_farm_img.jpeg";
import subsidy_img from "../../Assets/subsidy_img.png";
import factory_img from "../../Assets/gallery/factory12.png";
import why_choose_rgcpl_img from "../../Assets/why_choose_rgcpl_img.jpeg";
import cost_blog_img from "../../Assets/cost_blog_img.png";
import "./blog.css";

function Blog() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      mirror: true,
      once: false,
      offset: 120, // Adjust this offset to control when the animation triggers
    });
    AOS.refresh(); // Refresh AOS to ensure all elements are registered
  }, []);

  return (
    <Container fluid>
      {/* Header Row */}
      <Row className="headingimg">
        <Col className="m-5">
          <h2 className="text-white m-4 mx-4 px-2 fw-bold text-center">
            Blogs
          </h2>
          <div className="text-white m-4 fw-bold text-center">
            <a href="/" className="text-white m-3 text-decoration-none">
              Home
            </a>
            <i className="fa-solid fa-circle-arrow-right"></i>
            <a href="#" className="text-white text-decoration-none m-3">
              Blogs
            </a>
          </div>
        </Col>
      </Row>

      {/* Blog Cards Row 1 */}
      <Row className="p-3 bg-dark">
        <Col md={4} className="mb-4" data-aos="flip-right" data-aos-delay="100">
          <div className="card h-100 border border-2">
            <img
              src={blog1Img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="future maintenance in polyhouse farming"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                How to Reduce Future Maintenance in Polyhouse Farming
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/HowToReduceFutureMaintenanceInPolyhouseFarming"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4" data-aos="flip-right" data-aos-delay="350">
          <div className="card h-100">
            <img
              src={nvphImg}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="nvph a frame"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                NVPH A Frame
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link to="/NVPHBlog" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4" data-aos="flip-right" data-aos-delay="550">
          <div className="card h-100">
            <img
              src={fanbadaFrame}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="fanpad a frame"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                FANPAD A Frame
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link to="/FanpadAFrame_Blog" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
      </Row>

      {/* Blog Cards Row 2 */}
      <Row className="p-3 bg-dark">
        <Col md={4} className="mb-4" data-aos="flip-right" data-aos-delay="750">
          <div className="card h-100">
            <img
              src={NvphFlatbed}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="hydroponic farm"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                NVPH Flatbed Hydroponics
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link to="/NVPHFlatbed_Blog" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4" data-aos="flip-right" data-aos-delay="850">
          <div className="card h-100">
            <img
              src={climateImg}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="hydroponics farms"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                Climate Overview for Hydroponic Farming in Pondicherry, India
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link to="/ClimateOverview_Blog" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="mb-4" data-aos="flip-right" data-aos-delay="950">
          <div className="card h-100">
            <img
              src={Blog6Img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="hydroponic farming in india"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                Hydroponic Farming in India:Revolutionizing Agriculture with
                Royal Green House
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Hydroponic_Farming_In_India"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center p-3 bg-dark">
        <Col
          md={4}
          className="mb-4"
          data-aos="flip-right"
          data-aos-delay="1000"
        >
          <div className="card h-100">
            <img
              src={Commercial_Hydroponic_Img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="Commercial Hydroponic farm setup in India "
            />
            <div className="card-body">
              <h1 className="card-title text-decoration-underline">
                Commercial Hydroponic Farming:
              </h1>{" "}
              <h2>A New Frontier in Agriculture</h2>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Commercial_Hydroponic_Farming"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col
          md={4}
          className="mb-4"
          data-aos="flip-right"
          data-aos-delay="1000"
        >
          <div className="card h-100">
            <img
              src={polyhouse_bog_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="polyhouse farming setup"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                Polyhouse Farming:Benefits and Setup with RGCPL Expertise
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Polyhouse_Farming_Benefits_and_Setup"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col
          md={4}
          className="mb-4"
          data-aos="flip-right"
          data-aos-delay="1000"
        >
          <div className="card h-100">
            <img
              src={Hydroponics_types_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="types of hydroponic systems"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                Types of Hydroponic Systems
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Types_of_Hydroponics_Systems"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center p-3 bg-dark">
        <Col
          md={4}
          className="mb-4"
          data-aos="flip-right"
          data-aos-delay="1000"
        >
          <div className="card h-100">
            <img
              src={Top10_hydroponic_crops_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="top 10 hydroponic setup companies"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                Top 10 Hydroponic Crops for India
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Top10_hydroponic_crops_for_india"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>

        <Col
          md={4}
          className="mb-4"
          data-aos="flip-right"
          data-aos-delay="1000"
        >
          <div className="card h-100">
            <img
              src={IntelliDose_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="Automated Dosing Systems"
            />
            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                5 Reasons You Need to Invest in Automated Dosing Systems on Your
                Farm
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Reasons_to_invest_in_automated_dosing_systems"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col
          md={4}
          className="mb-4"
          data-aos="flip-right"
          data-aos-delay="1000"
        >
          <div className="card h-100">
            <img
              src={cucumber_blog}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="infestation in cucumber"
              style={{ height: "350px" }}
            />

            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                Downy Mildew Infestation in Cucumber (🥒)
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Downy_Mildew_Infestation_in_Cucumber"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center p-3 bg-dark">
        <Col
          md={4}
          className="mb-4"
          data-aos="flip-right"
          data-aos-delay="1000"
        >
          <div className="card h-100">
            <img
              src={hydroponic_farm_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="Hydroponic farming"
              style={{ height: "350px" }}
            />

            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                Hydroponic Farming: How High-Tech Agriculture Adapts to Climate
                Change
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link to="/Hydroponic_Farming" className="btn btn-success">
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col
          md={4}
          className="mb-4"
          data-aos="flip-right"
          data-aos-delay="1000"
        >
          <div className="card h-100">
            <img
              src={subsidy_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="Hydroponic farming"
              style={{ height: "350px" }}
            />

            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                Guide to Poly House Subsidy in India by Royal Green House
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Guide_to_Poly_House_Subsidy_in_India_by_Royal_Green_House"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col
          md={4}
          className="mb-4"
          data-aos="flip-right"
          data-aos-delay="1000"
        >
          <div className="card h-100">
            <img
              src={factory_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="polyhouse farming"
              style={{ height: "350px" }}
            />

            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                RGCPL - Leading the Way in Polyhouse Construction & Hydroponics
                Innovation in India
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/RGCPL_Leading_the_Way_in_Polyhouse_Construction_Hydroponics_Innovation_in_India"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center p-3 bg-dark">
        <Col
          md={4}
          className="mb-4"
          data-aos="flip-right"
          data-aos-delay="1000"
        >
          <div className="card h-100">
            <img
              src={why_choose_rgcpl_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="hydroponics and polyhouse farms"
              style={{ height: "350px" }}
            />

            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                The Ultimate Guide to Polyhouse Construction and Hydroponic
                Setup by Royal Green House
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Ultimate_Guide_to_Polyhouse_Construction"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
        <Col
          md={4}
          className="mb-4"
          data-aos="flip-right"
          data-aos-delay="1000"
        >
          <div className="card h-100">
            <img
              src={cost_blog_img}
              className="card-img-top img-fluid p-3 rounded zoom-img"
              alt="cost of commercial farm setups"
              style={{ height: "350px" }}
            />

            <div className="card-body">
              <h2 className="card-title text-decoration-underline">
                Understanding the Cost of Setting Up Different Types of
                Protected Cultivation Structures
              </h2>
            </div>
            <div className="card-footer text-center">
              <Link
                to="/Understanding_the_Cost_of_Setting_Up_Different_Types_of_Protected_Cultivation_Structures"
                className="btn btn-success"
              >
                View More
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Blog;