import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import nvphImg from "../../Assets/gallery/blogNVPHA.png";
import "animate.css";
import { Link } from "react-router-dom";

function NVPHBlog() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center mb-4">
        <img
          src={nvphImg}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ maxWidth: "100%", height: "auto" }}
          alt="nvph"
        />
      </div>

      <Row className="m-3">
        <Col
          className="p-4 text-justify"
          sm={12}
          md={10}
          lg={8}
          style={{ margin: "auto" }}
          id="content"
        >
          <div id="blog1" className="border p-3">
            <h1 className="text-center p-3" style={{ fontSize: "1.75rem" }}>
              Naturally Ventilated Poly House with A Frame: A Sustainable
              Solution for Modern Agriculture
            </h1>
            <p style={{ fontSize: "1rem" }}>
              Agriculture is a vital sector for human survival and prosperity,
              but it also faces many challenges, including climate change,
              resource depletion, and population growth. To address these
              challenges, farmers and researchers are exploring new techniques
              and technologies that can enhance productivity, reduce waste, and
              improve sustainability. One such innovation is the Naturally
              Ventilated Poly House with A Frame, a design that combines the
              benefits of greenhouse cultivation with natural ventilation.
            </p>
            <Link to="/NvphAframe" style={{ textDecoration: "none" }}>
              <h5 style={{ fontSize: "1.2rem", color: "#007bff" }}>
                What is a Naturally Ventilated Poly House with A Frame?
              </h5>
            </Link>
            <p style={{ fontSize: "1rem" }}>
              A Naturally Ventilated Poly House with A Frame is a type of
              greenhouse that uses a combination of natural and mechanical
              ventilation to regulate temperature, humidity, and air quality.
              The poly house is made of polyethylene sheets that cover a frame
              made of PVC pipes or bamboo poles, forming a tunnel-like structure
              that can be customized in size and shape according to the needs of
              the crops and the available space. The A Frame refers to the
              triangular structure that supports the roof of the poly house,
              providing stability and resistance to wind and snow loads.
            </p>
            <h5 style={{ fontSize: "1.2rem" }}>
              How does a Naturally Ventilated Poly House with A Frame work?
            </h5>
            <p style={{ fontSize: "1rem" }}>
              The polyethylene sheets used in the poly house are UV-stabilized
              and have a thickness of 200 to 400 microns, depending on the
              location and climate. The sheets are fixed to the frame using
              clips or ropes, and can be rolled up or down to adjust the
              ventilation and the amount of sunlight that enters the poly house.
              The A Frame is made of steel or bamboo, and can be covered with
              shade nets or insect nets to protect the crops from pests and
              excessive heat.
            </p>
            <p style={{ fontSize: "1rem" }}>
              The natural ventilation in the poly house is achieved by the
              combination of two factors: the stack effect and the wind effect.
              The stack effect is the movement of air caused by the difference
              in temperature and pressure between the interior and exterior of
              the poly house. As the air inside the poly house heats up, it
              becomes less dense and rises towards the roof, creating a
              low-pressure zone that draws in cooler air from the sides or the
              ends of the poly house. The wind effect is the movement of air
              caused by the external wind that passes through the openings of
              the poly house, creating a cross-ventilation that refreshes the
              air and removes excess moisture.
            </p>
            <h5 style={{ fontSize: "1.2rem" }}>
              What are the benefits of a Naturally Ventilated Poly House with A
              Frame?
            </h5>
            <p style={{ fontSize: "1rem" }}>
              The Naturally Ventilated Poly House with A Frame has several
              advantages over traditional cultivation methods, such as open
              field or closed greenhouse.
            </p>
            <ul style={{ fontSize: "1rem" }}>
              <li>
                Lower energy consumption: The natural ventilation in the poly
                house reduces the need for artificial cooling or heating, which
                lowers the energy bills and the carbon footprint.
              </li>
              <li>
                Higher yield and quality: The controlled environment in the poly
                house allows for optimal conditions of light, temperature, and
                humidity, which can enhance the growth, yield, and quality of
                the crops, especially in areas with extreme weather conditions
                or pests.
              </li>
              <li>
                Reduced water consumption: The polyethylene sheets reduce the
                evapotranspiration of the crops, which means that less water is
                needed for irrigation. Moreover, the poly house can collect and
                store rainwater for later use, reducing the dependence on
                external sources of water.
              </li>
              <li>
                Diversified and year-round production: The poly house can be
                used for various crops, such as vegetables, fruits, flowers, or
                herbs, and can extend the growing season beyond the natural
                cycle. This can increase the income and the food security of the
                farmers, as well as provide local and fresh produce to the
                consumers.
              </li>
            </ul>
            <p style={{ fontSize: "1rem" }}>
              In conclusion, the Naturally Ventilated Poly House with A Frame is
              a promising solution for sustainable agriculture, as it combines
              the advantages of greenhouse cultivation with natural ventilation,
              leading to lower energy consumption, higher yield and quality,
              reduced water consumption, and diversified and year-round
              production. The design is simple, affordable, and adaptable to
              various contexts and needs.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default NVPHBlog;
