import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Enquiry from "../../Components/Enquiry";
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import WireRope1 from "../../Assets/gallery/WireRope1.png";
import WireRope2 from "../../Assets/gallery/WireRope2.png";
import WireRope3 from "../../Assets/gallery/WireRope3.png";
import WireRope4 from "../../Assets/gallery/WireRope4.png";
function RainProtectedWireRopeNetHouse() {
  return (
    <>
      <Container fluid>
        <Row className="headingimgnew3">
          <Col className="m-3">
            <h1 className="text-dark m-4 mx-4 px-2 fw-bold text-center">
              {" "}
              Rain Protected Wire Rope Net House
            </h1>
            <div className="text-dark m-4 fw-bold text-center">
              <a href="/" className=" text-dark m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-dark text-decoration-none m-3">
                Rain Protected Wire Rope Net House
              </a>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="m-3 mb-2">
            <h6 className="text-green m-2">
              Rain Protected Wire Rope Net House
            </h6>
            <h2 className="m-2">Rain Protected Wire Rope Net House</h2>

            <Row>
              <Col lg={6}>
                <p className="text-muted m-2">
                  {" "}
                  A Rain Protected Wire Rope Net House with a solarig sheet on
                  top offers enhanced protection. The solarig sheet shields
                  crops from rain and UV rays, while the wire rope and net
                  structure provide ventilation and support. This setup ensures
                  year-round crop protection, maintaining healthy growth even
                  during the rainy season.
                </p>
              </Col>
              <Col className="p-3" lg={6}>
                <img
                  src={WireRope1}
                  alt="rain protected wire rope net house"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="m-3">
          <Col className="p-1" lg={4}>
            <img
              src={WireRope2}
              alt="net house"
              className="img-fluid rounded"
              style={{ border: "1px solid #000" }}
            />
          </Col>
          <Col className="p-1" lg={4}>
            <img
              src={WireRope3}
              alt="wire rope net house"
              className="img-fluid rounded"
              style={{ border: "1px solid #000" }}
            />
          </Col>
          <Col className="p-1" lg={4}>
            <img
              src={WireRope4}
              alt="polyhouse farming"
              className="img-fluid rounded"
              style={{ border: "1px solid #000" }}
            />
          </Col>
        </Row>

        <Row className="text-justify m-3">
          <Col className="p-2" lg={6}>
            <h3> Features</h3>
            <ul>
              <li>Reinforced edges for added durability and strength</li>
              <li>
                8 m x 4 m grid structure for optimal support and stability
              </li>
              <li>
                Rubber and plastic clips for secure and easy attachment of the
                netting
              </li>
              <li>
                PVC drainage cone with accompanying accessories for efficient
                water management
              </li>
              <li>
                Eyelets placed every 30 cm along both the length and width for
                versatile attachment options
              </li>
            </ul>
          </Col>
          <Col className="p-2" lg={6}>
            <h3>Specifications</h3>
            <ul>
              <li>
                PVC Drainage Cone: diameter of 160 mm for effective drainage
              </li>
              <li>Rubber Ring: diameter of 170 mm with a thickness of 2 mm</li>
              <li>
                Black Rubber Band: diameter of 5 mm and length of 80 mm for
                secure fittings
              </li>
              <li>
                Closed Safety Plastic Ring: dimensions of 4 mm x 40 mm for
                enhanced safety
              </li>
              <li>Eye Bolt: diameter of 8 mm and length of 90 mm</li>
              <li>Nut: diameter of 8 mm for secure fastening</li>
              <li>
                Washer: dimensions of 8 mm x 25 mm x 2 mm for firm connections
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <TestimonialsSlider />
      <Enquiry />
    </>
  );
}

export default RainProtectedWireRopeNetHouse;
