import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory18 from "../../Assets/accessory18.jpeg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Greenhouse_Pipe_Clamp = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 64,
      name: "Greenhouse Pipe Clamp",
      price: 44,
      image: accessory18,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory18}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Greenhouse Pipe Clamp</h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The Greenhouse Pipe Clamp is a vital component used in the
                  assembly of greenhouse structures. Its primary function is to
                  securely hold pipes together, ensuring the greenhouse
                  framework remains stable and durable, even in harsh weather
                  conditions.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span> Galvanized Iron
                      (GI)
                    </li>
                    <li>
                      <span className="fw-bold">Type:</span> Pipe Clamp
                    </li>
                    <li>
                      <span className="fw-bold">Size:</span> Available in
                      different sizes for various pipe diameters
                    </li>
                    <li>
                      <span className="fw-bold">Design:</span> Open-slot design
                      for easy installation and secure fastening
                    </li>
                    <li>
                      <span className="fw-bold">Finish:</span> Anti-corrosive,
                      zinc-coated finish for long-lasting use
                    </li>
                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 2-3 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>Eco-friendly: Made from recyclable materials.</li>
                  <li>
                    High Strength: Capable of holding pipes securely under high
                    tension.
                  </li>
                  <li>
                    Corrosion-resistant: The galvanized finish protects against
                    rust and environmental wear.
                  </li>
                  <li>
                    Easy to Install: Simple design allows for quick installation
                    and removal.
                  </li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Large
                      Bags
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <h5 className="mt-3">Importance of Greenhouse Pipe Clamp:</h5>
        <p className="text-justify">
          The Greenhouse Pipe Clamp is essential for building strong and stable
          greenhouses. It helps maintain the overall structure, ensuring that
          the pipes stay connected, thereby protecting the plants and crops
          inside from external forces like strong winds or heavy snow.
        </p>
        <h5 className="mt-3">Key Benefits of Greenhouse Pipe Clamp:</h5>

        <p className="text-justify">
          <ul>
            <li>
              <span className="fw-bold">Structural Integrity:</span> Provides
              strong and reliable support to greenhouse frameworks, ensuring the
              pipes remain securely connected.
            </li>
            <li>
              <span className="fw-bold">Durability:</span> Built to withstand
              environmental challenges such as rain, wind, and snow.
            </li>
            <li>
              <span className="fw-bold">Low Maintenance:</span> Requires minimal
              upkeep due to its corrosion-resistant properties.
            </li>
          </ul>
        </p>
        <h5 className="mt-3">Applications of Greenhouse Pipe Clamp:</h5>
        <p className="text-justify">
          <ul>
            <li>
              Used to connect and secure pipes in greenhouse structures,
              polyhouse setups, and net houses.
            </li>
            <li>
              Suitable for various greenhouse types, including tunnel,
              low-tunnel, and high-tunnel greenhouses.
            </li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Greenhouse_Pipe_Clamp;
