import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BatteryCapForTruncheonMeterV2 from "../../Assets/Battery Cap for Truncheon Meter V2.png";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Battery_Cap_for_Truncheon_Meter_V2 = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 30,
      name: "Battery Cap for Truncheon Meter V2",
      price: 588,
      image: BatteryCapForTruncheonMeterV2,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BatteryCapForTruncheonMeterV2}
                  alt="greenhouse accessories"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Battery Cap for Truncheon Meter V2</h2>
            <p className="product-price">₹{588}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>
                Bluelab Truncheon Meter V2 Replacement Battery Cap – Easy,
                Reliable Replacement for Long-lasting Performance.
              </h5>
              This version emphasizes the ease and reliability of the product,
              which may appeal more to users looking for a quick solution for
              their Bluelab Truncheon Meter V2.
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Battery_Cap_for_Truncheon_Meter_V2;
